import React, { useState } from "react";
import "./webinarhistory.css";

import { useDispatch } from "react-redux";
import PageLayout from "../../components/pagelayout/pagelayout";
import { failureAlert, IsAdmin, REG_EX_URL_FORMAT, S3_BASE_URL, signOut, successAlert } from "../../helpers/helper";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { useEffect } from "react";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import Select from "react-select";
import { updateSSODetails } from "../../redux/actions/adminApiActions";
import { getAllWebinarCategories, getAllWebinars, updateWebinar, uploadFile } from "../../redux/actions/commonActions";
import upload from '../../assets/images/upload.svg';
import { gameAllCategory, getAllObjectives } from "../../redux/actions/gameDetailAction";
import { mapToDropdownArray } from '../../utils';
const WebinarHistoryAdd = (props) => {
    const isAdmin = IsAdmin();
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(true);
    const [webinarsList, setWebinarsList] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [details, setDetails] = useState({
        webinar: null,
        description: "",
        previewUrl: "",
        // webinarCategory: "",
        teaserUrl: "",
        fullVideoUrl: "",
        hideFromUsers: false
    });
    const [touched, setTouched] = useState({
        webinar: false,
        description: false,
        previewUrl: false,
        // webinarCategory: "",
        teaserUrl: false,
        fullVideoUrl: false
    });
    const [categoryOptions, setCategoryOptions] = useState([]);
    const { gameCategory } = useSelector((state) => state.gameAllCategory);
    const AllWebinars = useSelector(state => state.allWebinars);
    const { allWebinars } = AllWebinars;
    const { allWebinarCategories } = useSelector(state => state.allWebinarCategories);
    const [errorMobileImage, setErrorMobileImage] = useState("");
    const [errorDesktopImage, setErrorDesktopImage] = useState("");
    const [dropdownArr, setDropdownArr] = useState([]);
    useEffect(() => {
        dispatch(getAllWebinars(true, true));
        dispatch(getAllWebinarCategories());
        dispatch(gameAllCategory());
    }, [])
    useEffect(() => {
        if (gameCategory) {
       
          setDropdownArr(gameCategory.data);
        }
      }, [gameCategory]);
    useEffect(() => {
        if (details.webinar) {
            setDetails(prevState => ({
                ...prevState,
                title: details.webinar.data.title,
                description: details.webinar.data.description || "",
                fullVideoUrl: details.webinar.data.fullVideoUrl || "",
                hideFromUsers: details.webinar.data.hideFromUsers || false,
                previewUrl: details.webinar.data.previewUrl || "",
                // webinarCategory: details.webinar.data.catagoryId ? { label: details.webinar.data.catagory?.name, value: details.webinar.data.catagory?.name, id: details.webinar.data.catagoryId } : null,
                teaserUrl: details.webinar.data.teaserUrl || "",
                coverMedia: details.webinar.data.coverMedia || "",
                mobileCoverMedia: details.webinar.data.mobileCoverMedia || ""
            }))
        }
    }, [details.webinar])
 
    useEffect(() => {
        if (
            (AllWebinars && AllWebinars.loading)
        ) {
            setLoaded(false);
        }
        else
            setLoaded(true);
    }, [AllWebinars])
    useEffect(() => {
        if (allWebinars?.data?.length > 0) {
           
            setWebinarsList(allWebinars.data.map(data => (
                { label: data.title, value: data.title, id: data.id, data }
            )))
        }
    }, [allWebinars]);
    useEffect(() => {
        if (dropdownArr) {
           
            const categories = dropdownArr.map(cat => ({ label: cat.title, value: cat.title, id: cat.id }));
            setCategoryOptions(categories);
        }
    }, [dropdownArr]);

    const handleBlur = (e) => {
        const { name } = e.target;
        setTouched(prevState => ({
            ...prevState,
            [name]: true
        }))
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setDetails(prevState => ({
            ...prevState,
            [name]: value
        }))
    }
    const handleFileChange = (e) => {
        e.persist();
        const file = e.target.files[0];
        e.target.value = null;
        if (file && file.type && file.type.startsWith("image/")) {
            const img = new Image();
            let width, height;
            img.onload = async function () {
                width = this.width;
                height = this.height;
                if (e.target.name === "coverMedia") {
                    if (width === 1850 && height === 420) {
                        setErrorDesktopImage("");
                        const data = new FormData();
                        data.append("banner-images", file);
                        const response = await dispatch(uploadFile(data));
                        if (response && response.status === 200) {
                            if (response.data && response.data.data && response.data.data.path) {
                                setDetails(prevState => ({
                                    ...prevState,
                                    coverMedia: response.data.data.path
                                }))
                            }
                        }
                        else if (response?.status?.includes?.("417"))
                            failureAlert("Uploaded file contains some malware!");
                        else if (response?.status?.includes?.("500"))
                            failureAlert("File Format Not supported");
                    }
                    else {
                        setDetails(prevState => ({
                            ...prevState,
                            coverMedia: null
                        }))
                        setErrorDesktopImage("*Image dimension should be 1850x420");
                    }
                }
                if (e.target.name === "mobileCoverMedia") {
                    if (width === 200 && height === 300) {
                        setErrorMobileImage("");
                        const data = new FormData();
                        data.append("banner-images", file);
                        const response = await dispatch(uploadFile(data));
                        if (response && response.status === 200) {
                            if (response.data && response.data.data && response.data.data.path) {
                                setDetails(prevState => ({
                                    ...prevState,
                                    mobileCoverMedia: response.data.data.path
                                }))
                            }
                        }
                        else if (response?.status?.includes?.("417"))
                            failureAlert("Uploaded file contains some malware!");
                        else if (response?.status?.includes?.("500"))
                            failureAlert("File Format Not supported");
                    }
                    else {
                        setDetails(prevState => ({
                            ...prevState,
                            mobileCoverMedia: null
                        }))
                        setErrorMobileImage("*Image dimension should be 200x300");
                    }
                }
                handleBlur(e);
            }
            const _URL = window.URL || window.webkitURL;
            img.src = _URL.createObjectURL(file);
        }
    }
    const formatImageName = (name) => {
        return ''
        // if (name) {
        //     name = name.split("/")?.at(-1) || name;
        //     // name = name.replace("https://extramileplay.com/public/uploads/images/", "");
        //     // name = name.replace("https://uat.extramileplay.com/public/uploads/images/", "");
        //     // name = name.replace("https://extramileplay.com/public/uploads/banner-images/", "");
        //     // name = name.replace("https://uat.extramileplay.com/public/uploads/banner-images/", "");
        //     // if (name.length > 20)
        //     //     name = name.slice(20)
        // }
        // return name;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setTouched({ fullVideoUrl: true, description: true, previewUrl: true, teaserUrl: true, webinar: true });
        setSubmitClicked(true);
    }

    useEffect(() => {
        const callBack = async () => {
            if (submitClicked) {
                if (JSON.stringify(validate()) === JSON.stringify({ webinar: "", description: "", previewUrl: "", teaserUrl: "", fullVideoUrl: "" })) {
                    let body = {
                        teaserUrl: details.teaserUrl,
                        previewUrl: details.previewUrl,
                        // catagoryId: details.webinarCategory.id,
                        description: details.description,
                        fullVideoUrl: details.fullVideoUrl,
                        hideFromUsers: details.hideFromUsers,
                        coverMedia: details.coverMedia || details.webinar.data.coverMedia,
                        mobileCoverMedia: details.mobileCoverMedia || details.webinar.data.mobileCoverMedia,
                        redirectURL: details.webinar.data.redirectURL,
                        startsAt: details.webinar.data.startsAt,
                        isChat: details.webinar.data.isChat,
                        webinarId: details.webinar.id,
                        title: details.title || details.webinar.title,
                    };
                    const response = await dispatch(updateWebinar(body));
                    if (response === 200) {
                        successAlert("Updated Successfully");
                        dispatch(getAllWebinars(true, true));
                    }
                    else {
                        failureAlert("Something went wrong");
                    }
                    setTouched({ webinar: false, fullVideoUrl: false, description: false, previewUrl: false, teaserUrl: false });
                    setDetails({ webinar: null, fullVideoUrl: "", hideFromUsers: false, description: "", previewUrl: "", teaserUrl: "" });
                }
                setSubmitClicked(false);
            }
        }
        callBack();
    }, [submitClicked]);
    const validate = () => {
        const errors = {
            webinar: "",
            description: "",
            previewUrl: "",
            // webinarCategory: "",
            teaserUrl: "",
            fullVideoUrl: "",
        }
        if (touched.webinar && !details.webinar)
            errors.webinar = "Please select a webinar";
        if (touched.description && !details.description)
            errors.description = "Please enter valid description";
        if (touched.previewUrl && !REG_EX_URL_FORMAT.test(details.previewUrl))
            errors.previewUrl = "Please enter valid Url";
        // if (touched.webinarCategory && !details.webinarCategory)
        //     errors.webinarCategory = "Please select valid category";
        if (touched.teaserUrl && !REG_EX_URL_FORMAT.test(details.teaserUrl))
            errors.teaserUrl = "Please enter valid Url";
        if (touched.fullVideoUrl && !REG_EX_URL_FORMAT.test(details.fullVideoUrl))
            errors.fullVideoUrl = "Please enter valid Url";
        return errors;
    }
    const errors = validate();
    // console.log(errors,details);

    return (<div className="admin-homepage">
        <PageLayout
            sidebartitle=""
            active={"Webinars History"}
            category
            sideBarContents={sidebarContentAdmin}
            profile
            {...props}
            signOut={() => signOut(dispatch, props.history, isAdmin)}
            isAdmin={isAdmin}
        >
            <div className="webinar-hisory-add">
                <LoadingComponent loaded={loaded} />
                <ToastContainer position="bottom-center" />
                <h4 className="title">Add Webinar History</h4>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="">Webinar</label>
                        <Select
                            classNamePrefix="react-select"
                            className="form-select"
                            options={webinarsList}
                            menuPlacement={"auto"}
                            onChange={e => handleChange({ target: { name: "webinar", value: e } })}
                            placeholder="Select Webinar"
                            value={details.webinar}
                        />
                        <div className="error-message">{errors.organization}</div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Image for desktop (1850x420)</label>
                        <div className="cstm-upload-btn">
                            <img src={S3_BASE_URL + details.coverMedia} />
                            <label className="upload-button">
                                <input type="file" name="coverMedia" accept="image/x-png,image/jpg,image/jpeg" onChange={handleFileChange} />
                            </label>
                        </div>
                        <div className="error-message">{errorDesktopImage ? errorDesktopImage : errors.coverMedia}</div>
                        <div className="error-message uploaded-info">{formatImageName(details.coverMedia)}</div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Thumbnail (200x300)</label>
                        <div className="cstm-upload-btn">
                            <img src={S3_BASE_URL + details.mobileCoverMedia} />
                            <label className="upload-button">
                                <input type="file" name="mobileCoverMedia" accept="image/x-png,image/jpg,image/jpeg" onChange={handleFileChange} />
                            </label>
                        </div>
                        <div className="error-message">{errorMobileImage ? errorMobileImage : errors.mobileCoverMedia}</div>
                        <div className="error-message uploaded-info">{formatImageName(details.mobileCoverMedia)}</div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Webinar Title</label>
                        <input className="form-field" placeholder="Enter webinar title" value={details.title} name="title" onChange={handleChange} onBlur={handleBlur} />
                        <div className="error-message">{errors.title}</div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Webinar Description</label>
                        <textarea className="form-field" placeholder="Enter webinar description" value={details.description} name="description" onChange={handleChange} onBlur={handleBlur} />
                        <div className="error-message">{errors.description}</div>
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="">Webinar Category</label>
                        <Select
                            classNamePrefix="react-select"
                            className="form-select"
                            options={categoryOptions}
                            onChange={(e) => handleChange({ target: { name: "webinarCategory", value: e } })}
                            menuPlacement={"auto"}
                            placeholder="Select Category"
                            value={details.webinarCategory}
                        />
                        <div className="error-message">{errors.webinarCategory}</div>
                    </div> */}
                    <div className="form-group">
                        <label htmlFor="">Webinar Preview Url</label>
                        <input type="text" className="form-field" placeholder="Enter webinar preview Url" value={details.previewUrl} name="previewUrl" onChange={handleChange} onBlur={handleBlur} />
                        <div className="error-message">{errors.previewUrl}</div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Webinar Teaser Url</label>
                        <input type="text" className="form-field" placeholder="Enter webinar trailer Url" value={details.teaserUrl} name="teaserUrl" onChange={handleChange} onBlur={handleBlur} />
                        <div className="error-message">{errors.teaserUrl}</div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Webinar Full Url</label>
                        <input type="text" className="form-field" placeholder="Enter webinar full Url" value={details.fullVideoUrl} name="fullVideoUrl" onChange={handleChange} onBlur={handleBlur} />
                        <div className="error-message">{errors.fullVideoUrl}</div>
                    </div>
                    <div className="form-group checkbox">
                        <label htmlFor="">Hide From Users</label>
                        <input type="checkbox" name='hideFromUsers' checked={details.hideFromUsers} onClick={e => setDetails(prevState => ({ ...prevState, hideFromUsers: !details.hideFromUsers }))} className="form-field" />
                    </div>
                    <button className="btn btn-primary">Update</button>
                </form>
            </div>
        </PageLayout>
    </div>

    )
}

export default WebinarHistoryAdd;