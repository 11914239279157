import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router";

import gameArrow from "../../assets/images/gameArrow.svg";
import inviteOnly from "../../assets/images/inviteOnly.svg";
import arrowleft from "../../assets/images/paginationarrow.svg";
import rewards from "../../assets/images/rewards.svg";
import rewardsDistribution from "../../assets/images/rewards-distribution.svg";
import privateOnly from "../../assets/images/private.svg";
import publicOnly from "../../assets/images/public.svg";
import guestOnly from "../../assets/images/guestUser.svg";
import { BASE_URL, CLIENT_URL, axiosApiInstance, encryptData } from "../../helpers/helper";
import ROLES, { OrgRoles } from "../../helpers/userTypes";
import { getGameDetail, getGameServerOtp } from "../../redux/actions/gameDetailAction";
import { getMyPlans } from "../../redux/actions/plansApiActions";
import { deleteGameSession, getAllGameSessions, createGameSession } from "../../redux/actions/sessionsApiActions";
import { getOrganisationUsers } from '../../redux/actions/organisationActions';
import LoadingComponent from "../loader/LoadingComponent";
import DeleteDomainModal from '../modal/deleteDomainModal';
import StartNewGameModal from "../modal/startNewGameModal";
import SettingsSvg from "../svgIcon/settingsSvg";
import "./activeGamesDetail.css";
import QrDownloadModal from "./qrDownloadModal";
import XoxoModal from "./xoxoModal"
import { getXoxodayGames } from "../../redux/actions/adminApiActions";
import RewardDistributionModal from "./RewardDistributionModal";
import copy from "copy-to-clipboard";
import { HiLink } from "react-icons/hi";
import { getOrgTeamsGameSessions } from "../../redux/actions/teamAction";
import { FaClone } from "react-icons/fa6";



const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const ActiveGamesDetail = (props) => {
  const dispatch = useDispatch();
  const DeleteSession = useSelector(state => state.deleteSession);
  const { userInfo } = useSelector(state => state.getUser);
  const GameSessions = useSelector(state => state.gameSessions);
  const xoxoGames = useSelector(state => state.xoxoGames);
  const { gameSessions } = GameSessions;
  const GameDetail = useSelector(state => state.gameDetail);
  const { gameDetail } = GameDetail;
  const GetMyPlans = useSelector(state => state.getMyPlans);
  const { id } = useParams();
  const GameServerOtp = useSelector(state => state.gameServerOtp);
  const { gameServerOtp } = GameServerOtp;
  const [openNewGameModal, setOpenNewGameModal] = useState(false);
  const [page, setPage] = useState(1);
  const [isActive, setIsActive] = useState(false)
  const [joinPressed, setJoinPressed] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [edit, setEdit] = useState(false);
  const [xoxoModal, setXoxoModal] = useState(false);
  const [xoxoGameSession, setXoxoGameSession] = useState(false);
  const [isXoxoOption, setIsXoxoOption] = useState(false);
  const [xoxoGameDetail, setXoxoGameDetail] = useState([]);
  const [editSession, setEditSession] = useState({});
  const [openDeleteDomainModal, setOpenDeleteDomainModal] = useState(false);
  const [deleteSession, setDeleteSession] = useState(null);
  const [loadFlag, setLoadFlag] = useState(0);
  const [showConfigure, setShowConfigure] = useState(false);


  const [shortGameUrl, setShortGameUrl] = useState('');
  const [longGameUrl, setLongGameUrl] = useState('');
  const [openTypeLinkTooltip, setOpenTypeLinkTooltip] = useState(-1)
  const [openAdminTypeLinkTooltip, setAdminOpenTypeLinkTooltip] = useState(-1)

  const [linkCopied, setLinkCopied] = useState(false);
  const [selectedSession, setSelectedSession] = useState('');
  const [showQrModal, setShowQrModal] = useState(false);
  const [rewardDistribution, setRewardDistribution] = useState(false);
  const [activeTabType, setActiveTabType] = useState("Active Sessions");
  const { orgDetailsByEmail } = useSelector((state) => state.getOrganisation);
  const [selectedSessionId, setSelectedSessionId] = useState("");
  const [gameRewards, setGameRewards] = useState({});
  const [teamGameSessions, setTeamGameSessions] = useState([])

  const OrgTeamSessions = useSelector((state) => state.getOrgTeamsGameSession)
  const { teamSessions } = OrgTeamSessions;


  useEffect(() => {
    if (teamSessions && teamSessions.message === "sessions fetch sucessfully") {
      let teamSessionsData = teamSessions.data.filter((session) => session?.organizationGame.gameId === id)
      setTeamGameSessions(teamSessionsData)
    }
  }, [OrgTeamSessions])

  useEffect(() => {
    if (orgDetailsByEmail?.data?.isXoxoEnable) {
      dispatch(getXoxodayGames())
    }
  }, [orgDetailsByEmail])

  useEffect(() => {
    if (xoxoGames?.xoxoGames?.data?.length) {
      let xoxoGameList = xoxoGames.xoxoGames?.data?.map(data => data?.gameId);
      setIsXoxoOption(xoxoGameList?.includes(id))
    }
  }, [xoxoGames?.xoxoGames?.data])

  const copyGameLink = async (session, linkType) => {
    const obj = { from: session?.createdBy?.email, organizationId: session?.organizationId, onBoardType: "INVITE" };
    const inviteToken = encryptData(obj);
    let shareLink = "";

    if (session?.mode === 'ANYONE') {
      shareLink = `${CLIENT_URL}/join/guest?gameId=${session?.organizationGame?.gameId}&sessionId=${session?.id}&inviteId=${inviteToken}`;
    } else {
      shareLink = `${CLIENT_URL}/join?gameId=${session?.organizationGame?.gameId}&sessionId=${session?.id}&inviteId=${inviteToken}`;
    }

    if (linkType === "shortUrl") {
      const response = await axiosApiInstance.post(`${BASE_URL}/api/session/session-shortlink`, { sessionLink: shareLink });

      if (response?.data?.message !== "sucessfuly generated short link") {
        return failureAlert("Failed to generate copy url");
      }

      const shortSessionUrl = response.data.shortLink;

      copy(shortSessionUrl)
      setTimeout(() => {
        setLinkCopied(true);
      }, 500);

    }
    else {
      copy(shareLink)
      setTimeout(() => {
        setLinkCopied(true);
      }, 500);

    }



  };

  const handleCopyUrl = (linkType) => {
    if (linkType === "shortUrl") {
      copy(shortGameUrl)
      setLinkCopied(true);
      setTimeout(() => {
        setOpenTypeLinkTooltip(-1)
      }, 300);
      // setLongGameUrl('')
      // setShortGameUrl('')

    }
    else {
      copy(longGameUrl)
      setLinkCopied(true);

      setTimeout(() => {
        setOpenTypeLinkTooltip(-1)

      }, 300);

      // setLongGameUrl('')
      // setShortGameUrl('')
    }
  }



  const handleAdminCopyGameLinkType = async (session, index) => {
    setOpenTypeLinkTooltip(-1)
    setLongGameUrl('')
    setShortGameUrl('')
    if (index === openAdminTypeLinkTooltip) {
      setAdminOpenTypeLinkTooltip(-1)
    }
    else {
      setAdminOpenTypeLinkTooltip(index)
    }

    const obj = { from: session?.createdBy?.email, organizationId: session?.organizationId, onBoardType: "INVITE" };
    const inviteToken = encryptData(obj);
    let shareLink = "";

    if (session?.mode === 'ANYONE') {
      shareLink = `${CLIENT_URL}/join/guest?gameId=${session?.organizationGame?.gameId}&sessionId=${session?.id}&inviteId=${inviteToken}`;
    } else {
      shareLink = `${CLIENT_URL}/join?gameId=${session?.organizationGame?.gameId}&sessionId=${session?.id}&inviteId=${inviteToken}`;
    }
    setLongGameUrl(shareLink)

    const response = await axiosApiInstance.post(`${BASE_URL}/api/session/session-shortlink`, { sessionLink: shareLink });

    if (response?.data?.message !== "sucessfuly generated short link") {
      return failureAlert("Failed to generate copy url");
    }

    const shortSessionUrl = response.data.shortLink;

    setShortGameUrl(shortSessionUrl)
    // copy(shareLink)
  };

  const handleCopyGameLinkType = async (session, index) => {
    setAdminOpenTypeLinkTooltip(-1)
    setLongGameUrl('')
    setShortGameUrl('')
    if (index === openTypeLinkTooltip) {
      setOpenTypeLinkTooltip(-1)
    }
    else {
      setOpenTypeLinkTooltip(index)
    }

    const obj = { from: session?.createdBy?.email, organizationId: session?.organizationId, onBoardType: "INVITE" };
    const inviteToken = encryptData(obj);
    let shareLink = "";

    if (session?.mode === 'ANYONE') {
      shareLink = `${CLIENT_URL}/join/guest?gameId=${session?.organizationGame?.gameId}&sessionId=${session?.id}&inviteId=${inviteToken}`;
    } else {
      shareLink = `${CLIENT_URL}/join?gameId=${session?.organizationGame?.gameId}&sessionId=${session?.id}&inviteId=${inviteToken}`;
    }
    setLongGameUrl(shareLink)

    const response = await axiosApiInstance.post(`${BASE_URL}/api/session/session-shortlink`, { sessionLink: shareLink });

    if (response?.data?.message !== "sucessfuly generated short link") {
      return failureAlert("Failed to generate copy url");
    }

    const shortSessionUrl = response.data.shortLink;

    setShortGameUrl(shortSessionUrl)

    if (navigator.clipboard) {
      navigator.clipboard.writeText(shareLink)
        .then(() => { })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    }
    // copy(shareLink)
  };

  const copyExtramileGameLink = async (session) => {
    const obj = { from: session?.createdBy?.email, organizationId: session?.organizationId, onBoardType: "INVITE" };
    const inviteToken = encryptData(obj);
    let shareLink = "";
    if (session?.mode === 'ANYONE') {
      shareLink = CLIENT_URL + "/join/guest?gameId=" + session?.gameId + "&sessionId=" + session?.id + "&inviteId=" + inviteToken;
    } else {
      shareLink = CLIENT_URL + "/join?gameId=" + session?.gameId + "&sessionId=" + session?.id + "&inviteId=" + inviteToken;
    }
    const response = await axiosApiInstance.post(BASE_URL + "/api/session/session-shortlink", { sessionLink: shareLink })
    if (!response && !response.message === "sucessfuly generated short link") {
      return failureAlert("Failed to generate copy url");
    }
    const shortSessionUrl = response.data.shortLink;
    copy(shortSessionUrl)
    setLinkCopied(true);

  };

  useEffect(() => {
    dispatch(getAllGameSessions(id, page, activeTabType === "Active Sessions" ? false : true));
    dispatch(getGameDetail(id));
    if (OrgRoles.includes(userInfo?.data?.role))
      dispatch(getMyPlans());
    const callBack = async () => {
      try {
        const response = await axios.post(BASE_URL + "/php/API/admin.php", { gameId: id });
        if (response.data && response.data.response && response.data.response.success) {
          setShowConfigure(true);

        }
      } catch (err) {
        console.log("err - ", err);
      }
    }
    callBack();
  }, [id]);

  useEffect(() => {
    if (activeTabType !== "Teams Sessions") {
      dispatch(getAllGameSessions(id, page, activeTabType === "Active Sessions" ? false : true, page));

    }
    else {
      dispatch(getOrgTeamsGameSessions("Active Sessions"))
    }
  }, [page, activeTabType]);


  const handleClick = () => {
    if (userInfo && userInfo.data && userInfo.data.role === ROLES.EMPLOYEE
      && gameDetail && gameDetail.data && !gameDetail.data.allowEmployeeSession)
      return;
    setOpenNewGameModal(true);
  }
  const formatToDate = (dateString) => {
    const date = new Date(dateString);
    const newDateString = `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}
    -${monthNames[date.getMonth()]}-
    ${date.getFullYear()} 
    ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`;
    return newDateString;
  }
  const changePage = (newPage) => {
    setLoaded(false);
    setPage(newPage)
    setTimeout(() => {
      setLoaded(true)
    }, 100);
  }
  const handleEdit = (e, session) => {
    e.preventDefault();
    setEditSession(session);
    setEdit(true);
    setOpenNewGameModal(true);
  }
  const handleDelete = async (session) => {
    setOpenDeleteDomainModal(false);
    const response = await dispatch(deleteGameSession(session.id));
    if (response === 200) {
      dispatch(getAllGameSessions(id, page, activeTabType === "Active Sessions" ? false : true));
    }
  }
  useEffect(() => {
    if (
      (GameSessions && GameSessions.loading) ||
      (GetMyPlans && GetMyPlans.loading) ||
      (GameDetail && GameDetail.loading) ||
      (DeleteSession && DeleteSession.loading)
    ) {
      setLoaded(false);
    }
    else {
      setLoaded(true);
    }
  }, [GameSessions, GameDetail, GetMyPlans, DeleteSession]);

  const handleJoinGame = async (sessionId, from, to) => {
    if ((new Date(from) > new Date()) || (new Date(to) < new Date())) {
      setLoadFlag(prevState => prevState + 1);
      return;
    }
    setLoaded(false);
    const response = await dispatch(getGameServerOtp(id, sessionId));
    if (response && response.status === 200) {
      setJoinPressed(true);
    }
    else {
      setLoaded(true);
    }
  }
  useEffect(() => {
    if (joinPressed && gameServerOtp && gameServerOtp.data) {
      setJoinPressed(false);
      window.location.replace(gameServerOtp.data.redirectUrl);
    }
  }, [gameServerOtp, joinPressed])

  const handleConfigure = async (e, sessionId) => {
    e.preventDefault();
    setLoaded(false);
    const responseotp = await dispatch(getGameServerOtp(id, sessionId))
    if (responseotp && responseotp.data && responseotp.data.data && responseotp.data.data.redirectUrl)
      window.location.replace(responseotp.data.data.redirectUrl + "&admin=true");
    else
      setLoaded(true);
  }

  const setXoxoGameDetails = async (sessionId,) => {
    setXoxoGameSession(sessionId)
    try {
      const result = await axiosApiInstance.post(BASE_URL + "/api/xoxoday-game/get-game-detail", { sessionId: sessionId, gameId: id, orgId: userInfo?.data?.organizationId });

      if (result?.data?.data) {
        setXoxoGameDetail(result.data.data)
      }
    } catch (err) {
      console.log("err - ", err);
    }
    setXoxoModal(true);

  }

  const handleRewardDistributionMessage = async (message, status) => {
    if (status) {
      successAlert(message);
    } else {
      failureAlert(message);
    }
  };

  const handleRewardDistribution = async (sessionId) => {
    setSelectedSessionId(sessionId)
    try {
      const result = await axiosApiInstance.post(
        BASE_URL + "/api/xoxoday-game/points-distribution-access",
        {
          sessionId: sessionId,
          gameId: id,
          userId: userInfo?.data?.id,
          orgId: userInfo?.data?.organizationId
        }
      );
      if (result?.status === 200) {
        setGameRewards(result?.data ?? {})
      }

    } catch (err) {
      console.log("err - ", err);
      setRewardDistribution(false);
    }
    setRewardDistribution(true);
  };


  const successAlert = (content) => {
    toast.success(content, {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      pauseOnFocusLoss: false
    });
  }
  const failureAlert = (content) => {
    toast.warning(content, {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      pauseOnFocusLoss: false
    });
  }

  const handleSessionDuplicate = async (session, userInfo) => {
    const data = {
      "scheduleTo": session.scheduleTo,
      "name": session.name,
      "mode": session.mode,
      "gameId": session.organizationGame.gameId,
      "scheduleFrom": session.scheduleFrom,
      "guestUserFields": session.guestUserFields ? session.guestUserFields : [],
      "isCopy": true,
      "sessionIDOfCopied": session.id
    }
    const response = await dispatch(createGameSession(data));
    if (response.status === 200) {
      dispatch(getOrganisationUsers('', false, '', true));
      setActiveTabType("Active Sessions");
      dispatch(getAllGameSessions(id, page, activeTabType === "Active Sessions" ? false : true, page));

    }
  }

  return (
    <div
    // onClick={(e) => { setOpenTypeLinkTooltip(-1); setAdminOpenTypeLinkTooltip(-1) }}
    >
      {/* Desktop View */}
      {
        openNewGameModal &&
        <StartNewGameModal
          modalid={"new-game-modal"}
          gameId={id} toggle={openNewGameModal}
          setOpenNewGameModal={setOpenNewGameModal}
          editSession={editSession}
          setEditSession={setEditSession}
          edit={edit}
          setEdit={setEdit}
        />
      }
      <div className="games-table-sect">
        <div className="game-head-active">
          <div className="game-head-active">
            <button
              onClick={() => setActiveTabType("Active Sessions")}
              className={`${activeTabType === "Active Sessions"
                ? "text-red-600"
                : "text-gray-600"
                }  text-xl font-medium md:px-12 focus:outline-none rounded-md float-left`}
            >
              Active Sessions
            </button>
            <button
              onClick={() => setActiveTabType("Teams Sessions")}
              className={`${activeTabType === "Teams Sessions"
                ? "text-red-600"
                : "text-gray-600"
                }  text-xl font-medium md:px-12 focus:outline-none rounded-md float-left`}
            >
              Teams Sessions
            </button>
            <button
              onClick={() => setActiveTabType("Expired Sessions")}
              className={`${activeTabType === "Expired Sessions"
                ? "text-red-600"
                : "text-gray-600"
                } px-4 py-2 text-xl font-medium capitalize md:py-3 focus:outline-none rounded-md md:px-12`}
            >
              Expired Sessions
            </button>
          </div>
          {
            activeTabType === "Active Sessions" ? (
              <div className="game-head-new-game">
                <button type="submit"
                  className={`float-right btn btn-secondry ${userInfo &&
                    userInfo.data && userInfo.data.role === ROLES.EMPLOYEE
                    && gameDetail && gameDetail.data && !gameDetail.data.allowEmployeeSession ? "disabled" : ""}`} onClick={handleClick}>
                  Start new Game
                </button>
              </div>
            ) : ""
          }

        </div>
        <LoadingComponent loaded={loaded} >
          <div className="game-table">
            <table>
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Initiated By</th>
                  <th>Game Visibility</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  {/* <th></th> */}
                  {activeTabType === "Active Sessions" || activeTabType === "Teams Sessions" ? (<th>Action</th>) : null}
                </tr>
              </thead>
              <tbody>
                {/* {gameSessions?.paginationData?.totalEntries > 0 ? <></> : <></>} */}
                {
                  activeTabType !== "Teams Sessions" && gameSessions?.publicSessions?.map?.((session, index) => {
                    return (<>
                      <tr key={JSON.stringify(session)}>
                        <td className="games-title">{session?.name}</td>
                        <td>
                          Extramile
                        </td>
                        <td>
                          {
                            session.mode === "PUBLIC" ?
                              <div className="game-mode">
                                <img src={publicOnly} alt="public" /> <span>Public</span>
                              </div>
                              : session.mode === "PRIVATE" ?
                                <div className="game-mode">
                                  <img src={privateOnly} alt="public" /> <span>Private</span>
                                </div>
                                : session.mode === 'INVITE' ?
                                  <div className="game-mode">
                                    <img src={inviteOnly} alt="public" /> <span>Invite Only</span>
                                  </div>
                                  : session?.mode === "ANYONE" ?
                                    <div className="game-mode">
                                      <img src={guestOnly} alt="public" /> <span>Guest User</span>
                                    </div> : null
                          }
                        </td>
                        <td>{formatToDate(session.scheduleFrom)}</td>
                        <td>
                          <div className="game-btn">
                            {formatToDate(session.scheduleTo)}
                            {activeTabType === "Active Sessions" ? (
                              <div className="action-group">
                                <div className="animated-btn-tooltip">
                                  <div class="icon-conatiner"
                                    // onMouseOut={() => setLinkCopied(false)} onClick={(e) => { e.preventDefault(); copyGameLink(session) }} 
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      handleAdminCopyGameLinkType(session, index)
                                    }
                                    }
                                  >
                                    <svg
                                      width="19px"
                                      height="21px"
                                      viewBox="0 0 19 21"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >

                                      <g
                                        id="Page-1"
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                      >
                                        <g id="Artboard" transform="translate(-142.000000, -122.000000)">
                                          <g id="Group" transform="translate(142.000000, 122.000000)">
                                            <path
                                              d="M3.4,4 L11.5,4 L11.5,4 L16,8.25 L16,17.6 C16,19.4777681 14.4777681,21 12.6,21 L3.4,21 C1.52223185,21 6.74049485e-16,19.4777681 0,17.6 L0,7.4 C2.14128934e-16,5.52223185 1.52223185,4 3.4,4 Z"
                                              id="Rectangle-Copy"
                                              fill="#a1a3b2"
                                            ></path>
                                            <path
                                              d="M6.4,0 L12,0 L12,0 L19,6.5 L19,14.6 C19,16.4777681 17.4777681,18 15.6,18 L6.4,18 C4.52223185,18 3,16.4777681 3,14.6 L3,3.4 C3,1.52223185 4.52223185,7.89029623e-16 6.4,0 Z"
                                              id="Rectangle"
                                              fill="#5F638A"
                                            ></path>
                                            <path
                                              d="M12,0 L12,5.5 C12,6.05228475 12.4477153,6.5 13,6.5 L19,6.5 L19,6.5 L12,0 Z"
                                              id="Path-2"
                                              fill="#373a57"
                                            ></path>
                                          </g>
                                        </g>
                                      </g>
                                    </svg>
                                    <svg
                                      width="19px"
                                      height="21px"
                                      viewBox="0 0 19 21"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="Page-1"
                                        stroke="none"
                                        stroke-width="1"
                                        fill="none"
                                        fill-rule="evenodd"
                                      >
                                        <g id="Artboard" transform="translate(-142.000000, -122.000000)">
                                          <g id="Group" transform="translate(142.000000, 122.000000)">
                                            <path
                                              d="M3.4,4 L11.5,4 L11.5,4 L16,8.25 L16,17.6 C16,19.4777681 14.4777681,21 12.6,21 L3.4,21 C1.52223185,21 6.74049485e-16,19.4777681 0,17.6 L0,7.4 C2.14128934e-16,5.52223185 1.52223185,4 3.4,4 Z"
                                              id="Rectangle-Copy"
                                              fill="#a1a3b2"
                                            ></path>
                                            <path
                                              d="M6.4,0 L12,0 L12,0 L19,6.5 L19,14.6 C19,16.4777681 17.4777681,18 15.6,18 L6.4,18 C4.52223185,18 3,16.4777681 3,14.6 L3,3.4 C3,1.52223185 4.52223185,7.89029623e-16 6.4,0 Z"
                                              id="Rectangle"
                                              fill="#5F638A"
                                            ></path>
                                            <path
                                              d="M12,0 L12,5.5 C12,6.05228475 12.4477153,6.5 13,6.5 L19,6.5 L19,6.5 L12,0 Z"
                                              id="Path-2"
                                              fill="#373a57"
                                            ></path>
                                          </g>
                                        </g>
                                      </g>
                                    </svg>

                                  </div>
                                  {/* <div className="hover-tooltip" role="tooltip">
                                        <span className="text-white">{linkCopied ? 'Copied' : 'Copy Link'}</span>
                                      </div> */}

                                </div>
                                {/* <div className="btn-tooltip">
                                  <button className="delete-icon-img" onClick={() => { setSelectedSession(session); setShowQrModal(true); }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="var(--color-theme)" className="w-6 h-6">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z" />
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z" />
                                    </svg>
                                  </button>
                                  <div className="tooltip" role="tooltip">
                                    <span>Download QR</span>
                                  </div>
                                </div> */}
                                <div className="animated-btn-tooltip">
                                  <div class="qr-loader" onClick={() => { setSelectedSession(session); setShowQrModal(true); }}>
                                    <div class="qr-box1"></div>
                                    <div class="qr-box2"></div>
                                    <div class="qr-box3"></div>
                                  </div>
                                  <div className="hover-qrCode-tooltip" role="tooltip">
                                    <span>Download QR</span>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </td>




                        {/* <td>
                          {" "}
                          <div style={{ margin: "0px -10px" }} className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "" : "btn-tooltip"}>
                            <button
                              type="submit"
                              className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "btn btn-primary" : "btn btn-primary disabled"}
                              onClick={e => handleJoinGame(session.id, session.scheduleFrom, session.scheduleTo)}
                            >
                              Join Game
                            </button>
                            tooltip
                            <div className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "tooltip hide" : "tooltip "} role="tooltip">
                              <span>
                                {
                                  new Date(session.scheduleFrom) > new Date() ?
                                    "This session not yet started."
                                    : "This session was ended."
                                }
                              </span>
                            </div>
                          </div>
                        </td> */}

                        {activeTabType === "Active Sessions" ? (<td>
                          {" "}
                          <div style={{ margin: "0px -10px" }} className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "" : "btn-tooltip"}>
                            <button
                              type="submit"
                              className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date()) && session.mode !== "ANYONE") ? "btn btn-primary" : "btn btn-primary disabled"}
                              onClick={e => handleJoinGame(session.id, session.scheduleFrom, session.scheduleTo)}
                              disabled={session.mode === "ANYONE"}
                            >
                              Join Game
                            </button>
                            {/* tooltip */}
                            <div className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "tooltip hide" : "tooltip "} role="tooltip">
                              <span>
                                {
                                  new Date(session.scheduleFrom) > new Date() ?
                                    "This session not yet started."
                                    : "This session was ended."
                                }
                              </span>
                            </div>
                          </div>
                        </td>) : null}

                      </tr>

                      {
                        openAdminTypeLinkTooltip === index ? (
                          <tr className="z-50 bg-white border-none">
                            <td colSpan={6} style={{
                              border: 'none',
                              padding: '5px 0px',
                              height: '60px',
                              background: 'none',
                              width: '100%',
                              backgroundColor: 'white'
                            }}>
                              <div className="copyLink-options-row">
                                <div className="relative flex flex-row items-center h-[40px]">
                                  <span className="link-type-span">Link 1</span>
                                  <div className="flex items-center bg-[#E5EDFB] ml-1 p-1 rounded-[5px] w-[250px] h-[40px] text-ellipsis text-sm whitespace-nowrap overflow-x-hidden" >
                                    {shortGameUrl}
                                  </div>
                                  <div className="right-0 absolute flex justify-center items-center bg-[#E5EDFB] w-[35px] h-[40px] cursor-pointer" id="copy-icon-btn-tip" onMouseOut={() => setLinkCopied(false)} onClick={(e) => handleCopyUrl('shortUrl')}>
                                    <HiLink size={30} color="var(--color-theme)"></HiLink>
                                    <div className="hover-tooltip-type" role="tooltip">
                                      <span className="text-white">{linkCopied ? 'Copied' : 'Copy Link'}</span>
                                    </div>
                                  </div>

                                </div>

                                <div className="relative z-10 flex flex-row items-center ml-10 h-[40px]">
                                  <span className="link-type-span">Link 2</span>
                                  <div className="flex items-center bg-[#E5EDFB] ml-1 p-1 rounded-[5px] w-[250px] h-[40px] text-ellipsis text-sm whitespace-nowrap overflow-x-hidden">
                                    {longGameUrl}
                                  </div>
                                  <div className="right-0 absolute flex justify-center items-center bg-[#E5EDFB] w-[35px] h-[40px] cursor-pointer" id="copy-icon-btn-tip" onMouseOut={() => setLinkCopied(false)} onClick={(e) => handleCopyUrl('lognUrl')}>
                                    <HiLink size={30} color="var(--color-theme)"></HiLink>
                                    <div className="hover-tooltip-type" role="tooltip">
                                      <span className="text-white">{linkCopied ? 'Copied' : 'Copy Link'}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>

                          </tr>
                        )
                          : null
                      }
                    </>)
                  })
                }
                {
                  activeTabType !== "Teams Sessions" && gameSessions?.data?.map((session, index) => {
                    return (<>
                      <tr key={JSON.stringify(session)} className="relative">

                        {
                          session.isCopy ?
                            <td className="games-title">
                              <span style={{ color: "red", fontSize: "0.8rem" }}>Copied Session ({session.copyCount})</span><br />
                              {session.name}
                            </td>
                            :
                            <td className="games-title">
                              {session.name}
                            </td>
                        }

                        <td>
                          {
                            session.name.includes(gameDetail && gameDetail.data && gameDetail.data.title + " - PUBLIC SESSION") && session.guestUserFields === null ? "Default"
                              : (userInfo && userInfo.data && session.createdBy.email === userInfo.data.email) ? "Me"
                                : session.createdBy.email
                          }
                        </td>
                        <td>
                          {
                            session.mode === "PUBLIC" ?
                              <div className="game-mode">
                                <img src={publicOnly} alt="public" /> <span>Public</span>
                              </div>
                              : session.mode === "PRIVATE" ?
                                <div className="game-mode">
                                  <img src={privateOnly} alt="public" /> <span>Private</span>
                                </div>
                                : session.mode === 'INVITE' ?
                                  <div className="game-mode">
                                    <img src={inviteOnly} alt="public" /> <span>Invite Only</span>
                                  </div>
                                  : session?.mode === "ANYONE" ?
                                    <div className="game-mode">
                                      <img src={guestOnly} alt="public" /> <span>Guest User</span>
                                    </div> : null
                          }
                        </td>
                        <td>{formatToDate(session.scheduleFrom)}</td>
                        <td>
                          <div className="game-btn">
                            {formatToDate(session.scheduleTo)}
                            <div className="action-group">
                              {
                                (activeTabType === "Active Sessions" && userInfo && userInfo.data && session.createdBy.email === userInfo.data.email || activeTabType === "Active Sessions" && userInfo && userInfo.data && userInfo.data.role !== ROLES.EMPLOYEE || activeTabType === "Active Sessions" && userInfo && userInfo.data && userInfo.data.role !== ROLES.USER) && (<>
                                  <div className="rounded-[5px] w-[32px] h-[32px] btn-tooltip"
                                    style={{
                                      boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.336)'
                                    }}
                                  >
                                    <button onClick={(e) => handleSessionDuplicate(session, userInfo)}
                                    >
                                      {/* <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                        <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" stroke="#5F638A" />
                                        <g transform="translate(8,8)"> */}
                                      <FaClone style={{ color: "#5F638A", width: "auto", height: "auto" }} />
                                      {/* </g>
                                      </svg> */}
                                    </button>

                                    <div className="tooltip" role="tooltip">
                                      <span>Duplicate Session</span>
                                    </div>
                                  </div>
                                </>)
                              }
                              {(userInfo && userInfo.data && session.createdBy.email === userInfo.data.email) &&
                                <>
                                  {activeTabType === "Active Sessions" ? (<>

                                    <div className="animated-btn-tooltip">
                                      <button class="animated-editBtn" onClick={(e) => handleEdit(e, session)}>
                                        <svg height="1em" viewBox="0 0 512 512">
                                          <path
                                            fill="#5F638A"
                                            d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                          ></path>
                                        </svg>
                                      </button>
                                      <div className="hover-tooltip" role="tooltip">
                                        <span className="text-white">Edit</span>
                                      </div>
                                    </div>

                                    <div className="animated-btn-tooltip">
                                      <button class="session-delete-button" onClick={(e) => { e.preventDefault(); setDeleteSession(session); setOpenDeleteDomainModal(true) }}>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 69 14"
                                          class="bin-top svgIcon"
                                        >
                                          <g clip-path="url(#clip0_35_24)">
                                            <path
                                              fill="black"
                                              d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                            ></path>
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_35_24">
                                              <rect fill="white" height="14" width="69"></rect>
                                            </clipPath>
                                          </defs>
                                        </svg>

                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 69 57"
                                          class="bin-bottom svgIcon"
                                        >
                                          <g clip-path="url(#clip0_35_22)">
                                            <path
                                              fill="black"
                                              d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                            ></path>
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_35_22">
                                              <rect fill="white" height="57" width="69"></rect>
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </button>
                                      <div className="hover-tooltip" role="tooltip">
                                        <span className="text-white">Delete</span>
                                      </div>
                                    </div>

                                    <div className="animated-btn-tooltip">
                                      <div class="icon-conatiner"
                                        // onMouseOut={() => setLinkCopied(false)} onClick={(e) => { e.preventDefault(); copyGameLink(session) }} 
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          handleCopyGameLinkType(session, index)
                                        }
                                        }
                                      >
                                        <svg
                                          width="19px"
                                          height="21px"
                                          viewBox="0 0 19 21"
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >

                                          <g
                                            id="Page-1"
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <g id="Artboard" transform="translate(-142.000000, -122.000000)">
                                              <g id="Group" transform="translate(142.000000, 122.000000)">
                                                <path
                                                  d="M3.4,4 L11.5,4 L11.5,4 L16,8.25 L16,17.6 C16,19.4777681 14.4777681,21 12.6,21 L3.4,21 C1.52223185,21 6.74049485e-16,19.4777681 0,17.6 L0,7.4 C2.14128934e-16,5.52223185 1.52223185,4 3.4,4 Z"
                                                  id="Rectangle-Copy"
                                                  fill="#a1a3b2"
                                                ></path>
                                                <path
                                                  d="M6.4,0 L12,0 L12,0 L19,6.5 L19,14.6 C19,16.4777681 17.4777681,18 15.6,18 L6.4,18 C4.52223185,18 3,16.4777681 3,14.6 L3,3.4 C3,1.52223185 4.52223185,7.89029623e-16 6.4,0 Z"
                                                  id="Rectangle"
                                                  fill="#5F638A"
                                                ></path>
                                                <path
                                                  d="M12,0 L12,5.5 C12,6.05228475 12.4477153,6.5 13,6.5 L19,6.5 L19,6.5 L12,0 Z"
                                                  id="Path-2"
                                                  fill="#373a57"
                                                ></path>
                                              </g>
                                            </g>
                                          </g>
                                        </svg>
                                        <svg
                                          width="19px"
                                          height="21px"
                                          viewBox="0 0 19 21"
                                          version="1.1"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g
                                            id="Page-1"
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <g id="Artboard" transform="translate(-142.000000, -122.000000)">
                                              <g id="Group" transform="translate(142.000000, 122.000000)">
                                                <path
                                                  d="M3.4,4 L11.5,4 L11.5,4 L16,8.25 L16,17.6 C16,19.4777681 14.4777681,21 12.6,21 L3.4,21 C1.52223185,21 6.74049485e-16,19.4777681 0,17.6 L0,7.4 C2.14128934e-16,5.52223185 1.52223185,4 3.4,4 Z"
                                                  id="Rectangle-Copy"
                                                  fill="#a1a3b2"
                                                ></path>
                                                <path
                                                  d="M6.4,0 L12,0 L12,0 L19,6.5 L19,14.6 C19,16.4777681 17.4777681,18 15.6,18 L6.4,18 C4.52223185,18 3,16.4777681 3,14.6 L3,3.4 C3,1.52223185 4.52223185,7.89029623e-16 6.4,0 Z"
                                                  id="Rectangle"
                                                  fill="#5F638A"
                                                ></path>
                                                <path
                                                  d="M12,0 L12,5.5 C12,6.05228475 12.4477153,6.5 13,6.5 L19,6.5 L19,6.5 L12,0 Z"
                                                  id="Path-2"
                                                  fill="#373a57"
                                                ></path>
                                              </g>
                                            </g>
                                          </g>
                                        </svg>

                                      </div>
                                      {/* <div className="hover-tooltip" role="tooltip">
                                        <span className="text-white">{linkCopied ? 'Copied' : 'Copy Link'}</span>
                                      </div> */}

                                    </div>

                                    <div className="animated-btn-tooltip">
                                      <div class="qr-loader" onClick={() => { setSelectedSession(session); setShowQrModal(true); }}>
                                        <div class="qr-box1"></div>
                                        <div class="qr-box2"></div>
                                        <div class="qr-box3"></div>
                                      </div>
                                      <div className="hover-qrCode-tooltip" role="tooltip">
                                        <span className="text-white">Download QR</span>
                                      </div>
                                    </div>

                                    {/**animated btn tooltip end*/}
                                    {isXoxoOption && gameDetail?.data?.title !== 'Secret Santa' ?
                                      <div className="btn-tooltip">
                                        <button className="delete-icon-img" onClick={() => setXoxoGameDetails(session?.id)}>
                                          <img src={rewards} alt="Rewards" />
                                        </button>
                                        <div className="tooltip" role="tooltip">
                                          <span className="text-white">Rewards</span>
                                        </div>
                                      </div> : null}</>)
                                    : (<>
                                      {isXoxoOption && gameDetail?.data?.title !== 'Secret Santa' ? <>
                                        <div className="btn-tooltip">
                                          <button className="delete-icon-img" onClick={() => setXoxoGameDetails(session?.id)}>
                                            <img src={rewards} alt="Rewards" />
                                          </button>
                                          <div className="tooltip" role="tooltip">
                                            <span className="text-white">Rewards</span>
                                          </div>
                                        </div>
                                        <div className="btn-tooltip">
                                          <button
                                            className={`delete-icon-img ${session?.isRewardDistributed ? "cursor-not-allowed" : null}`}
                                            onClick={() => handleRewardDistribution(session?.id)}
                                            disabled={session?.isRewardDistributed}
                                          >
                                            <img src={rewardsDistribution} alt="Rewards Distribution" />
                                          </button>
                                          <div className="tooltip" role="tooltip">
                                            <span className="text-white">Rewards Distribution</span>
                                          </div>
                                        </div></> : null}
                                    </>
                                    )

                                  }
                                  {
                                    activeTabType === "Expired Sessions" ? (

                                      <div className="animated-btn-tooltip">
                                        <button class="session-delete-button" onClick={(e) => { e.preventDefault(); setDeleteSession(session); setOpenDeleteDomainModal(true) }}>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 69 14"
                                            class="bin-top svgIcon"
                                          >
                                            <g clip-path="url(#clip0_35_24)">
                                              <path
                                                fill="black"
                                                d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_35_24">
                                                <rect fill="white" height="14" width="69"></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>

                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 69 57"
                                            class="bin-bottom svgIcon"
                                          >
                                            <g clip-path="url(#clip0_35_22)">
                                              <path
                                                fill="black"
                                                d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                              ></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_35_22">
                                                <rect fill="white" height="57" width="69"></rect>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </button>
                                        <div className="hover-tooltip" role="tooltip">
                                          <span className="text-white">Delete</span>
                                        </div>

                                      </div>

                                    ) : ""
                                  }
                                </>
                              }
                              {
                                userInfo && userInfo.data && (userInfo.data.role === ROLES.ORG_SUPER_ADMIN || session?.createdBy?.email === userInfo.data.email) && showConfigure &&

                                <div className="animated-btn-tooltip" onClick={(e) => handleConfigure(e, session.id)}>
                                  <button class="setting-btn">
                                    <span class="bar bar1"></span>
                                    <span class="bar bar2"></span>
                                    <span class="bar bar1"></span>
                                  </button>
                                  <div className="hover-tooltip" role="tooltip">
                                    <span className="text-white">setting</span>
                                  </div>

                                </div>

                              }
                            </div>
                          </div>
                        </td>
                        {activeTabType === "Active Sessions" ? (<td>
                          {" "}
                          <div style={{ margin: "0px -10px" }} className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "" : "btn-tooltip"}>
                            <button
                              type="submit"
                              className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date()) && session.mode !== "ANYONE") ? "btn btn-primary" : "btn btn-primary disabled"}
                              onClick={e => handleJoinGame(session.id, session.scheduleFrom, session.scheduleTo)}
                              disabled={session.mode === "ANYONE"}
                            >
                              Join Game
                            </button>
                            {/* tooltip */}
                            <div className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "tooltip hide" : "tooltip "} role="tooltip">
                              <span>
                                {
                                  new Date(session.scheduleFrom) > new Date() ?
                                    "This session not yet started."
                                    : "This session was ended."
                                }
                              </span>
                            </div>
                          </div>
                        </td>) : null}
                      </tr>
                      {
                        openTypeLinkTooltip === index ? (
                          <tr className="z-50 bg-white border-none">
                            <td colSpan={6} style={{
                              border: 'none',
                              padding: '5px 0px',
                              height: '60px',
                              background: 'none',
                              width: '100%',
                              backgroundColor: 'white'
                            }}>
                              <div className="copyLink-options-row">
                                <div className="relative flex flex-row items-center h-[40px]">
                                  <span className="link-type-span">Link 1</span>
                                  <div className="flex items-center bg-[#E5EDFB] ml-1 p-1 rounded-[5px] w-[250px] h-[40px] text-ellipsis text-sm whitespace-nowrap overflow-x-hidden" >
                                    {shortGameUrl}
                                  </div>
                                  <div className="right-0 absolute flex justify-center items-center bg-[#E5EDFB] w-[35px] h-[40px] cursor-pointer" id="copy-icon-btn-tip" onMouseOut={() => setLinkCopied(false)} onClick={(e) => handleCopyUrl('shortUrl')}>
                                    <HiLink size={30} color="var(--color-theme)"></HiLink>
                                    <div className="hover-tooltip-type" role="tooltip">
                                      <span className="text-white">{linkCopied ? 'Copied' : 'Copy Link'}</span>
                                    </div>
                                  </div>

                                </div>

                                <div className="relative z-10 flex flex-row items-center ml-10 h-[40px]">
                                  <span className="link-type-span">Link 2</span>
                                  <div className="flex items-center bg-[#E5EDFB] ml-1 p-1 rounded-[5px] w-[250px] h-[40px] text-ellipsis text-sm whitespace-nowrap overflow-x-hidden">
                                    {longGameUrl}
                                  </div>
                                  <div className="right-0 absolute flex justify-center items-center bg-[#E5EDFB] w-[35px] h-[40px] cursor-pointer" id="copy-icon-btn-tip" onMouseOut={() => setLinkCopied(false)} onClick={(e) => handleCopyUrl('lognUrl')}>
                                    <HiLink size={30} color="var(--color-theme)"></HiLink>
                                    <div className="hover-tooltip-type" role="tooltip">
                                      <span className="text-white">{linkCopied ? 'Copied' : 'Copy Link'}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>

                          </tr>
                        )
                          : null
                      }


                    </>);
                  })
                }

                {
                  activeTabType === "Teams Sessions" && teamGameSessions && teamGameSessions.length > 0 && teamGameSessions?.map((session, index) => {
                    return (
                      <>
                        <tr key={JSON.stringify(session)}>



                          <td className="text-sm games-title">
                            {session.name}
                          </td>


                          <td className='font-sans text-sm'>
                            {
                              session.name.includes(gameDetail && gameDetail.data && gameDetail.data.title + " - PUBLIC SESSION") && session.guestUserFields === null ? "Default"
                                : (userInfo && userInfo.data && session.createdBy.email === userInfo.data.email) ? "Me"
                                  : session.createdBy.email
                            }
                          </td>
                          <td className="font-sans text-sm">
                            {/* {
                              session.sessionType === "PUBLIC" ?
                                <div className="game-mode">
                                  <img src={publicOnly} alt="public" /> <span>Public</span>
                                </div>
                                : session.sessionType === "PRIVATE" ?
                                  <div className="game-mode">
                                    <img src={privateOnly} alt="public" /> <span>Private</span>
                                  </div>
                                  : session.sessionType === 'INVITE' ?
                                    <div className="game-mode">
                                      <img src={inviteOnly} alt="public" /> <span>Invite Only</span>
                                    </div>
                                    : session?.sessionType === "ANYONE" ?
                                      <div className="game-mode">
                                        <img src={guestOnly} alt="public" /> <span>Guest User</span>
                                      </div> : null
                            } */}
                            Teams Members
                          </td>
                          <td className='text-sm'>{formatToDate(session.scheduleFrom)}</td>
                          <td>
                            <div className="text-sm game-btn">
                              {formatToDate(session.scheduleTo)}
                              <div className="flex action-group">

                                {(userInfo && userInfo.data && session.createdBy.email === userInfo.data.email) &&
                                  <>


                                    {
                                      activeTabType === "Expired Sessions" ? (

                                        <div className="animated-btn-tooltip">
                                          <button class="session-delete-button"
                                            onClick={(e) => { e.preventDefault(); setDeleteSession(session); setOpenDeleteDomainModal(true) }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 69 14"
                                              class="bin-top svgIcon"
                                            >
                                              <g clip-path="url(#clip0_35_24)">
                                                <path
                                                  fill="black"
                                                  d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                                ></path>
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_35_24">
                                                  <rect fill="white" height="14" width="69"></rect>
                                                </clipPath>
                                              </defs>
                                            </svg>

                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              fill="none"
                                              viewBox="0 0 69 57"
                                              class="bin-bottom svgIcon"
                                            >
                                              <g clip-path="url(#clip0_35_22)">
                                                <path
                                                  fill="black"
                                                  d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                                ></path>
                                              </g>
                                              <defs>
                                                <clipPath id="clip0_35_22">
                                                  <rect fill="white" height="57" width="69"></rect>
                                                </clipPath>
                                              </defs>
                                            </svg>
                                          </button>
                                          <div className="hover-tooltip" role="tooltip">
                                            <span>Delete</span>
                                          </div>

                                        </div>



                                      ) : ""
                                    }
                                  </>
                                }

                              </div>
                            </div>
                          </td>
                          {activeTabType === "Teams Sessions" ? (<td>
                            {" "}
                            <div style={{ margin: "0px -10px" }} className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "" : "btn-tooltip"}>
                              <button
                                type="submit"
                                className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date()) && session.mode !== "ANYONE") ? "btn btn-primary" : "btn btn-primary disabled"}
                                onClick={e => {
                                  e.preventDefault(); // Prevent the default form submission
                                  if (!joinPressed) {
                                    setJoinPressed(true);
                                    handleJoinGame(session.id, session.scheduleFrom, session.scheduleTo, session.organizationGame.gameId);
                                  }
                                }} disabled={joinPressed || session.mode === "ANYONE"}
                              >
                                Join Game
                              </button>
                              {/* tooltip */}
                              <div className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "tooltip hide" : "tooltip "} role="tooltip">
                                <span>
                                  {
                                    new Date(session.scheduleFrom) > new Date() ?
                                      "This session not yet started."
                                      : "This session was ended."
                                  }
                                </span>
                              </div>
                            </div>
                          </td>) : null}


                        </tr>

                        {
                          openTypeLinkTooltip === index ? (
                            <tr className="z-50 bg-white border-none">
                              <td colSpan={6} style={{
                                border: 'none',
                                padding: '5px 0px',
                                height: '60px',
                                background: 'none',
                                width: '100%',
                                backgroundColor: 'white'
                              }}>
                                <div className="copyLink-options-row">
                                  <div className="relative flex flex-row items-center h-[40px]">
                                    <span className="link-type-span">Link 1</span>
                                    <div className="flex items-center bg-[#E5EDFB] ml-1 p-1 rounded-[5px] w-[250px] h-[40px] text-ellipsis text-sm whitespace-nowrap overflow-x-hidden" >
                                      {shortGameUrl}
                                    </div>
                                    <div className="right-0 absolute flex justify-center items-center bg-[#E5EDFB] w-[35px] h-[40px] cursor-pointer" id="copy-icon-btn-tip" onMouseOut={() => setLinkCopied(false)} onClick={(e) => handleCopyUrl('shortUrl')}>
                                      <HiLink size={30} color="var(--color-theme)"></HiLink>
                                      <div className="hover-tooltip-type" role="tooltip">
                                        <span className="text-white">{linkCopied ? 'Copied' : 'Copy Link'}</span>
                                      </div>
                                    </div>

                                  </div>

                                  <div className="relative z-10 flex flex-row items-center ml-10 h-[40px]">
                                    <span className="link-type-span">Link 2</span>
                                    <div className="flex items-center bg-[#E5EDFB] ml-1 p-1 rounded-[5px] w-[250px] h-[40px] text-ellipsis text-sm whitespace-nowrap overflow-x-hidden">
                                      {longGameUrl}
                                    </div>
                                    <div className="right-0 absolute flex justify-center items-center bg-[#E5EDFB] w-[35px] h-[40px] cursor-pointer" id="copy-icon-btn-tip" onMouseOut={() => setLinkCopied(false)} onClick={(e) => handleCopyUrl('lognUrl')}>
                                      <HiLink size={30} color="var(--color-theme)"></HiLink>
                                      <div className="hover-tooltip-type" role="tooltip">
                                        <span className="text-white">{linkCopied ? 'Copied' : 'Copy Link'}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>

                            </tr>
                          )
                            : null
                        }

                      </>)
                  })
                }
                {
                  gameSessions?.publicSessions?.length === 0 && gameSessions?.data?.length === 0 || activeTabType === "Teams Sessions" && teamGameSessions && teamGameSessions.length === 0 ? (<div>
                    <h3 className="no-data-desk">No Sessions Found !</h3>
                  </div>)
                    : null
                }
              </tbody>
            </table>
          </div>

          {/* table for mobile view */}
          <div className="m-games-table table-responsive">
            <div className="game-m-head">
              <button
                onClick={() => setActiveTabType("Active Sessions")}
                className={`${activeTabType === "Active Sessions"
                  ? "text-red-600"
                  : "text-gray-600"
                  }  text-sm font-medium md:px-12 focus:outline-none rounded-md float-left`}
              >
                Active Sessions
              </button>
              <button
                onClick={() => setActiveTabType("Expired Sessions")}
                className={`${activeTabType === "Expired Sessions"
                  ? "text-red-600"
                  : "text-gray-600"
                  } px-4 py-2 text-sm font-medium capitalize md:py-3 rounded-md md:px-12 focus:outline-none`}
              >
                Expired Sessions
              </button>

              {
                activeTabType === "Active Sessions" ? (
                  <button type="submit"
                    className={`btn btn-secondry  ${userInfo &&
                      userInfo.data && userInfo.data.role === ROLES.EMPLOYEE
                      && gameDetail && gameDetail.data && !gameDetail.data.allowEmployeeSession ? "disabled" : ""}`}
                    onClick={handleClick}>
                    Start new Game</button>
                ) : ""
              }


            </div>
            {
              gameSessions?.publicSessions?.map?.((session) => {
                return (
                  <div className="game-m-card c-white-card" key={JSON.stringify(session)}>
                    <div className="game-m-top">
                      <div className="game-detail">
                        <div className="game-mode">
                          <img src={publicOnly} alt="public" /> <span>Public</span>
                        </div>
                      </div>
                    </div>
                    <div className="games-m-title">{session.name}</div>
                    <span className="games-m-date">
                      {formatToDate(session.scheduleFrom)}
                      <img src={gameArrow} alt="public" /> {formatToDate(session.scheduleTo)}
                    </span>
                    <div className="game-m-bottom">
                      <div>
                        <div className="game-m-initiate">Initiated by</div>
                        <div className="game-m-user">
                          Extramile
                        </div>
                      </div>
                      <div>
                        <div className="action-group">

                        </div>
                      </div>
                      <button
                        type="submit"
                        className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date())) ? "btn btn-primary join-game" : "btn btn-primary join-game disabled"}
                        onClick={() => handleJoinGame(session.id, session.scheduleFrom, session.scheduleTo)}
                      >
                        Join Game
                      </button>
                    </div>
                  </div>
                );
              })
            }
            {
              gameSessions?.paginationData?.totalEntries > 0 &&
              gameSessions?.data.map((session, index) => {
                return (
                  <div className="game-m-card c-white-card" key={JSON.stringify(session)}>
                    <div className="game-m-top">
                      <div className="game-detail">
                        {
                          session.mode === "PUBLIC" ?
                            <div className="game-mode">
                              <img src={publicOnly} alt="public" /> <span>Public</span>
                            </div>
                            : session.mode === "PRIVATE" ?
                              <div className="game-mode">
                                <img src={privateOnly} alt="public" /> <span>Private</span>
                              </div>
                              : session.mode === "INVITE" ?
                                <div className="game-mode">
                                  <img src={inviteOnly} alt="public" /> <span>Invite only</span>
                                </div>
                                :
                                <div className="game-mode">
                                  <img src={guestOnly} alt="public" /> <span> Guest User</span>
                                </div>
                        }
                      </div>
                    </div>
                    {
                      session.isCopy ? <>
                        <span style={{ color: "red", fontSize: "0.8rem" }}>Copied Session</span>
                        <div className="games-m-title">

                          {session.name}
                        </div>
                      </>
                        :
                        <div className="games-m-title">{session.name}</div>
                    }

                    <span className="games-m-date">
                      {formatToDate(session.scheduleFrom)}
                      <img src={gameArrow} alt="public" /> {formatToDate(session.scheduleTo)}
                    </span>
                    <div className="game-m-bottom game-m-bottom-mob">
                      <div>
                        <div className="game-m-initiate">Initiated by</div>
                        <div className="game-m-user">
                          {
                            session.name.includes(gameDetail && gameDetail.data && gameDetail.data.title + " - PUBLIC SESSION") ? "Default"
                              : (userInfo && userInfo.data && session.createdBy.email === userInfo.data.email) ? "Me"
                                : session.createdBy.email
                          }
                        </div>
                      </div>



                      <div>
                        <div className="action-group" style={{ marginTop: '12px', marginBottom: '12px', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                          {
                            (activeTabType === "Active Sessions" && userInfo && userInfo.data && session.createdBy.email === userInfo.data.email || activeTabType === "Active Sessions" && userInfo && userInfo.data && userInfo.data.role !== ROLES.EMPLOYEE || activeTabType === "Active Sessions" && userInfo && userInfo.data && userInfo.data.role !== ROLES.USER) && (<>
                              <div className="rounded-[5px] w-[32px] h-[32px] btn-tooltip"
                                style={{
                                  boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.336)'
                                }}
                              >
                                <button onClick={(e) => handleSessionDuplicate(session, userInfo)}>
                                  {/* <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" stroke="#5F638A" />
                                    <g transform="translate(8,8)"> */}
                                  <FaClone style={{ color: "#5F638A", width: "auto", height: "auto" }} />
                                  {/* </g>
                                  </svg> */}
                                </button>

                                <div className="tooltip" role="tooltip">
                                  <span>Duplicate Session</span>
                                </div>
                              </div>
                            </>)
                          }
                          {
                            openTypeLinkTooltip === index ? (

                              <div className="mobile-copyLinks-options">

                                <div className="relative z-10 flex flex-row items-center h-[40px]">
                                  <span className="link-type-span">Link 1</span>
                                  <div className="flex items-center bg-[#E5EDFB] ml-1 p-1 rounded-[5px] w-[120px] h-[40px] text-ellipsis text-sm whitespace-nowrap overflow-x-hidden">
                                    {shortGameUrl}
                                  </div>
                                  <div className="right-0 absolute flex justify-center items-center bg-[#E5EDFB] w-[35px] h-[40px] cursor-pointer" id="copy-icon-btn-tip" onMouseOut={() => setLinkCopied(false)} onClick={(e) => handleCopyUrl('shortUrl')}>
                                    <HiLink size={30} color="var(--color-theme)"></HiLink>
                                    <div className="hover-tooltip-type" role="tooltip">
                                      <span className="text-white">{linkCopied ? 'Copied' : 'Copy Link'}</span>
                                    </div>
                                  </div>
                                </div>


                                <div className="relative z-10 flex flex-row items-center ml-3 h-[40px]">
                                  <span className="link-type-span">Link 2</span>
                                  <div className="flex items-center bg-[#E5EDFB] ml-1 p-1 rounded-[5px] w-[120px] h-[40px] text-ellipsis text-sm whitespace-nowrap overflow-x-hidden">
                                    {longGameUrl}
                                  </div>
                                  <div className="right-0 absolute flex justify-center items-center bg-[#E5EDFB] w-[35px] h-[40px] cursor-pointer" id="copy-icon-btn-tip" onMouseOut={() => setLinkCopied(false)} onClick={(e) => handleCopyUrl('lognUrl')}>
                                    <HiLink size={30} color="var(--color-theme)"></HiLink>
                                    <div className="hover-tooltip-type" role="tooltip">
                                      <span className="text-white">{linkCopied ? 'Copied' : 'Copy Link'}</span>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            )
                              : null
                          }

                          {
                            activeTabType === "Expired Sessions" ? (


                              <div className="animated-btn-tooltip">
                                <button class="session-delete-button" onClick={(e) => { e.preventDefault(); setDeleteSession(session); setOpenDeleteDomainModal(true) }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 69 14"
                                    class="bin-top svgIcon"
                                  >
                                    <g clip-path="url(#clip0_35_24)">
                                      <path
                                        fill="black"
                                        d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_35_24">
                                        <rect fill="white" height="14" width="69"></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>

                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 69 57"
                                    class="bin-bottom svgIcon"
                                  >
                                    <g clip-path="url(#clip0_35_22)">
                                      <path
                                        fill="black"
                                        d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_35_22">
                                        <rect fill="white" height="57" width="69"></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>
                                <div className="hover-tooltip" role="tooltip">
                                  <span className="text-white">Delete</span>
                                </div>

                              </div>
                            ) : ""
                          }

                          {(userInfo && userInfo.data && session.createdBy.email === userInfo.data.email) && new Date(session.scheduleTo) > new Date() &&
                            <>

                              <div className="animated-btn-tooltip">
                                <button class="animated-editBtn" onClick={(e) => handleEdit(e, session)}>
                                  <svg height="1em" viewBox="0 0 512 512">
                                    <path
                                      d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                    ></path>
                                  </svg>
                                </button>
                                <div className="hover-tooltip" role="tooltip">
                                  <span>Edit</span>
                                </div>
                              </div>

                              <div className="animated-btn-tooltip">
                                <button class="session-delete-button" onClick={(e) => { e.preventDefault(); setDeleteSession(session); setOpenDeleteDomainModal(true) }}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 69 14"
                                    class="bin-top svgIcon"
                                  >
                                    <g clip-path="url(#clip0_35_24)">
                                      <path
                                        fill="black"
                                        d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_35_24">
                                        <rect fill="white" height="14" width="69"></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>

                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 69 57"
                                    class="bin-bottom svgIcon"
                                  >
                                    <g clip-path="url(#clip0_35_22)">
                                      <path
                                        fill="black"
                                        d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                      ></path>
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_35_22">
                                        <rect fill="white" height="57" width="69"></rect>
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </button>
                                <div className="hover-tooltip" role="tooltip">
                                  <span className="text-white">Delete</span>
                                </div>

                              </div>
                              <div className="animated-btn-tooltip">
                                <div class="icon-conatiner"
                                  //  onMouseOut={() => setLinkCopied(false)} onClick={(e) => { e.preventDefault(); copyGameLink(session) }} 
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    handleCopyGameLinkType(session, index)
                                  }
                                  }


                                >
                                  <svg
                                    width="19px"
                                    height="21px"
                                    viewBox="0 0 19 21"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >


                                    <g
                                      id="Page-1"
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <g id="Artboard" transform="translate(-142.000000, -122.000000)">
                                        <g id="Group" transform="translate(142.000000, 122.000000)">
                                          <path
                                            d="M3.4,4 L11.5,4 L11.5,4 L16,8.25 L16,17.6 C16,19.4777681 14.4777681,21 12.6,21 L3.4,21 C1.52223185,21 6.74049485e-16,19.4777681 0,17.6 L0,7.4 C2.14128934e-16,5.52223185 1.52223185,4 3.4,4 Z"
                                            id="Rectangle-Copy"
                                            fill="#a1a3b2"
                                          ></path>
                                          <path
                                            d="M6.4,0 L12,0 L12,0 L19,6.5 L19,14.6 C19,16.4777681 17.4777681,18 15.6,18 L6.4,18 C4.52223185,18 3,16.4777681 3,14.6 L3,3.4 C3,1.52223185 4.52223185,7.89029623e-16 6.4,0 Z"
                                            id="Rectangle"
                                            fill="#5F638A"
                                          ></path>
                                          <path
                                            d="M12,0 L12,5.5 C12,6.05228475 12.4477153,6.5 13,6.5 L19,6.5 L19,6.5 L12,0 Z"
                                            id="Path-2"
                                            fill="#373a57"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </svg>
                                  <svg
                                    width="19px"
                                    height="21px"
                                    viewBox="0 0 19 21"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                  // xmlns:xlink="http://www.w3.org/1999/xlink"
                                  >
                                    {/* <title>{linkCopied ? 'Copied' : 'Copy Link'}</title> */}

                                    <g
                                      id="Page-1"
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <g id="Artboard" transform="translate(-142.000000, -122.000000)">
                                        <g id="Group" transform="translate(142.000000, 122.000000)">
                                          <path
                                            d="M3.4,4 L11.5,4 L11.5,4 L16,8.25 L16,17.6 C16,19.4777681 14.4777681,21 12.6,21 L3.4,21 C1.52223185,21 6.74049485e-16,19.4777681 0,17.6 L0,7.4 C2.14128934e-16,5.52223185 1.52223185,4 3.4,4 Z"
                                            id="Rectangle-Copy"
                                            fill="#a1a3b2"
                                          ></path>
                                          <path
                                            d="M6.4,0 L12,0 L12,0 L19,6.5 L19,14.6 C19,16.4777681 17.4777681,18 15.6,18 L6.4,18 C4.52223185,18 3,16.4777681 3,14.6 L3,3.4 C3,1.52223185 4.52223185,7.89029623e-16 6.4,0 Z"
                                            id="Rectangle"
                                            fill="#5F638A"
                                          ></path>
                                          <path
                                            d="M12,0 L12,5.5 C12,6.05228475 12.4477153,6.5 13,6.5 L19,6.5 L19,6.5 L12,0 Z"
                                            id="Path-2"
                                            fill="#373a57"
                                          ></path>
                                        </g>
                                      </g>
                                    </g>
                                  </svg>

                                </div>
                                {/* <div className="hover-tooltip" role="tooltip">
                                  <span className="text-white">{linkCopied ? 'Copied' : 'Copy Link'}</span>
                                </div> */}
                              </div>

                              <div className="animated-btn-tooltip">
                                <div class="qr-loader" onClick={() => { setSelectedSession(session); setShowQrModal(true); }}>
                                  <div class="qr-box1"></div>
                                  <div class="qr-box2"></div>
                                  <div class="qr-box3"></div>
                                </div>
                                <div className="hover-qrCode-tooltip" role="tooltip">
                                  <span className="text-white">Download QR</span>
                                </div>
                              </div>

                              {/*new tooltip btn end*/}


                              {isXoxoOption ?
                                <div className="btn-tooltip">
                                  <button className="delete-icon-img" onClick={() => setXoxoGameDetails(session?.id)}>
                                    <img src={rewards} alt="Rewards" />
                                  </button>
                                  <div className="tooltip" role="tooltip">
                                    <span className="text-white">Rewards</span>
                                  </div>
                                </div> : null}
                            </>
                          }
                          {
                            userInfo && userInfo.data && (userInfo.data.role === ROLES.ORG_SUPER_ADMIN || session?.createdBy?.email === userInfo.data.email) && showConfigure &&


                            <div className="animated-btn-tooltip" onClick={(e) => handleConfigure(e, session.id)}>
                              <button class="setting-btn">
                                <span class="bar bar1"></span>
                                <span class="bar bar2"></span>
                                <span class="bar bar1"></span>
                              </button>
                              <div className="hover-tooltip" role="tooltip">
                                <span className="text-white">setting</span>
                              </div>

                            </div>
                          }

                        </div>
                      </div>
                      {activeTabType === "Active Sessions" ? (<button
                        type="submit"
                        className={((new Date(session.scheduleFrom) <= new Date()) && (new Date(session.scheduleTo) >= new Date()) && session.mode !== "ANYONE") ? "btn btn-primary" : "btn btn-primary disabled"}
                        onClick={e => handleJoinGame(session.id, session.scheduleFrom, session.scheduleTo)}
                        disabled={session.mode === "ANYONE"}
                      >
                        Join Game
                      </button>) : <>{isXoxoOption ? <>
                        <div className="btn-tooltip">
                          <button className="delete-icon-img" onClick={() => setXoxoGameDetails(session?.id)}>
                            <img src={rewards} alt="Rewards" />
                          </button>
                          <div className="tooltip" role="tooltip">
                            <span className="text-white">Rewards</span>
                          </div>
                        </div>
                        <div className="btn-tooltip">
                          <button
                            className={`delete-icon-img ${session?.isRewardDistributed ? "cursor-not-allowed" : null}`}
                            onClick={() => handleRewardDistribution(session?.id)}
                            disabled={session?.isRewardDistributed}
                          >
                            <img src={rewardsDistribution} alt="Rewards Distribution" />
                          </button>
                          <div className="tooltip" role="tooltip">
                            <span className="text-white">Rewards Distribution</span>
                          </div>
                        </div></> : null}
                      </>}
                    </div>
                  </div>
                );
              })
            }
            {
              gameSessions?.publicSessions?.length == 0 && gameSessions?.data?.length == 0 && <div>
                <h3 className="no-data-mob">No Sessions Found !</h3>
              </div>
            }
          </div>
          {/* Pagination */}

          {gameSessions?.paginationData?.totalEntries > 0 &&
            <div className="active-games pagination-wrapper">
              <button
                className={page > 1 ? "pagination-left enable" : "pagination-left"}
                onClick={() => {
                  if (page > 1)
                    changePage(page - 1);
                  window.scrollTo(0, 0);
                }}
              >
                <img src={arrowleft} alt="arrow left" />
              </button>
              <div className="pagination-number">
                <h5>{page}</h5>
                <span>of {Math.ceil(gameSessions?.paginationData?.totalEntries / 12)}{" "}pages</span>
              </div>
              <button
                className={(page < Math.ceil(gameSessions?.paginationData?.totalEntries / 12)) ? "pagination-right enable" : "pagination-right"}
                onClick={() => {
                  if (page < Math.ceil(gameSessions?.paginationData?.totalEntries / 12))
                    changePage(page + 1);
                }}
              >
                <img src={arrowleft} alt="arrow right" />
              </button>
            </div>
          }
        </LoadingComponent>
        {openDeleteDomainModal && <DeleteDomainModal toggle={openDeleteDomainModal} setOpenDeleteDomainModal={setOpenDeleteDomainModal} deleteFunction={handleDelete} deleteData={deleteSession} session />}
        <QrDownloadModal showModal={showQrModal} setShowModal={setShowQrModal} session={selectedSession} />

      </div>
      <ToastContainer style={{ marginTop: '4rem' }} position="bottom-center" />
      {xoxoModal ?
        <XoxoModal userInfo={userInfo} showModal={xoxoModal} setShowModal={setXoxoModal} sessionId={xoxoGameSession} gameId={id} xoxoGameDetail={xoxoGameDetail} activeTabType={activeTabType} /> : null}

      {rewardDistribution ? <RewardDistributionModal selectedSessionId={selectedSessionId} setRewardDistribution={setRewardDistribution} sessionId={xoxoGameSession} gameId={id} userInfo={userInfo} handleRewardDistributionMessage={handleRewardDistributionMessage} gameRewards={gameRewards} /> : null}
    </div >
  );
};
export default ActiveGamesDetail;
