import { UPDATE_CALENDAR_FORM_DATA }  from "../constants/calendarFilterConstants";
import { axiosApiInstance, BASE_URL } from "../../helpers/helper";



// Action creator to update form data
export const updateCalendarFilterFormData = (data) => ({
  type: UPDATE_CALENDAR_FORM_DATA,
  payload: data,
});
