import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./playCard.css";
// image
import quesImg from "../../assets/images/questions.svg";
import pauseIcon from "../../assets/images/pauseIcon.svg";
import diyImg from "../../assets/images/diy.svg";
import Watch from "../../assets/images/Watch.png";
import Virtual from "../../assets/images/Virtual.png"
import People from "../../assets/images/People.png"
import Hybrid from "../../assets/images/Hybrid.png"
import Offline from "../../assets/images/offline.png"
import Multilingual from "../../assets/images/multilingual.png"
import playicon from "../../assets/images/playicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { addToFavourite, removeFromFavourite } from "../../redux/actions/homepageActions";
import like from "../../assets/images/like.svg";
import notlike from "../../assets/images/notLike.svg";
import ReactPlayer from "react-player/lazy";
import { BASE_URL, encryptData, S3_BASE_URL } from "../../helpers/helper";
import { OrgRoles } from "../../helpers/userTypes";

const PlayCard = ({
    title,
    srcImage,
    gameDetail,
    setBannerDeleteModal,
    setSloganFlag,
    setGameDetails,
    isAdmin,
    setOpenShareModal,
    setShareLink,
    setHover,
    setHoverSlogan,
    previouslyPlayed,
    setOpenMobileHoverCard,
    componentStyle
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { favGames } = useSelector((state) => state.getFavGames);
    const { userInfo } = useSelector(state => state.getUser);
    const [isHovered, setIsHovered] = useState(false);
    const [play, setPlay] = useState(false);
    const [liked, setLiked] = useState(false);
    const [touchMoved, setTouchMoved] = useState(false);
    const [hovered, setHovered] = useState(false)
    const addFav = () => {
        setLiked(true);
        dispatch(addToFavourite(gameDetail.id));
    };
    const removeFav = () => {
        setLiked(false);
        setTimeout(() => {
            dispatch(removeFromFavourite(gameDetail.id));
        }, 5000);
    };

    useEffect(() => {
        if (favGames && favGames.data) {
            favGames.data.forEach((game) => {
                if (game.id === gameDetail.id) {
                    setLiked(true)
                    return;
                }
            });
        }
    }, [favGames]);

    const handleClick = (e) => {
        if (previouslyPlayed && gameDetail && gameDetail.lastPlayedSessionID) {
            sessionStorage.setItem("gameReportId", encryptData(gameDetail.id));
            history.push("game-reports/" + gameDetail.lastPlayedSessionID);
        }
        else
            history.push("/game-detail/" + gameDetail.id);
    };
    const handleImageClick = (e) => {
        e.preventDefault();
        if (!touchMoved) {
            setGameDetails({ ...gameDetail, previouslyPlayed });
            setOpenMobileHoverCard(true);
            // history.push("/game-detail/" + gameDetail.id);
        }
    };
    const handleShareClick = () => {
        if (userInfo && userInfo.data && OrgRoles.includes(userInfo.data.role)) {
            const { email, organizationId } = userInfo && userInfo.data;
            const obj = { from: email, organizationId: organizationId, onBoardType: "INVITE", redirectURL: "/game-detail/" + gameDetail.id };
            const inviteToken = encryptData(obj);
            const shareLink = BASE_URL + "/join?inviteId=" + inviteToken;
            setShareLink(shareLink);
        }
        else
            setShareLink(BASE_URL + "/game-detail/" + gameDetail.id);
        setOpenShareModal(true);
    };
    const togglePlay = () => {
        setPlay(prevState => !prevState);
    }
    var width;
    const isWideScreen = window.innerWidth > 1000;
    // if (window.innerWidth > 1000) width = window.innerWidth / 6;
    if (window.innerWidth > 600 && window.innerWidth < 1000) width = window.innerHeight / 5;
    return (
        <>
            <div className="gameListHover hover:scale-[1.2] playcard" style={{ width: isWideScreen ? '200px' : width }}
                onTouchStart={() => setTouchMoved(false)}
                onTouchMove={() => setTouchMoved(true)}
                onTouchEnd={handleImageClick}

                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleClick}>

                <div className="right-0 bottom-0 left-0 z-10 absolute justify-between hidden gameCardDetails">
                    {gameDetail?.duration && <div className="bottom-2 z-10 flex items-center p-1 font-bold">
                        <img fetchpriority="high" className="w-6 h-6" src={Watch} alt="Logo 2" />
                        <span className="ml-1 font-bold text-white">{gameDetail?.duration}:00</span>
                    </div>}
                    {/* {gameDetail?.gameType === "HYBRID" && <img className="right-2 bottom-2 z-10 absolute pl-[10px] w-[3.5rem] h-[2.2rem] font-bold" src={Hybrid} alt="Logo 3" />}
                    {gameDetail?.gameType === "ONLINE" && <img className="right-2 bottom-2 z-10 absolute font-bold" src={Virtual} alt="Logo 3" />}
                    {gameDetail?.gameType === "OFFLINE" && <img className="right-2 bottom-2 z-10 absolute w-[2rem] h-[1.9rem] font-bold" src={Offline} alt="Logo 3" />} */}
                    {gameDetail?.maxPlayers && <div className="bottom-1 flex items-center hidden p-1 font-bold">
                        <img fetchpriority="high" className="w-5 h-5" src={People} alt="Logo 3" />
                        <span className="ml-1 font-bold text-white text-xs">{gameDetail?.maxPlayers}+</span>
                    </div>}
                    {/* <img className="right-2 bottom-[70px] z-10 absolute mb-[5px] font-bold fill-white" src={Multilingual} alt="Logo 3" /> */}
                </div>

                <div className={`playcard-img lg:h-[300px] 
                ${componentStyle && componentStyle?.mobile ? componentStyle.mobile : ''} 
                ${componentStyle && componentStyle?.tablet ? componentStyle.tablet : ''} 
                ${componentStyle && componentStyle?.desktop ? componentStyle.desktop : ''} 
                `}>
                    {
                        srcImage && srcImage[0].includes('https://youtu.be') || srcImage && srcImage[0].includes('https://youtube.com') ? (
                            <img fetchpriority="high" src={S3_BASE_URL + srcImage[1]} alt="img" className="relative rounded-[10px] w-full h-full aspect-[2/3] object-fill" />
                        ) : (
                            srcImage && /\.(gif|jpg|jpeg|png)$/.test(srcImage[0]) ? (
                                <img fetchpriority="high" src={S3_BASE_URL + srcImage[0]} alt="img" className="relative rounded-[10px] w-full h-full aspect-[2/3] object-fill" />
                            ) : (
                                <div className="flex bg-slate-100 rounded-[10px] w-full h-full skeleton-animation"></div>
                            )
                        )
                    }
                    <div className="lg:block absolute inset-0 hidden rounded-[10px]"></div>
                </div>

                {/* <h1 className="lg:block z-10 absolute hidden ml-1 pt-[5px] font-semibold text-white uppercase">{gameDetail.title}</h1> */}
                {isHovered && (
                    <div className="bottom-4 z-10 absolute hidden hidden-description ml-[-50px]">
                        <p className="block-ellipsis max-w-[130px] text-[0.60rem] text-white uppercase leading-[1] tracking-tight whitespace-pre-wrap">
                            {gameDetail.description}
                        </p>
                    </div>)}
            </div>
        </>
    );
};

export default PlayCard;
