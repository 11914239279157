import React, { useState } from "react";
import "./addeditgame.css";

import { useDispatch } from "react-redux";
import PageLayout from "../../components/pagelayout/pagelayout";
import { failureAlert, IsAdmin, REG_EX_URL_FORMAT, successAlert } from "../../helpers/helper";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { logOut } from "../../redux/actions/userAction";
import Select from "react-select"
import upload from '../../assets/images/upload.svg';
import { useEffect } from "react";
import { gameAllCategory, getAllObjectives, getGameDetail, getAllRegion } from "../../redux/actions/gameDetailAction";
import { getAllOrganisations } from "../../redux/actions/organisationActions";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { addNewGame, updateGame } from "../../redux/actions/homepageActions";
import { ToastContainer } from "react-toastify";
import { uploadFile } from "../../redux/actions/commonActions";
import remove from '../../assets/images/remove.svg';
import _isEqual from "lodash/isEqual";
import { toggleLiveGames } from '../../redux/actions/gameDetailAction';

const AddEditGame = (props) => {
    const isAdmin = IsAdmin();
    const dispatch = useDispatch();
    const [uploadedImages, setUploadedImages] = useState([]);
    const [oldUploadedImages, setOldUploadedImages] = useState([]);
    const [loaded, setLoaded] = useState(true);
    const [regionOption, setRegionOption] = useState([{ value: 'ASIA', label: 'ASIA' }, { value: 'MENA', label: 'MENA' }])


    const [error, seterror] = useState("")
    const [gameTypeOptions, setGameType] = useState([{ id: 1, label: "Onsite", value: "OFFLINE" }, { id: 2, label: "Online", value: "ONLINE" }, { id: 3, label: "Hybrid", value: "HYBRID" }, { id: 3, label: "Download Resource", value: "DOWNLOADRESOURCE" }]);
    const [durationOptions, setDurationOptions] = useState([{ id: 1, label: "10min", value: "10" }, { id: 2, label: "20min", value: "20" }, { id: 3, label: "30min", value: "30" }, { id: 4, label: "40min", value: "40" }, { id: 5, label: "50min", value: "50" }, { id: 6, label: "60min", value: "60" }])
    const [headcountOptions, setHeadcountOptions] = useState([{ id: 1, label: "10", value: "10" }, { id: 2, label: "20", value: "20" }, { id: 3, label: "30", value: "30" }, { id: 4, label: "40", value: "40" }, { id: 5, label: "50", value: "50" }, { id: 6, label: "60", value: "60" }, { id: 7, label: "70", value: "70" }, { id: 8, label: "80", value: "80" }, { id: 9, label: "90", value: "90" }, { id: 10, label: "100", value: "100" }, { id: 11, label: "200", value: "200" }, { id: 12, label: "300", value: "300" }, { id: 13, label: "400", value: "400" }, { id: 14, label: "500", value: "500" }])
    const [gameFormatOptions, setGameFormatOptions] = useState([{ id: 1, label: "Team/Individual", value: "Team/Individual" }, { id: 2, label: "Team", value: "Team" }, { id: 3, label: "Individual", value: "Individual" }, { id: 4, label: "Team Fitness Competition", value: "Team Fitness Competition" }, { id: 5, label: "Team Icebreakers", value: "Team Icebreakers" }, { id: 6, label: "Team Olympics Tournament", value: "Team Olympics Tournament" }, { id: 7, label: "Tag Yours Peers", value: "Tag Yours Peers" }, { id: 8, label: "Photobooth - Team Or Individual", value: "Photobooth - Team Or Individual" }, { id: 9, label: "Individual Quiz Contest", value: "Individual Quiz Contest" }]);
    const [contentOptions, setContentOptions] = useState([{ id: 1, label: "India", value: "India" }, { id: 2, label: "Global", value: "Global" }, { id: 1, label: "India Festival", value: "India Festival" }]);

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [objectiveOptions, setObjectiveOptions] = useState([]);
    const [isOffline, setSelectedOption] = useState(false);
    const [islive, setIsLive] = useState(false);
    const [details, setDetails] = useState({
        title: "",
        description: "",
        features: "",
        category: [],
        // objectives: [],
        link: "",
        demoLink: "",
        videoURL: "",
        answerSheet: "",
        mailerTemplate: "",
        downloadResource: "",
        desktopBanner: "",
        mobileBanner: "",
        bannerThumbnail: "",
        isPublic: true,
        region: [],
        duration: "",
        maxPlayers: "",
        gameType: "",
        content: "",
        gameFormat: ""
    })
    const [oldDetails, setOldDetails] = useState({
        title: "",
        description: "",
        features: "",
        category: [],
        // objectives: [],
        link: "",
        demoLink: "",
        videoURL: "",
        answerSheet: "",
        mailerTemplate: "",
        region: [],
        desktopBanner: "",
        mobileBanner: "",
        bannerThumbnail: "",
        duration: "",
        maxPlayers: "",
        gameType: "",
        content: "",
        gameFormat: ""
    })
    const [touched, setTouched] = useState({
        title: false,
        description: false,
        features: false,
        category: false,
        // objectives: false,
        link: false,
        demoLink: false,
        videoURL: false,
        coverMedia: false,
        organisation: false,
        duration: false,
        maxPlayers: false,
        gameType: false,
        content: false,
        gameFormat: false,
        downloadResource: true
    })
    const [submitClicked, setSubmitClicked] = useState(false);
    const [gameId, setGameId] = useState(null);
    const [allOrganisations, setAllOrganisations] = useState([]);
    const [selectedOrganisations, setSelectedOrganisations] = useState([]);
    const [isAsiaSelected, setIsAsiaSelected] = useState(true);
    const [isMenaSelected, setIsMenaSelected] = useState(false);
    const [bannerResolution, setBannerResolution] = useState({
        desktopBanner: false,
        mobileBanner: false,
        bannerThumbnail: false,
    });
    const GameCategory = useSelector((state) => state.gameAllCategory);
    const { gameCategory } = GameCategory;
    const GameObjectives = useSelector(state => state.getAllObjectives);
    const { gameObjectives } = GameObjectives;
    const AddNewgGame = useSelector(state => state.addNewGame);
    const GameDetail = useSelector(state => state.gameDetail);
    const { gameDetail } = GameDetail;
    const GameAllRegion = useSelector(state => state.gameAllRegion);
    const { gameRegion } = GameAllRegion;
    const FileUpload = useSelector(state => state.uploadedFile);
    const UpdateGame = useSelector(state => state.updateGame);
    const { allOrganisationData } = useSelector(state => state.allOrganisationData);
    const AllGames = useSelector(state => state.allGames);
    const { allGames } = AllGames;

    function searchAndCheckProperty(arr, id, property) {
        const obj = arr.find(item => item.id === id);
        return obj && property in obj ? obj[property] : false;
    }
    useEffect(() => {
        if (allGames && allGames.data && props.match.params.id) {
            const result = searchAndCheckProperty(allGames.data, props.match.params.id, 'liveForAllOrgs')

            setIsLive(result)
        }

    }, [allGames]);
    useEffect(() => {
        dispatch(getAllObjectives());
        dispatch(gameAllCategory());
        dispatch(getAllOrganisations("", true));
        dispatch(getAllRegion())

    }, []);

    useEffect(() => {
        if (props.match && props.match.params && props.match.params.id) {
            setGameId(props.match.params.id);
            dispatch(getGameDetail(props.match.params.id));
        }
        else
            setGameId(null);
    }, [props.match])

    const validate = () => {
        const error = {
            title: "",
            description: "",
            features: "",
            category: "",
            link: "",
            demoLink: "",
            videoURL: "",
            coverMedia: "",
            duration: "",
            maxPlayers: "",
            gameType: "",
            desktopBanner: "",
            mobileBanner: "",
            bannerThumbnail: "",
            content: "",
            gameFormat: "",
            category: "",
            downloadResource: "",
            // objectives: ""
        }
        if (touched.title && details.title?.length <= 0)
            error.title = "Please enter valid title";
        if (touched.description && details.description?.length <= 0)
            error.description = "Please enter valid description";
        if (touched.features && details.features?.length <= 0)
            error.features = "Please enter valid features";
        if (touched.link && !REG_EX_URL_FORMAT.test(details.link) && !isOffline && details.gameType !== "DOWNLOADRESOURCE")
            error.link = "Please enter valid game link";
        if (touched.demoLink && !REG_EX_URL_FORMAT.test(details.demoLink) && !isOffline && details.gameType !== "DOWNLOADRESOURCE")
            error.demoLink = "Please enter valid demo link";
        if (touched.videoURL && !REG_EX_URL_FORMAT.test(details.videoURL))
            error.videoURL = "Please enter valid video link";
        // if (touched.content && details.content.length <= 0)
        //     error.content = "Please select atleast one content Type";
        // if (touched.gameFormat && details.gameFormat.length <= 0)
        //     error.content = "Please select atleast one gameFormat";
        if (touched.coverMedia && uploadedImages?.length <= 0)
            error.coverMedia = "Please upload atleast one cover media";
        if (touched.organisation && !details.isPublic && selectedOrganisations?.length == 0)
            error.organisation = "Please select an organisation"
        // if (touched.duration && !details.duration)
        //     error.duration = "Please enter duration"
        // if (touched.maxPlayers && !details.maxPlayers)
        //     error.maxPlayers = "Please enter maxPlayers"
        if (touched.gameType && !details.gameType)
            error.gameType = "Please select an gameType"
        // if (touched.bannerThumbnail && !details.bannerThumbnail)
        //     error.bannerThumbnail = "Please upload a Banner Thumbnail"
        // if (touched.mobileBanner && !details.mobileBanner)
        //     error.mobileBanner = "Please upload a Mobile Banner"
        // if (touched.desktopBanner && !details.desktopBanner && !bannerResolution?.desktopBanner)
        //     error.desktopBanner = "Please upload a Desktop Banner"
        if (touched.category && details.category.length <= 0) {
            error.category = "Please select atleast one category"
        }

         if (touched.downloadResource && !details.downloadResource && details.gameType === "DOWNLOADRESOURCE") {
            error.downloadResource = "Please upload game Download resource"
        }
        // if (touched.objectives && details.objectives.length <= 0 && details.category.length <= 0) {
        //     error.objectives = "Please select atleast one objectives"
        // }
        return error;
    }
    const errors = validate();

    useEffect(() => {
        const region = gameDetail?.data?.region;
        if (region === 'ASIA') {
            setIsAsiaSelected(true)
            setIsMenaSelected(false);

        }
        if (region === 'MENA') {
            setIsAsiaSelected(false)
            setIsMenaSelected(true);

        }

    }, [gameDetail])

    useEffect(() => {
        if (gameDetail && gameDetail.data && gameId) {
            const { data } = gameDetail;
            if (data.region === 'ASIA') {
                setIsAsiaSelected(!isAsiaSelected)
            }
            if (data.region === 'MENA') {
                setIsMenaSelected(!isMenaSelected)
            }
            if (data.gameType === 'OFFLINE') {
                setSelectedOption(true);
            }
            setDetails(prevState => ({
                ...prevState,
                title: data.title,
                videoURL: data.coverMedia[0],
                description: data.description,
                features: data.features,
                link: data.link,
                demoLink: data.demoLink,
                answerSheet: data.answerSheet?.split("/").at(-1) || "",
                mailerTemplate: data.mailerTemplate?.split("/").at(-1) || "",
                downloadResource: data?.downloadResource?.split("/").at(-1) || "",
                category: data.category.map(item => ({ id: item.id, label: item.title, value: item.title })),
                objectives: data.objectives.map(item => ({ id: item.id, label: item.title, value: item.title })),
                isPublic: data.isPublic,
                organizations: data.organizations,
                region: data.region.map(item => ({ id: item.id, label: item.title, value: item.title })),
                duration: data.duration,
                maxPlayers: data.maxPlayers,
                gameType: data.gameType,
                content: data.content,
                gameFormat: data.gameFormat,
                desktopBanner: data.desktopBanner?.split("/").at(-1) || "",
                mobileBanner: data.mobileBanner?.split("/").at(-1) || "",
                bannerThumbnail: data.bannerThumbnail?.split("/").at(-1) || "",
            }))
            setOldDetails(prevState => ({
                ...prevState,
                title: data.title,
                videoURL: data.coverMedia[0],
                description: data.description,
                features: data.features,
                link: data.link,
                demoLink: data.demoLink,
                answerSheet: data.answerSheet?.split("/").at(-1) || "",
                mailerTemplate: data.mailerTemplate?.split("/").at(-1) || "",
                downloadResource: data?.downloadResource?.split("/").at(-1) || "",
                category: data.category.map(item => ({ id: item.id, label: item.title, value: item.title })),
                objectives: data.objectives.map(item => ({ id: item.id, label: item.title, value: item.title })),
                region: data.region.map(item => ({ id: item.id, label: item.title, value: item.title })),
                duration: data.duration,
                maxPlayers: data.maxPlayers,
                gameType: data.gameType,
                content: data.content,
                gameFormat: data.gameFormat,
            }))
            let list = [];
            data.coverMedia.map((item) => {
                if (!item?.includes?.("youtu")) {
                    list.push({ name: item.split("/").pop(), url: item });
                }
            })
            setUploadedImages([...list]);
            setOldUploadedImages([...list]);
        }
    }, [gameDetail]);

    useEffect(() => {
        if (gameCategory && gameCategory.data) {
            const options = gameCategory.data.map(category => (
                { id: category.id, label: category.title, value: category.title }
            ))
            setCategoryOptions(options);
        }
    }, [gameCategory]);
    useEffect(() => {
        if (gameObjectives && gameObjectives.data) {
            const options = gameObjectives.data.map(objective => (
                { id: objective.id, label: objective.title, value: objective.title }
            ))
            setObjectiveOptions(options);
        }
    }, [gameObjectives]);

    useEffect(() => {
        if (gameRegion) {
            const options = gameRegion.map(region => (
                { id: region.id, label: region.title, value: region.title }
            ))
            setRegionOption(options);
        }
    }, [gameRegion]);

    useEffect(() => {
        if (allOrganisationData && allOrganisationData.data && allOrganisationData.data.allOrganisationDetails) {
            setAllOrganisations(allOrganisationData.data.allOrganisationDetails.map(data => (
                { label: data.organizationName, value: data.organizationName, id: data.organizationId }
            )))
        }
    }, [allOrganisationData])


    useEffect(() => {
        if (
            (GameCategory && GameCategory.loading) ||
            (GameObjectives && GameObjectives.loading) ||
            (AddNewgGame && AddNewgGame.loading) ||
            (GameDetail && GameDetail.loading) ||
            (FileUpload && FileUpload.loading) ||
            (UpdateGame && UpdateGame.loading)
        ) {
            setLoaded(false);
        }
        else
            setLoaded(true);
    }, [GameCategory, GameObjectives, AddNewgGame, GameDetail, FileUpload, UpdateGame])

    const signOut = async () => {
        await dispatch(logOut());
        if (isAdmin) props.history.push("/admin");
        else props.history.push("/");
    };

    const handleChange = (e) => {

        const { name, value } = e.target;
        if (name == 'gameType') {
            const isOffline = (value == 'OFFLINE') ? true : false
            setSelectedOption(isOffline);
        }

        setDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleBlur = (e) => {
        const { name } = e.target;
        setTouched(prevState => ({
            ...prevState,
            [name]: true
        }))
    }
    const handleFileChange = (e, key) => {
        e.persist();
        const file = e.target.files[0];
        if (key === "coverMedia") {
            if (file && file.type && file.type.startsWith("image/")) {
                if (!uploadedImages.some(item => item.name === file.name)) {
                    setUploadedImages(prevState => ([
                        ...prevState,
                        file
                    ]))
                }
            }


            e.target.value = [];

        } else if (["desktopBanner", "mobileBanner", "bannerThumbnail"].includes(key) && file && file.type && (file.type.startsWith("image/") || file.type.startsWith("video/"))) {

            if (file && file.type && file.type.startsWith("video/")) {
                const video = document.createElement("video");
                video.onloadedmetadata = async function () {
                    const width = this.videoWidth;
                    const height = this.videoHeight;
                    if (key === "desktopBanner" ? (width === 1850 && height === 420) : key === "mobileBanner" ? (width === 328 && height === 140) : (width === 200 && height === 300)) {
                        setBannerResolution((val) => ({ ...val, [key]: false }));
                        const data = new FormData();
                        data.append("game-banner", file);
                        const response = await dispatch(uploadFile(data));
                        if (response && response.status === 200) {
                            if (response.data && response.data.data && response.data.data.path) {
                                setDetails(prevState => ({
                                    ...prevState,
                                    [key]: response.data.data.path
                                }));
                            }
                        }
                        else if (response?.status?.includes?.("417"))
                            failureAlert("Uploaded file contains some malware!");
                        else if (response?.status?.includes?.("500"))
                            failureAlert("File Format Not supported");
                    }
                    else {
                        setBannerResolution((val) => ({ ...val, [key]: true }));
                    }
                };
                video.src = URL.createObjectURL(file);
            } else {
                const img = new Image();
                let width, height;
                img.onload = async function () {
                    width = this.width;
                    height = this.height;
                    if (key === "desktopBanner" ? (width === 1850 && height === 420) : key === "mobileBanner" ? (width === 328 && height === 140) : (width === 200 && height === 300)) {
                        setBannerResolution((val) => ({ ...val, [key]: false }));
                        const data = new FormData();
                        data.append("game-banner", file);
                        const response = await dispatch(uploadFile(data));
                        if (response && response.status === 200) {
                            if (response.data && response.data.data && response.data.data.path) {
                                setDetails(prevState => ({
                                    ...prevState,
                                    [key]: response.data.data.path
                                }));
                            }
                        }
                        else if (response?.status?.includes?.("417"))
                            failureAlert("Uploaded file contains some malware!");
                        else if (response?.status?.includes?.("500"))
                            failureAlert("File Format Not supported");
                    } else {
                        setBannerResolution((val) => ({ ...val, [key]: true }));
                    }
                }
                const _URL = window.URL || window.webkitURL;
                img.src = _URL.createObjectURL(file);
            }
        } else {
            if ((key === "answerSheet" && file.type !== "application/pdf") || (key === "mailerTemplate" && (!file.name.endsWith(".ppt") && !file.name.endsWith(".pptx") && !file.name.endsWith(".pdf")))
                || (key === "downloadResource" && (!file.name.endsWith(".ppt") && !file.name.endsWith(".pptx") && !file.name.endsWith(".pdf")))
            ) {
                failureAlert("File format not allowed");
                return;
            }
            setDetails(prevState => ({
                ...prevState,
                [key]: file
            }));
        }
    }
    const removeImg = (index) => {
        const list = [...uploadedImages];
        list.splice(index, 1);
        setUploadedImages(list);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (JSON.stringify(details) === JSON.stringify(oldDetails) && JSON.stringify(uploadedImages) === JSON.stringify(oldUploadedImages))
            return;

        if (details.gameType === "DownloadResource") {
            setTouched({
                title: true, description: true, features: true, category: true, videoURL: true, coverMedia: true, organisation: true, duration: true,
                maxPlayers: true,
                gameType: true,
                desktopBanner: true,
                mobileBanner: true,
                bannerThumbnail: true,
                content: true,
                gameFormat: true,
                objectives: true,
                downloadResource: true

            })
        }
        else {
            setTouched({
                title: true, description: true, features: true, category: true, link: true, demoLink: true, videoURL: true, coverMedia: true, organisation: true, duration: true,
                maxPlayers: true,
                gameType: true,
                desktopBanner: true,
                mobileBanner: true,
                bannerThumbnail: true,
                content: true,
                gameFormat: true,
                objectives: true,

            })
        }

        setSubmitClicked(true);

    }
    const uploadFiles = async () => {
        const data = new FormData();
        let length = 0;
        var files = uploadedImages.filter(item => {
            if (item.url)
                return true;
            else {
                data.append("game-images", item);
                length += 1;
                return false;
            }
        })
        if (length > 0) {
            const response = await dispatch(uploadFile(data));
            if (response && response.data) {
                if (response.data.data && response.data.data.path) {
                    files.push({ name: response.data.data.path.split("/").pop(), url: response.data.data.path })
                } else if (response.data.data && response.data.data?.length > 0) {
                    response.data.data.map(item => {
                        files.push({ name: item.split("/").pop(), url: item });
                    })
                }
            }
            else if (response && response.status === "Request failed with status code 417") {
                failureAlert("Uploaded file contains some malware!");
                return response.status;
            }
        }
        return files;
    }
    const uploadPDFFiles = async (file) => {
        if (file) {
            const data = new FormData();
            data.append("game-files", file);
            const response = await dispatch(uploadFile(data, true));
            if (response?.data?.data?.path) {
                return response.data.data.path
            }
            else if (response && response.status === "Request failed with status code 417") {
                failureAlert("Uploaded file contains some malware!");
                return response.status;
            }
            else {
                failureAlert("Something went wrong");
                return response.status;
            }
        }
        return null;
    }
    const handleOrganisationChange = (e) => {
        setSelectedOrganisations(e)
    }
    useEffect(() => {
        const callBack = async () => {
            if (submitClicked) {

                if (_isEqual(validate(), {
                    downloadResource: "", category: "", content: "", gameFormat: "", title: "", description: "", features: "", category: "", link: "", demoLink: "", videoURL: "", coverMedia: "", duration: "", maxPlayers: "", gameType: "", desktopBanner: "", mobileBanner: "", bannerThumbnail: "",
                })) {

                    const { videoURL, category, objectives, region, maxPlayers, duration, ...data } = details;
                    const categoryIds = category.map(item => ({ id: item.id }))
                    // const objectivesids = objectives.map(item => ({ id: item.id }))
                    const regionIds = region.map(item => ({ id: item.id }))

                    let organisationList = [];
                    if (!details.isPublic) {
                        organisationList = selectedOrganisations.map(item => item.id)
                        data.organizations = organisationList
                    }
                    data.maxPlayers = parseInt(maxPlayers)
                    data.duration = parseInt(duration);
                    const images = await uploadFiles();
                    data.answerSheet = typeof (details.answerSheet) == "string" ? details.answerSheet : await uploadPDFFiles(details.answerSheet);
                    data.mailerTemplate = typeof (details.mailerTemplate) == "string" ? details.mailerTemplate : await uploadPDFFiles(details.mailerTemplate);
                    data.downloadResource = typeof (details.downloadResource) == "string" ? details.downloadResource : await uploadPDFFiles(details.downloadResource);

                    let parsedUrlSheet = data.answerSheet?.split('?')[0];
                    let parsedUrlTemp = data.mailerTemplate?.split('?')[0];
                    let parsedUrlResource = data.downloadResource?.split('?')[0];


                    if (data.answerSheet?.split('?')[1]) {
                        data.answerSheet = `uploads/game-files/${parsedUrlSheet}`;
                    }
                    if (data.mailerTemplate?.split('?')[1]) {
                        data.mailerTemplate = `uploads/game-files/${parsedUrlTemp}`;
                    }
                    if (data.downloadResource?.split('?')[1]) {
                        data.downloadResource = `uploads/game-files/${parsedUrlResource}`;
                    }

                    if ([images, data.answerSheet, data.mailerTemplate, data.downloadResource].includes("Request failed with status code 417")) {
                        setSubmitClicked(false);
                        return;
                    }
                    if ([images, data.answerSheet, data.mailerTemplate, data.downloadResource].includes("Request failed with status code 500")) {
                        setSubmitClicked(false);
                        return;
                    }
                    data.coverMedia = [videoURL, ...images.map(item => item.url)];
                    data.category = categoryIds;
                    // data.objectives = objectivesids;
                    data.region = regionIds
                    if (gameId) {
                        const response = await dispatch(updateGame(gameId, { games: [data] }));

                        if (response === 200) {

                            if (data.gameType === "OFFLINE" && !islive) {
                                const res = await dispatch(toggleLiveGames(response.lastInsertedGameId))

                                if (res.status === 200) {
                                    successAlert("Game Added Successfuly");
                                    setTimeout(() => {
                                        props.history.push("/all-games");
                                    }, 1000)
                                }
                                else {
                                    failureAlert("Add Game failed")
                                }
                            }
                            else {
                                successAlert("Game Updated Successfuly");
                                setTimeout(() => {
                                    props.history.push("/all-games");
                                }, 1000)
                            }

                        }
                        else
                            failureAlert("Update Game failed")
                    }
                    else {

                        const response = await dispatch(addNewGame({ games: data }));

                        if (response.status === 200) {

                            if (data.gameType === "OFFLINE") {

                                const res = await dispatch(toggleLiveGames(response.lastInsertedGameId))

                                if (res.status === 200) {
                                    successAlert("Game Added Successfuly");
                                    setTimeout(() => {
                                        props.history.push("/all-games");
                                    }, 1000)
                                }
                                else {
                                    failureAlert("Add Game failed")
                                }
                            }
                            else {
                                successAlert("Game Added Successfuly");
                                setTimeout(() => {
                                    props.history.push("/all-games");
                                }, 1000)
                            }

                        }
                        else if (response && response.message === 'GAMES ALREADY EXISTS') {
                            failureAlert("Game already exists")

                        }
                        else
                            failureAlert("Add Game failed")
                    }
                }
                else {
                    failureAlert("Please ensure all fields are filled out accurately")
                }
                setSubmitClicked(false);
            }
        }
        callBack();
    }, [submitClicked])


    useEffect(() => {
        const orgData = gameDetail && gameDetail.data && gameDetail.data.organizations && gameDetail.data.organizations.map((org) => {
            const orgDetail = allOrganisations?.find?.(ele => org == ele.id)
            return {
                "id": org,
                "label": orgDetail?.label,
                "value": orgDetail?.value
            }
        })
        setSelectedOrganisations(orgData)
    }, [allOrganisations, gameDetail])

    return (
        <div className="admin-homepage">
            <PageLayout
                sidebartitle=""
                active={"Games"}
                category
                sideBarContents={sidebarContentAdmin}
                profile
                {...props}
                signOut={signOut}
                {...props}
                isAdmin={isAdmin}
            >
                <div className="add-game-container">
                    <LoadingComponent loaded={loaded} />
                    <ToastContainer position="bottom-center" />
                    <h4 className="title">{gameId ? "Edit" : "Add"} Game</h4>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="flex-box">
                            <div className="form-group">
                                <label htmlFor="">Select Region</label>
                                <Select
                                    classNamePrefix="react-select"
                                    className="form-select"
                                    options={regionOption}
                                    menuPlacement={"auto"}
                                    isMulti
                                    onChange={(e) => handleChange({ target: { name: "region", value: e } })}
                                    placeholder="Select Region"
                                    value={details.region}
                                    onBlur={() => handleBlur({ target: { name: "region" } })}
                                />
                                <div className="error-message">{errors.region}</div>
                                <div className="form-group">
                                </div>
                            </div>
                        </div>

                        {/* <div className={"flex mb-2 "}>
                            <div className={`flex items-center  w-[100%] border ${isAsiaSelected ? 'border-blue-200' : 'border-gray-200'} rounded dark:border-gray-700 m-2`} >
                                <label onClick={() => { setIsAsiaSelected(!isAsiaSelected) }} for="bordered-checkbox-1" className='px-4 py-3 w-full font-bold text-2xl text-gray-700 dark:text-gray-300 cursor-pointer' >ASIA</label>
                                {isAsiaSelected && (<span className={`px-2 bg-green-300 text-gray-00`}>selected</span>)}
                            </div>
                            <div className={`flex items-center  w-[100%] border ${isMenaSelected ? 'border-blue-200' : 'border-gray-200'} rounded dark:border-gray-700 m-2`}>
                                <label onClick={() => { setIsMenaSelected(!isMenaSelected) }} for="bordered-checkbox-2" className='px-4 py-3 w-full font-bold text-2xl text-gray-700 dark:text-gray-300 cursor-pointer'>MENA</label>
                                {isMenaSelected && (<span className={`px-2 bg-green-300 text-gray-00`}>selected</span>)}
                            </div>
                        </div> */}
                        <div className=" ">{errors.region}</div>
                        <div className="flex-box">
                            <div className="form-group">
                                <label htmlFor="">Title</label>
                                <input type="text" className="form-field" placeholder="Please enter game title" name="title" value={details.title} onChange={handleChange} onBlur={handleBlur} />
                                <div className="error-message">{errors.title}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Video URL</label>
                                <input type="text" className="form-field" placeholder="Enter the video URL" name="videoURL" value={details.videoURL} onChange={handleChange} onBlur={handleBlur} />
                                <div className="error-message">{errors.videoURL}</div>
                            </div>
                        </div>
                        <div className="flex-box">
                            <div className="form-group">
                                <label htmlFor="">Description</label>
                                <textarea id="" cols="30" rows="10" placeholder="Enter the description" value={details.description} name="description" onChange={handleChange} onBlur={handleBlur}></textarea>
                                <div className="error-message">{errors.description}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Features</label>
                                <textarea id="" cols="30" rows="10" placeholder="Enter the features, each feature should be seperated by '*'. Example: '*feature 1. *feature2. '" value={details.features} name="features" onChange={handleChange} onBlur={handleBlur}></textarea>
                                <div className="error-message">{errors.features}</div>
                            </div>
                        </div>
                        <div className="flex-box">
                            <div className="form-group">
                                <label htmlFor="">Game URL</label>
                                <input type="text" className="form-field" placeholder="Please enter game URL" name="link" value={details.link} onChange={handleChange} onBlur={handleBlur} />
                                <div className="error-message">{errors.link}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Demo Game URL</label>
                                <input type="text" className="form-field" placeholder="Enter the demo game URL" name="demoLink" value={details.demoLink} onChange={handleChange} onBlur={handleBlur} />
                                <div className="error-message">{errors.demoLink}</div>
                            </div>
                        </div>
                        <div className="flex-box">
                            <div className="form-group">
                                <label htmlFor="">Experiences</label>
                                <Select
                                    classNamePrefix="react-select"
                                    className="form-select"
                                    options={categoryOptions}
                                    menuPlacement={"auto"}
                                    isMulti
                                    onChange={(e) => handleChange({ target: { name: "category", value: e } })}
                                    placeholder="Select Experiences"
                                    value={details.category}
                                    onBlur={() => handleBlur({ target: { name: "category" } })}
                                />
                                <div className="error-message">{errors.category}</div>
                            </div>
                            {/* <div className="form-group">
                                <label htmlFor="">Objectives</label>
                                <Select
                                    classNamePrefix="react-select"
                                    className="form-select"
                                    options={objectiveOptions}
                                    menuPlacement={"auto"}
                                    onChange={(e) => handleChange({ target: { name: "objectives", value: e } })}
                                    value={details.objectives}
                                    isMulti
                                    placeholder="Select Objectives"
                                    onBlur={() => handleBlur({ target: { name: "objectives" } })}

                                />
                                <div className="error-message">{errors.objectives}</div>
                            </div> */}
                        </div>
                        <div className="flex-box">
                            <div className="form-group">
                                <label htmlFor="">Upload Answer Sheet</label>
                                <div className="cstm-upload-btn">
                                    <label className="upload-btn">
                                        <input type="file" onChange={(e) => handleFileChange(e, "answerSheet")} accept="application/pdf" />
                                        <span>
                                            <img src={upload} alt="upload" />
                                            {details.answerSheet ? "Update" : "Upload"}
                                        </span>
                                    </label>
                                    <button type="submit" className={`btn btn-remove ${details.answerSheet ? "" : "disabled"}`} onClick={() => { setDetails(prevState => ({ ...prevState, answerSheet: null })) }}>
                                        <img src={remove} alt="remove" />
                                        Remove
                                    </button>
                                </div>
                                <div className="error-message">{details.answerSheet?.name ||
                                    details.answerSheet?.split("\\")?.at(-1) || ""}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Upload Mailer Template</label>
                                <div className="cstm-upload-btn">
                                    <label className="upload-btn">
                                        <input type="file" onChange={(e) => handleFileChange(e, "mailerTemplate")} accept="application/pdf,.ppt,.pptx" />
                                        <span>
                                            <img src={upload} alt="upload" />
                                            {details.mailerTemplate ? "Update" : "Upload"}
                                        </span>
                                    </label>
                                    <button type="submit" className={`btn btn-remove ${details.mailerTemplate ? "" : "disabled"}`} onClick={() => { setDetails(prevState => ({ ...prevState, mailerTemplate: null })) }}>
                                        <img src={remove} alt="remove" />
                                        Remove
                                    </button>
                                </div>
                                <div className="error-message">{details.mailerTemplate?.name ||
                                    details.mailerTemplate?.split("/")?.at(-1) || ""}</div>
                            </div>
                        </div>
                        <div className="flex-box">
                            <div className="form-group">
                                <label htmlFor="">Desktop Banner</label>
                                <div className="cstm-upload-btn">
                                    <label className="upload-btn">
                                        <input type="file" onChange={(e) => handleFileChange(e, "desktopBanner")} accept="image/x-png,image/jpg,image/jpeg,image/gif,video/mp4" />
                                        <span>
                                            <img src={upload} alt="upload" />
                                            {details.desktopBanner ? "Update" : "Upload"}
                                        </span>
                                    </label>
                                    <button className={`btn btn-remove ${details.desktopBanner ? "" : "disabled"}`} onClick={() => { setDetails(prevState => ({ ...prevState, desktopBanner: null })) }}>
                                        <img src={remove} alt="remove" />
                                        Remove
                                    </button>
                                </div>
                                <div className="error-message">{details.desktopBanner?.name ||
                                    details.desktopBanner?.split("/")?.at(-1) || ""}</div>
                                <div className="error-message">{bannerResolution?.desktopBanner ? "*Video/Image dimension should be 1850x420" : errors.desktopBanner}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Mobile Banner</label>
                                <div className="cstm-upload-btn">
                                    <label className="upload-btn">
                                        <input type="file" onChange={(e) => handleFileChange(e, "mobileBanner")} accept="image/x-png,image/jpg,image/jpeg,image/gif,video/mp4" />
                                        <span>
                                            <img src={upload} alt="upload" />
                                            {details.mobileBanner ? "Update" : "Upload"}
                                        </span>
                                    </label>
                                    <button className={`btn btn-remove ${details.mobileBanner ? "" : "disabled"}`} onClick={() => { setDetails(prevState => ({ ...prevState, mobileBanner: null })) }}>
                                        <img src={remove} alt="remove" />
                                        Remove
                                    </button>
                                </div>
                                <div className="error-message">{details.mobileBanner?.name ||
                                    details.mobileBanner?.split("/")?.at(-1) || ""}</div>
                                <div className="error-message">{bannerResolution?.mobileBanner ? "*Video/Image dimension should be 328x140" : errors.mobileBanner}</div>

                            </div>


                        </div>
                        <div className="flex-box">
                            <div className="form-group">
                                <label htmlFor="">Banner Thumbnail</label>
                                <div className="cstm-upload-btn">
                                    <label className="upload-btn">
                                        <input type="file" onChange={(e) => handleFileChange(e, "bannerThumbnail")} accept="image/x-png,image/jpg,image/jpeg,image/gif,video/mp4" />
                                        <span>
                                            <img src={upload} alt="upload" />
                                            {details.bannerThumbnail ? "Update" : "Upload"}
                                        </span>
                                    </label>
                                    <button className={`btn btn-remove ${details.bannerThumbnail ? "" : "disabled"}`} onClick={() => { setDetails(prevState => ({ ...prevState, bannerThumbnail: null })) }}>
                                        <img src={remove} alt="remove" />
                                        Remove
                                    </button>
                                </div>
                                <div className="error-message">{details.bannerThumbnail?.name ||
                                    details.bannerThumbnail?.split("/")?.at(-1) || ""}</div>
                                <div className="error-message">{bannerResolution?.bannerThumbnail ? "*Video/Image dimension should be 200x300" : errors.bannerThumbnail}</div>

                            </div>
                            <div className="form-group">
                                <label htmlFor="">Download Resource</label>
                                <div className="cstm-upload-btn">
                                    <label className="upload-btn">
                                        <input type="file" onChange={(e) => handleFileChange(e, "downloadResource")} accept="application/pdf,.ppt,.pptx" />
                                        <span>
                                            <img src={upload} alt="upload" />
                                            {details.downloadResource ? "Update" : "Upload"}
                                        </span>
                                    </label>
                                    <button className={`btn btn-remove ${details.downloadResource ? "" : "disabled"}`} onClick={() => { setDetails(prevState => ({ ...prevState, downloadResource: null })) }}>
                                        <img src={remove} alt="remove" />
                                        Remove
                                    </button>
                                </div>
                                <div className="error-message">{details.downloadResource?.name ||
                                    details.downloadResource?.split("/")?.at(-1) || ""}</div>
                                <div className="error-message">{errors.downloadResource}</div>

                            </div>
                            {/* <div className="form-group"></div> */}
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Upload Cover Media</label>
                            <div className="cstm-upload-btn">
                                <label className="upload-btn">
                                    <input type="file" onChange={(e) => { handleFileChange(e, "coverMedia") }} accept="image/x-png,image/jpg,image/jpeg" />
                                    <span>
                                        <img src={upload} alt="upload" />
                                        Update Image
                                    </span>
                                </label>
                            </div>
                            <div className="error-message">{errors.coverMedia}</div>
                            <ul className="uploaded-images">
                                {
                                    uploadedImages.map((img, index) => {
                                        return (
                                            <li key={JSON.stringify(img)}>{img.name}<span className="close" onClick={() => removeImg(index)}>X</span></li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="flex-box items-center gap-[20px]">
                            <div className="form-group">
                                <label htmlFor="">Game Duration (in minutes)</label>
                                {/* <input type="number" className="form-field" placeholder="Enter game duration" name="duration" value={details.duration} onChange={handleChange} /> */}
                                <Select
                                    classNamePrefix="react-select"
                                    className="form-select"
                                    options={durationOptions}
                                    menuPlacement={"auto"}
                                    onChange={(e) => handleChange({ target: { name: "duration", value: e?.value || null } })}
                                    value={durationOptions.find(option => Number(option.value) === Number(details.duration))}


                                    placeholder="Enter game duration"
                                />
                                <div className="error-message">{errors.duration}</div>

                            </div>
                            <div className="form-group">
                                <label htmlFor="">Headcount</label>
                                {/* <input type="number" className="form-field" placeholder="Enter headcount" name="maxPlayers" value={details.maxPlayers} onChange={handleChange} /> */}
                                <Select
                                    classNamePrefix="react-select"
                                    className="form-select"
                                    options={headcountOptions}
                                    menuPlacement={"auto"}
                                    onChange={(e) => handleChange({ target: { name: "maxPlayers", value: e?.value || null } })}
                                    value={headcountOptions.find(option => Number(option.value) === Number(details.maxPlayers))}


                                    placeholder="Enter headcount"
                                />

                                <div className="error-message">{errors.maxPlayers}</div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="">Game Type</label>
                                {/* <select className="form-field" name="gameType" value={details.gameType} onChange={handleChange}>
                                    <option value="virtual">Virtual</option>
                                    <option value="hybrid">Hybrid</option>
                                </select> */}
                                <Select
                                    classNamePrefix="react-select"
                                    className="form-select"
                                    options={gameTypeOptions}
                                    menuPlacement={"auto"}
                                    onChange={(e) => handleChange({ target: { name: "gameType", value: e?.value || null } })}
                                    value={gameTypeOptions.find(option => option.value === details.gameType)}

                                    placeholder="Select gameType"
                                />
                                <div className="error-message">{errors.gameType}</div>
                            </div>
                        </div>
                        <div className="flex-box items-center gap-[20px]">

                            <div className="form-group">
                                <label htmlFor="">Content</label>
                                {/* <input type="number" className="form-field" placeholder="Enter headcount" name="maxPlayers" value={details.maxPlayers} onChange={handleChange} /> */}
                                <Select
                                    classNamePrefix="react-select"
                                    className="form-select"
                                    options={contentOptions}
                                    menuPlacement={"auto"}
                                    onChange={(e) => handleChange({ target: { name: "content", value: e?.value || null } })}
                                    value={contentOptions.find(option => (option.value) === (details.content))}


                                    placeholder="Enter Content Type"
                                />

                                <div className="error-message">{errors.maxPlayers}</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Game Format</label>
                                {/* <input type="number" className="form-field" placeholder="Enter headcount" name="maxPlayers" value={details.maxPlayers} onChange={handleChange} /> */}
                                <Select
                                    classNamePrefix="react-select"
                                    className="form-select"
                                    options={gameFormatOptions}
                                    menuPlacement={"auto"}
                                    onChange={(e) => handleChange({ target: { name: "gameFormat", value: e?.value || null } })}
                                    value={gameFormatOptions.find(option => (option.value) === (details.gameFormat))}


                                    placeholder="Enter Game Formet"
                                />

                                <div className="error-message">{errors.maxPlayers}</div>
                            </div>
                        </div>


                        <div className="flex-box">
                            <div className="form-group" style={{ flexDirection: "row", alignItems: "center" }}>
                                <label htmlFor="">Public Game</label>
                                <input type="checkbox" name='isPublicGame' checked={details.isPublic} onClick={(e) => setDetails({ ...details, isPublic: e.target.checked })} style={{ width: "32px" }} />
                            </div>
                            <div className="form-group">
                                {!details.isPublic &&
                                    <>
                                        <label htmlFor="">Organizations</label>
                                        <Select
                                            classNamePrefix="react-select"
                                            className="form-select"
                                            options={allOrganisations}
                                            menuPlacement={"auto"}
                                            isMulti
                                            placeholder="Select Organisation"
                                            onChange={handleOrganisationChange}
                                            value={selectedOrganisations}
                                        />
                                        <div className="error-message">{errors.organisation}</div>
                                    </>
                                }
                            </div>
                        </div>
                        <button className={`btn btn-primary ${JSON.stringify(details) === JSON.stringify(oldDetails) && JSON.stringify(uploadedImages) === JSON.stringify(oldUploadedImages) ? "disabled" : ""}`} onClick={(e) => { handleSubmit(e) }}>{gameId ? "Save" : "Add"} Game</button>
                    </form>
                </div>
            </PageLayout>
        </div>
    )
}

export default AddEditGame
