import React, { useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import search from "../../assets/images/search.svg";
import "./allgames.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllGames,
  getContactUs,
  homeSearch,
  updateGame,
} from "../../redux/actions/homepageActions";
import { logOut } from "../../redux/actions/userAction";
import PageLayout from "../../components/pagelayout/pagelayout";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import useDebouncedSearch from "../../helpers/debounce";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { failureAlert, IsAdmin, successAlert } from "../../helpers/helper";
import Playcardlarge from "../../components/playCard/playcardlarge";
import arrowleft from "../../assets/images/paginationarrow.svg";
import PlayCardMobileHoverCard from "../../components/playCard/playCardMobileHoverCard";
import { Link } from "react-router-dom";
import ConfirmModal from "../../components/modal/confirmModal";
import { ToastContainer } from "react-toastify";
import Select from 'react-select';
import { getAllRegion } from "../../redux/actions/gameDetailAction";
import { toggleLiveGames } from '../../redux/actions/gameDetailAction';




function AllGamesData(props) {
  const useSearch = () => useDebouncedSearch((text, region) => searchGames(text, region));
  const searchGames = (text, region) => {
    dispatch(homeSearch(text, region, '', '', '', page));
    setPage(1);
  }
  const { searchText, setSearchText, results, setSearchRegion } = useSearch();

  const dispatch = useDispatch();
  var isAdmin = IsAdmin();
  const [loaded, setloaded] = useState(true);
  const [page, setPage] = useState(1);
  const [gameDetails, setGameDetails] = useState({});
  const [openMobileHoverCard, setOpenMobileHoverCard] = useState(false);
  const [gamesList, setGamesList] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletGameData, setDeleteGameData] = useState("");
  const [triggerGameLive, setTriggerGameLive] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState({ regionId: "All", regionName: "All" });
  const [toggleGameQuestion, setToggleGameQuestion] = useState('');
  const [toggleGameInfo, setToggleGameInfo] = useState({
    gameId: '',
    isLive: false,
    isPublic: true
  });
  const [togglePage, setTogglePage] = useState(false);

  const SearchResults = useSelector((state) => state.homeSearch);
  const { searchResults } = SearchResults;
  const ContactUs = useSelector((state) => state.homeContactUs);
  const { contactUs } = ContactUs
  // const AllGames = useSelector(state => state.allGames);
  // const { allGames } = AllGames;
  const GameAllRegion = useSelector(state => state.gameAllRegion);
  const { gameRegion } = GameAllRegion;
  const [regionOptions, setRegionOption] = useState([])
  const UpdateGame = useSelector(state => state.updateGame);
  const ToggleLiveGame = useSelector((state) => state.toggleLiveGame);
  const { toggledGame } = ToggleLiveGame;
  const [allGames, setAllGames] = useState([])

  useEffect(() => {
    // dispatch(getAllGames(false, "", false, 1));
    dispatch(getContactUs());
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    // dispatch(getAllGames(false, searchText, false, page, selectedRegion?.regionId));
    dispatch(homeSearch(searchText, selectedRegion?.regionId, '', '', '', page));

  }, [page, selectedRegion, searchText])

  useEffect(() => { dispatch(getAllRegion()) }, [])

  useEffect(() => {
    if (gameRegion) {
      const options = gameRegion.map((item) => {
        return { regionId: item.id, regionName: item.title }
      })
      setRegionOption(options)
    }
  }, [gameRegion])

  useEffect(() => {
    if (
      (ToggleLiveGame && ToggleLiveGame.loading) ||
      (SearchResults && SearchResults.loading) ||
      (ContactUs && ContactUs.loading) ||
      (results && results.loading) ||
      // (AllGames && AllGames.loading) ||
      (UpdateGame && UpdateGame.loading)
    ) {
      setloaded(false);
    }
    else {
      setloaded(true)

    }
  }, [SearchResults, ContactUs, results, UpdateGame, ToggleLiveGame])
  useEffect(() => {
    if (searchResults) {
      setAllGames(searchResults)
    }
  }, [searchResults])

  useEffect(() => {
    if (allGames && allGames.data && togglePage) {
      const games = allGames.data.sort((a, b) => {
        if (new Date(a.createdAt) < new Date(b.createdAt))
          return 1;
        else if (new Date(a.createdAt) > new Date(b.createdAt))
          return -1;
        else
          return 0;
      })
      setGamesList(games);
      setTogglePage(false)

    }
    else if (searchText.length === 0 && searchResults && searchResults.data && allGames && allGames.data) {
      // const games = allGames.data.sort((a, b) => {
      //   if (new Date(a.createdAt) < new Date(b.createdAt))
      //     return 1;
      //   else if (new Date(a.createdAt) > new Date(b.createdAt))
      //     return -1;
      //   else
      //     return 0;
      // })
      const games = allGames.data;
      setGamesList(games);
    }
    else if (searchText.length > 0 && searchResults && searchResults.data && !togglePage) {

      setGamesList(searchResults.data);

    }
  }, [allGames, searchResults])
  const handleChange = (e) => {
    setSearchText(e.target.value);
    setSearchRegion(selectedRegion?.regionId);
    changePage(1);
    // if (e.target.value.length === 0)
    //   dispatch(homeSearch(""));
  };
  useEffect(() => {
    if (props.location && props.location.search) {
      const page = new URLSearchParams(props.location.search).get('page');
      if (page > 1)
        setPage(parseInt(page));
    }
  }, [props.location]);

  useEffect(() => {
    if (toggleGameInfo.gameId && toggleGameInfo.isLive === true && toggleGameInfo.isPublic === true) {
      setToggleGameQuestion("Are you sure you want to make this game offline for all organisations?");
    }
    if (toggleGameInfo.gameId && toggleGameInfo.isLive === false && toggleGameInfo.isPublic === true) {
      setToggleGameQuestion("Are you sure you want to make this game live for all organisations?");

    }
    if (toggleGameInfo.gameId && toggleGameInfo.isLive === true && toggleGameInfo.isPublic === false) {
      setToggleGameQuestion("Are you sure you want to make this game offline for selected organisations?");
    }
    if (toggleGameInfo.gameId && toggleGameInfo.isLive === false && toggleGameInfo.isPublic === false) {
      setToggleGameQuestion("Are you sure you want to make this game live for selected organisations?");

    }
  }, [toggleGameInfo])

  const changePage = (newPage) => {
    // setloaded(false)
    // setTimeout(() => setloaded(true), 1000);
    props.history.push("/all-games?page=" + newPage);
    setPage(newPage);
  }
  const handleDelete = (game) => {
    setDeleteGameData(game);
    setOpenDeleteModal(true);
  }
  const handleConfirmDelete = async () => {
    const objectives = deletGameData.objectives.map(objective => ({ id: objective.id }));
    const categories = deletGameData.category.map(category => ({ id: category.id }));
    const gameData = { objectives, category: categories, isDisabled: true }
    const response = await dispatch(updateGame(deletGameData.id, { games: [gameData] }));
    if (response === 200) {
      // dispatch(getAllGames(false, "", false, page));
      dispatch(homeSearch(searchText, selectedRegion?.regionId, '', '', '', page));
      successAlert("Game Deleted Successfully")
      setSearchText("");
    }
    else {
      failureAlert("Something went wrong");
    }
    setOpenDeleteModal(false);
  }

  const handleRegionChange = (value) => {
    setSelectedRegion(value);
    setSearchText('');
  }

  const handleAdminGameLiveToggle = async (e) => {

    setloaded(false);
    if (toggleGameInfo.gameId && toggleGameInfo.isLive) {
      const response = await dispatch(toggleLiveGames(toggleGameInfo.gameId))
      if (response.status === 200) {
        dispatch(homeSearch(searchText, selectedRegion?.regionId, '', '', '', page));
        dispatch(getAllGames(false, searchText, false, page, selectedRegion?.regionId));
        setToggleGameInfo({
          gameId: '',
          isLive: false,
          isPublic: true
        })
        setToggleGameQuestion('');
        setTriggerGameLive(false);

      }

    }
    if (toggleGameInfo.gameId && !toggleGameInfo.isLive) {
      const response = await dispatch(toggleLiveGames(toggleGameInfo.gameId))
      if (response.status === 200) {
        dispatch(homeSearch(searchText, selectedRegion?.regionId, '', '', '',page));
        dispatch(getAllGames(false, searchText, false, page, selectedRegion?.regionId));
        setToggleGameInfo({
          gameId: '',
          isLive: false,
          isPublic: true
        })
        setToggleGameQuestion('');
        setTriggerGameLive(false);
      }
    }
  }
useEffect(()=>{

},[gamesList])
  return (
    <div>
      <div className="allgames-container">
        {
          openDeleteModal &&
          <ConfirmModal modalid="confirm-modal" toggle={openDeleteModal} setOpenConfirmModal={setOpenDeleteModal} title="Delete Game" question="Are you sure to delete this game?" confirmFunction={handleConfirmDelete} />
        }
        {
          triggerGameLive && toggleGameQuestion &&
          <ConfirmModal modalid="confirm-modal" toggle={triggerGameLive} setOpenConfirmModal={setTriggerGameLive} question={toggleGameQuestion} confirmFunction={handleAdminGameLiveToggle} />

        }
        <ToastContainer position="bottom-center" />
        <div className="all-games">
          <h4 className="title">All Games</h4>
          <div className="top">
            <div className="input-icon">
              <img src={search} alt="search" />
              <input
                type="text"
                name="search"
                value={searchText}
                onChange={handleChange}
                placeholder="Search by Game Name"
              />
            </div>
            <Select
              options={[{ regionId: "All", regionName: "All" }, ...regionOptions]}
              onChange={(value) => handleRegionChange(value)}
              value={selectedRegion}
              className="form-control mx-2"
              placeholder="Select Organization"
              menuPlacement="auto"
              menuPosition="fixed"
              getOptionLabel={(option) => option?.regionName}
              getOptionValue={(option) => option.regionId}
              styles={{
                control: (base) => ({
                  ...base,
                  height: 35,
                  minWidth: 200,
                  boxSizing: "content-box"
                }),
                menu: (base) => ({
                  ...base,
                  // width: 300
                })
              }}
            />
            {allGames?.paginationData?.totalEntries && <span className="px-4 py-1 border rounded font-medium text-2xl text-gray-600">{allGames?.paginationData?.totalEntries} Games</span>}

            {/* <CSVLink filename={`${Date.now()}_ALL_GAME_CSV`} data={dataExport} headers={csvHeaders}>
              <button className="btn btn-primary">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 004.5 9.75v7.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25v-7.5a2.25 2.25 0 00-2.25-2.25h-.75m0-3l-3-3m0 0l-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 012.25 2.25v7.5a2.25 2.25 0 01-2.25 2.25h-7.5a2.25 2.25 0 01-2.25-2.25v-.75" />
                </svg>
                Export
              </button>
            </CSVLink> */}
            <Link to={"/all-games/game"}>
              <button className="btn btn-primary">Add New Game</button>
            </Link>
          </div>
          <LoadingComponent loaded={loaded} >
            <div className="playcard-wrapper">
              {
                gamesList.length > 0 ? (
                  gamesList.map((game, index) => {
                    return (
                      <Playcardlarge
                        key={JSON.stringify(game)}
                        srcImage={game?.coverMedia}
                        title={game.title}
                        gameDetail={game}
                        setTriggerGameLive={setTriggerGameLive}
                        setGameDetails={setGameDetails}
                        setOpenMobileHoverCard={setOpenMobileHoverCard}
                        handleDelete={handleDelete}
                        setToggleGameInfo={setToggleGameInfo}
                      />
                    );
                  })
                ) : (
                  <div className="no-game-wrapper">
                    <div>
                      <h3 className="no-game">
                        {
                          searchText.length === 0 ?
                            "Currently, there are no games."
                            : "Currently, there are no games with this name."
                        }
                      </h3>
                      <span className="no-game-span" onClick={() => props.history.push("/")}>
                        Go to Homepage
                      </span>
                    </div>
                  </div>
                )
              }
            </div>
            {
              gamesList.length > 0 &&
              <div className="pagination-wrapper">
                <button
                  className={page > 1 ? "pagination-left enable" : "pagination-left"}
                  onClick={() => {
                    if (page > 1) changePage(page - 1);
                    window.scrollTo(0, 0);
                  }}
                >
                  <img src={arrowleft} alt="arrow left" />
                </button>
                <div className="pagination-number">
                  <h5>{page}</h5>
                  <span>
                    of {allGames?.paginationData?.totalEntries ? Math.ceil(allGames?.paginationData?.totalEntries / 15) : 1}{" "}
                    pages
                  </span>
                </div>
                <button
                  className={(page < Math.ceil(allGames?.paginationData?.totalEntries / 15)) ? "pagination-right enable" : "pagination-right"}
                  onClick={() => {
                    if (page < Math.ceil(allGames?.paginationData?.totalEntries / 15)) {
                      changePage(page + 1);
                      setTogglePage(true);
                    }
                  }}
                >
                  <img src={arrowleft} alt="arrow right" />
                </button>
              </div>
            }
          </LoadingComponent>
        </div>
        {
          openMobileHoverCard &&
          <PlayCardMobileHoverCard
            gameDetail={gameDetails}
            setOpenMobileHoverCard={setOpenMobileHoverCard}
          />
        }
      </div>
      {contactUs && !isAdmin ? <Footer {...props} isAdmin={isAdmin} contactUs={contactUs.data} /> : ""}
    </div>
  );
}

const AllGames = (props) => {
  const isAdmin = IsAdmin();
  const dispatch = useDispatch();
  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };
  if (isAdmin)
    return (
      <div className='admin-homepage'>

        <PageLayout
          sidebartitle=""
          active={"Games"}
          category
          sideBarContents={sidebarContentAdmin}
          profile
          {...props}
          signOut={signOut}
          {...props}
          isAdmin={isAdmin}
        >
          <AllGamesData {...props} />
        </PageLayout>
      </div>
    )
  else
    return (
      <>
        <Header profile {...props} signOut={signOut} {...props} isAdmin={isAdmin} />
        <AllGamesData {...props} />
      </>
    )
}

export default AllGames;
