import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./playCard.css";
// image
import quesImg from "../../assets/images/questions.svg";
import pauseIcon from "../../assets/images/pauseIcon.svg";
import diyImg from "../../assets/images/diy.svg";
import Watch from "../../assets/images/Watch.png";
import Virtual from "../../assets/images/Virtual.png"
import People from "../../assets/images/People.png"
import Hybrid from "../../assets/images/Hybrid.png"
import Offline from "../../assets/images/offline.png"
import Multilingual from "../../assets/images/multilingual.png"
import playicon from "../../assets/images/playicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { addToFavourite, removeFromFavourite } from "../../redux/actions/homepageActions";
import like from "../../assets/images/like.svg";
import notlike from "../../assets/images/notLike.svg";
import ReactPlayer from "react-player/lazy";
import { BASE_URL, encryptData, S3_BASE_URL } from "../../helpers/helper";
import { OrgRoles } from "../../helpers/userTypes";

const WebinarPlayCard = ({
    title,
    srcImage,
    gameDetail,
    setBannerDeleteModal,
    setSloganFlag,
    setGameDetails,
    isAdmin,
    setOpenShareModal,
    setShareLink,
    setHover,
    setHoverSlogan,
    previouslyPlayed,
    setOpenMobileHoverCard,
    webinarClickFunction,
    isFromWebinar,
    componentStyle
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { favGames } = useSelector((state) => state.getFavGames);
    const { userInfo } = useSelector(state => state.getUser);
    const [isHovered, setIsHovered] = useState(false);
    const [play, setPlay] = useState(false);
    const [liked, setLiked] = useState(false);
    const [touchMoved, setTouchMoved] = useState(false);
    const [hovered, setHovered] = useState(false)
    const addFav = () => {
        setLiked(true);
        dispatch(addToFavourite(gameDetail.id));
    };

    const handleClick = (e) => {
        if (isFromWebinar) {
            webinarClickFunction()
        }

    };
    const handleImageClick = (e) => {
        e.preventDefault();
        if (isFromWebinar) {
            webinarClickFunction()
        }
    };
    const togglePlay = () => {
        setPlay(prevState => !prevState);
    }
    var width;
    const isWideScreen = window.innerWidth > 1000;
    // if (window.innerWidth > 1000) width = window.innerWidth / 6;
    if (window.innerWidth > 600 && window.innerWidth < 1000) width = window.innerHeight / 5;
    return (
        <>
            {/* <div className="playcard hover:scale-[1.2] gameListHover-webinar" style={{ width: isWideScreen ? '200px' : width, margin:'10px' }}
                onTouchStart={() => setTouchMoved(false)}
                onTouchMove={() => setTouchMoved(true)}
                onTouchEnd={handleImageClick}

                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleClick}
                > */}
            <div className="playcard playcard-large gameListHover-webinar"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleClick}

                onTouchStart={() => setTouchMoved(false)}
                onTouchMove={() => setTouchMoved(true)}
                onTouchEnd={handleImageClick}
            >
             <div className={`playcard-img ${componentStyle && componentStyle?.mobile ? componentStyle.mobile : ''} 
                ${componentStyle && componentStyle?.tablet ? componentStyle.tablet : ''} 
                ${componentStyle && componentStyle?.desktop ? componentStyle.desktop : ''} 
                `}>                    {/* <img fetchpriority="high" src={srcImage[0] && srcImage[0].includes('https://youtu.be') ? srcImage && S3_BASE_URL + srcImage[1] : srcImage && S3_BASE_URL + srcImage[0]} alt="img" className="relative" /> */}
                    {/* <div className=" hidden lg:block rounded-[10px] absolute inset-0
                    "></div> */}
                    {
                        srcImage && srcImage[0].includes('https://youtu.be') ? (
                            <img fetchpriority="high" src={S3_BASE_URL + srcImage[1]} alt="img" className="relative" />
                        ) : (
                            srcImage && /\.(gif|jpg|jpeg|png)$/.test(srcImage[0]) ? (
                                <img fetchpriority="high" src={S3_BASE_URL + srcImage[0]} alt="img" className="relative" />
                            ) : (
                                <div className="flex w-full rounded-[10px] h-full bg-slate-100 skeleton-animation"></div>
                            )
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default WebinarPlayCard;