import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IoMdArrowDropup } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { IoTime } from "react-icons/io5";
import { FaPlay } from "react-icons/fa";
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import Select from 'react-select';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { IoArrowBackCircleOutline } from "react-icons/io5";


import playIcon from "../../../assets/backgrounds/team logo.png"

import { Chart, BarElement, LineElement, PointElement, LineController, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { display, font, height, size, tick, width } from "synonyms/dictionary";
import { Calendar, DateRangePicker } from "react-date-range";
import DatePicker from "react-datepicker";
import DateRangePickerTwo from "./datePickerRange";

// Registering Chart.js components
Chart.register(LineElement, BarElement, PointElement, LineController, CategoryScale, LinearScale, Title, Tooltip, Legend);

const ReportsGrahpsGroupsTwo = ({ subObjData, subObjLables, subObjColorCode, subCategoryLables, subCategoryData, subCategoryColorcode, topPlayers, allExperiences, categoryGamesCount, categoryGamesLables, catgeoryColorCodes, objectiveGamesCount, avgUserPlayTimeLables, avgUserPlayTimeData, totalGamesPlayedData, totalGamesPlayedLables }) => {

    const [objCatValue, setObjCatValue] = useState('All');

    const [allMainTitleExperiencesCount, setAllMainTitleExperiencesCount] = useState(0);

    const [allMainTitleSubExperiencesLables, setAllMainTitleSubExperiencesLables] = useState([]);
    const [allMainTitleSubExperiencesCounts, setAllMainTitleSubExperiencesCounts] = useState([]);

    const [allMainTitlesSubExperiencesColors, setSllMainTitlesSubExperiencesColors] = useState({})



    useEffect(() => {
        const callBack = async () => {
            if (objCatValue !== "All" && allExperiences && allExperiences.length > 0) {
                let filterExperiences = getDataByMainTitle(allExperiences, objCatValue)
                const keys = Object.keys(filterExperiences.gameCountMap);
                const values = Object.values(filterExperiences.gameCountMap);

                setAllMainTitleSubExperiencesCounts(values)
                setAllMainTitleSubExperiencesLables(keys)

                setSllMainTitlesSubExperiencesColors(filterExperiences.subTitleColorMap)
            }
        }
        if (objCatValue !== "All" && allExperiences && allExperiences.length > 0) {
            callBack()

        }

    }, [objCatValue])

    useEffect(() => {
        if (categoryGamesCount && objCatValue === "All") {
            let count = 0;
            categoryGamesCount.map((data) => {
                count += data
            })
            setAllMainTitleExperiencesCount(count);

        }
        else if (allMainTitleSubExperiencesCounts && allMainTitleSubExperiencesCounts.length > 0 && objCatValue !== "All") {
            let count = 0;
            allMainTitleSubExperiencesCounts.map((data) => {
                count += data
            })
            setAllMainTitleExperiencesCount(count);

        }
    }, [categoryGamesCount, objCatValue, allMainTitleSubExperiencesCounts])

    function convertArrayToObject(arr) {
        return arr.reduce((acc, item) => {
            const key = Object.keys(item)[0];
            const value = item[key];
            acc[key] = value;
            return acc;
        }, {});
    }

    const calculateBarWidth = (currentValue, maxValue) => {
        if (maxValue === 0) return 0; // Prevent division by zero

        return (currentValue / maxValue) * 100; // Return the percentage width
    }

    const colorCatMap = Array.isArray(subCategoryColorcode) ? subCategoryColorcode.reduce((acc, item) => {
        const [key, color] = Object.entries(item)[0];
        acc[key] = color;
        return acc;
    }, {}) : {};

    const getMainTitleExperincesColor = (data) => {
        return data.map(label => catgeoryColorCodes[label] || 'rgba(0,0,0,0.1)'); // Default color if not found
    }
    const getTitleExperincesColor = (data) => {
        return data.map(label => allMainTitlesSubExperiencesColors[label] || 'rgba(0,0,0,0.1)'); // Default color if not found
    }
    const getBackgroundColorsCat = (labels) => {
        return labels.map(label => colorCatMap[label] || 'rgba(0,0,0,0.1)'); // Default color if not found
    };

    const colorObjMap = Array.isArray(subCategoryColorcode) ? subObjColorCode.reduce((acc, item) => {
        const [key, color] = Object.entries(item)[0];
        acc[key] = color;
        return acc;
    }, {}) : {};

    const getBackgroundColorsObj = (labels) => {
        return labels.map(label => colorObjMap[label] || 'rgba(0,0,0,0.1)'); // Default color if not found
    };

    function getDataByMainTitle(data, mainTitle) {
        // return data
        //     .filter((item) => item.mainTitle === mainTitle) // Filter by mainTitle
        //     .reduce((result, item) => {
        //         result[item.title] = item.gameCount; // Add title as key and gameCount as value
        //         return result;
        //     }, {});

        // Filter the data by mainTitle
        let filterTitle = mainTitle === "DEIB" ? "DEIB - Diversity, Inclusion, Equity and Belonging" : mainTitle
        const filteredData = data.filter((item) => item.mainTitle === filterTitle);

        // Reduce to create two separate objects
        const result = filteredData.reduce(
            (acc, item) => {
                acc.gameCountMap[item.title] = item.gameCount; // Add title as key and gameCount as value
                acc.subTitleColorMap[item.title]  = item.subTitleColorCode; // Add title as key and subTitleColorCode as value
                return acc;
            },
            { gameCountMap: {}, subTitleColorMap: {} } // Initial object structure
        );

        return result;
    }
    const dougnutdData = {
        labels: objCatValue && objCatValue === "All" ? categoryGamesLables : objCatValue && objCatValue !== "All" ? allMainTitleSubExperiencesLables : [],
        datasets: [{
            label: 'My First Dataset',
            data: objCatValue && objCatValue === "All" ? [...categoryGamesCount] : objCatValue && objCatValue !== "All" ? allMainTitleSubExperiencesCounts : [],
            backgroundColor: objCatValue && objCatValue !== "All" ? getTitleExperincesColor(allMainTitleSubExperiencesLables) : categoryGamesLables && categoryGamesLables.length > 0 ? getMainTitleExperincesColor(categoryGamesLables) : [],

            hoverBorderWidth: 1,
            hoverOffset: 4
        }]
    };

    const dougnutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: true,
            }
        }
    };


    const barData = {
        labels: avgUserPlayTimeLables,
        datasets: [{
            label: 'Avg time played (mins)',
            data: avgUserPlayTimeData,
            backgroundColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                    return null;
                }
                const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                gradient.addColorStop(0.0895, '#9DC9F5');  // Start color
                gradient.addColorStop(0.9728, '#4587DC');     // Adjust to match Figma design

                return gradient;
            },
            color: '#8E8E8E',
            borderWidth: 0.5,
            borderRadius: 5,
            barThickness: avgUserPlayTimeLables.length === 2 ? 120 : avgUserPlayTimeLables.length <= 7 ? 40 : avgUserPlayTimeLables.length > 7 && avgUserPlayTimeLables.length <= 30 ? 20 : 15
        }]
    };


    const barOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                max: avgUserPlayTimeData.reduce((max, item) => {
                    return item > max ? Math.ceil(item) : max;
                }, 0),
                title: {
                    display: true,
                    text: 'Avg time played (mins)',
                    color: '#8E8E8E',
                    font: {
                        // size: 12
                        size: avgUserPlayTimeLables.length === 2 ? 12 : avgUserPlayTimeLables.length <= 7 ? 10 : avgUserPlayTimeLables.length > 7 && avgUserPlayTimeLables.length <= 30 ? 8 : 5
                    }
                },
                ticks: {
                    stepSize: 1,
                    color: '#666',

                },
                grid: {
                    color: 'rgba(200, 200, 200, 0.2)',
                }
            },
            x: {
                ticks: {
                    color: '#666',
                    font: {
                        size: avgUserPlayTimeData.length <= 7 ? 11 : avgUserPlayTimeData.length > 7 && avgUserPlayTimeData.length <= 30 ? 7 : 6
                    },

                },
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.raw} mins`;
                    }
                }
            },
            legend: {
                display: false
            }
        }
    }


    const lineData = {
        labels: totalGamesPlayedLables,
        datasets: [{
            label: 'Games Played',
            data: totalGamesPlayedData,
            fill: false,
            borderColor: '#A875E7', // Updated line color
            backgroundColor: '#A875E7',
            pointBackgroundColor: '#A875E7', // Keeps the point background color
            pointBorderColor: '#A875E7', // Keeps the point border color
            pointRadius: 0, // Larger point radius for easier hover
            pointHoverRadius: 3, // Larger hover radius for better UX
            pointHoverBorderWidth: 2,
            tension: 0.1
        }]
    };

    const lineOptions = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false // Hides the vertical grid lines
                },
                ticks: {
                    font: {
                        size: totalGamesPlayedLables.length <= 7 ? 10 : totalGamesPlayedLables.length > 7 && totalGamesPlayedLables.length <= 30 ? 7 : 6
                    }
                }

            },
            y: {
                grid: {
                    borderDash: [5, 5] // Makes the horizontal grid lines dotted
                },
                title: {
                    display: true,
                    text: 'No of Games',
                    color: '#8E8E8E',
                    font: {
                        size: 12
                    }
                },
                ticks: {
                    font: {
                        size: totalGamesPlayedLables.length <= 7 ? 12 : totalGamesPlayedLables.length > 7 && totalGamesPlayedLables.length <= 30 ? 11 : 10
                    },
                    stepSize: 1
                }
            }
        },
        elements: {
            point: {
                radius: 0, // Initially hides the points
                hitRadius: 10, // Increases the hit area to make hover easier
                hoverRadius: 8,
            }
        },
        plugins: {
            legend: {
                display: true,
            }
        },
        tooltip: {
            enabled: true, // Ensures tooltips are enabled
            callbacks: {
                // Customize the tooltip to display the label and value
                label: function (tooltipItem) {
                    return `Count: ${tooltipItem.raw}`;
                }
            }
        }
    };



    return (
        <div className="overflow-wrapper mt-5 mb-5">
            {/* avgerage card */}
            <div className="flex flex-col lg:flex-row w-full justify-between">

                <div className="flex flex-col items-start p-[20px] rounded-[8px] bg-white h-[388px] mb-5 lg:mb-0 w-[100%] lg:w-[49.3%]">
                    <div className="flex items-center justify-between w-full mb-5">
                        {/* <h2 className="text-[20px] font-sans font-semibold">Top 5 Players</h2> */}
                        <div className="flex w-full" style={{ borderBottom: '1px solid #EDEDED' }}>
                            <h2 className="text-[16px] lg:text-[20px] font-sans font-semibold mb-5">Top 5 Players</h2>
                        </div>



                    </div>

                    <div className="flex w-full flex-col items-start">
                        {
                            topPlayers && topPlayers.length > 0 && topPlayers[0]?.userName && (
                                <div className="flex flex-row w-full h-[38px] justify-between items-center">
                                    <div className="flex items-center justify-center w-1/12 h-full">
                                        <div className="flex items-center justify-center w-[38px] h-[38px] rounded-full ">
                                            <img src={playIcon} alt="playet-team-logo" />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-9/12">
                                        <span className="text-[14px] font-sans">{topPlayers[0]?.userName}</span>
                                        <div className="flex items-center h-[10px] min-w-max bg-[#CECECE26] rounded-[4px]">
                                            <div className={`flex h-full rounded-[4px]`}
                                                style={{
                                                    background: 'linear-gradient(91.95deg, rgba(157, 201, 245, 0.5) 1.65%, rgba(69, 135, 220, 0.5) 98.35%)',
                                                    width: `${calculateBarWidth(topPlayers[0]?.totalPoints, topPlayers[0]?.totalPoints)}%`
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="flex items-end w-1/12 h-full">
                                        <span className="text-[14px] font-sans">{topPlayers[0]?.totalPoints}</span>
                                    </div>
                                </div>
                            )
                        }

                        {
                            topPlayers && topPlayers.length > 0 && topPlayers[1]?.userName && (
                                <div className="flex flex-row w-full h-[38px] justify-between items-center mt-5">
                                    <div className="flex items-center justify-center w-1/12 h-full">
                                        <div className="flex items-center justify-center w-[38px] h-[38px] rounded-full ">
                                            <img src={playIcon} alt="playet-team-logo" />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-9/12">
                                        <span className="text-[14px] font-sans">{topPlayers[1]?.userName}</span>
                                        <div className="flex items-center h-[10px] min-w-max bg-[#CECECE26] rounded-[4px] ">
                                            <div className={`flex h-full rounded-[4px]`}
                                                style={{
                                                    background: 'linear-gradient(91.95deg, rgba(157, 201, 245, 0.5) 1.65%, rgba(69, 135, 220, 0.5) 98.35%)',
                                                    width: `${calculateBarWidth(topPlayers[1]?.totalPoints, topPlayers[0]?.totalPoints)}%`
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="flex items-end w-1/12 h-full">
                                        <span className="text-[14px] font-sans">{topPlayers[1]?.totalPoints}</span>
                                    </div>
                                </div>
                            )
                        }

                        {
                            topPlayers && topPlayers.length > 0 && topPlayers[2]?.userName && (
                                <div className="flex flex-row w-full h-[38px] justify-between items-center mt-5">
                                    <div className="flex items-center justify-center w-1/12 h-full">
                                        <div className="flex items-center justify-center w-[38px] h-[38px] rounded-full ">
                                            <img src={playIcon} alt="playet-team-logo" />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-9/12">
                                        <span className="text-[14px] font-sans">{topPlayers[2]?.userName}</span>
                                        <div className="flex items-center h-[10px] min-w-max bg-[#CECECE26] rounded-[4px]">
                                            <div className={`flex h-full rounded-[4px]`}
                                                style={{
                                                    background: 'linear-gradient(91.95deg, rgba(157, 201, 245, 0.5) 1.65%, rgba(69, 135, 220, 0.5) 98.35%)',
                                                    width: `${calculateBarWidth(topPlayers[2]?.totalPoints, topPlayers[0]?.totalPoints)}%`
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="flex items-end w-1/12 h-full">
                                        <span className="text-[14px] font-sans">{topPlayers[2]?.totalPoints}</span>
                                    </div>
                                </div>
                            )
                        }


                        {
                            topPlayers && topPlayers.length > 0 && topPlayers[3]?.userName && (
                                <div className="flex flex-row w-full h-[38px] justify-between items-center mt-5">
                                    <div className="flex items-center justify-center w-1/12 h-full">
                                        <div className="flex items-center justify-center w-[38px] h-[38px] rounded-full ">
                                            <img src={playIcon} alt="playet-team-logo" />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-9/12">
                                        <span className="text-[14px] font-sans">{topPlayers[3]?.userName}</span>
                                        <div className="flex items-center h-[10px] min-w-max bg-[#CECECE26] rounded-[4px]">
                                            <div className={`flex h-full rounded-[4px]`}
                                                style={{
                                                    background: 'linear-gradient(91.95deg, rgba(157, 201, 245, 0.5) 1.65%, rgba(69, 135, 220, 0.5) 98.35%)',
                                                    width: `${calculateBarWidth(topPlayers[3]?.totalPoints, topPlayers[0]?.totalPoints)}%`
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="flex items-end w-1/12 h-full">
                                        <span className="text-[14px] font-sans">{topPlayers[3]?.totalPoints}</span>
                                    </div>
                                </div>
                            )
                        }

                        {
                            topPlayers && topPlayers.length > 0 && topPlayers[4]?.userName && (
                                <div className="flex flex-row w-full h-[38px] justify-between items-center mt-5">
                                    <div className="flex items-center justify-center w-1/12 h-full">
                                        <div className="flex items-center justify-center w-[38px] h-[38px] rounded-full ">
                                            <img src={playIcon} alt="playet-team-logo" />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-9/12">
                                        <span className="text-[14px] font-sans">{topPlayers[4]?.userName}</span>
                                        <div className="flex items-center h-[10px] min-w-max bg-[#CECECE26] rounded-[4px]">
                                            <div className={`flex h-full rounded-[4px]`}
                                                style={{
                                                    background: 'linear-gradient(91.95deg, rgba(157, 201, 245, 0.5) 1.65%, rgba(69, 135, 220, 0.5) 98.35%)',
                                                    width: `${calculateBarWidth(topPlayers[4]?.totalPoints, topPlayers[0]?.totalPoints)}%`
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                    <div className="flex items-end w-1/12 h-full">
                                        <span className="text-[14px] font-sans">{topPlayers[4]?.totalPoints}</span>
                                    </div>
                                </div>
                            )
                        }


                    </div>
                </div>

                {/* <div className="flex flex-col items-start p-[20px] rounded-[8px] bg-white h-[388px] w-[49.3%]">
                <div style={{ position: 'relative', width: '100%' }}>
                <Doughnut data={data} style={{height:'200px', width:'auto'}}/>
                </div>
                </div> */}

                <div className="flex flex-col items-start p-[20px] rounded-[8px] bg-white h-auto lg:h-[388px] w-[100%] lg:w-[49.3%]">
                    <div className="flex w-full" style={{ borderBottom: '1px solid #EDEDED' }}>
                        {
                            objCatValue && objCatValue !== "All" ? (
                                <div className=" flex items-center justify-center w-[24px] h-[24px] mr-3 cursor-pointer">
                                    <IoArrowBackCircleOutline style={{ width: '24px', height: '24px' }} onClick={(e) => { setObjCatValue('All') }} />

                                </div>
                            )
                                : null
                        }

                        <h2 className="text-[16px] lg:text-[20px] font-sans font-semibold mb-5">{objCatValue === "All" ? "Experiences" :objCatValue}</h2>
                    </div>
                    <div className="flex w-full items-center flex-row mt-3">
                        <div className=" relative flex w-[160px] h-[140px] lg:w-[270px] lg:h-[240px]">
                            <Doughnut data={dougnutdData} options={dougnutOptions} />
                        </div>
                        <div className="flex  flex-col items-start md:ml-[20%] lg:ml-10">
                            <h3 className="text-[18px] font-sans font-semibold">Total games</h3>
                            <span className=" text-[25px] lg:text-[37px] font-sans font-semibold">{allMainTitleExperiencesCount ? allMainTitleExperiencesCount : 0}</span>
                            <div className="flex flex-col mt-3">
                                {
                                    objCatValue && objCatValue === "All" && categoryGamesLables && categoryGamesLables.length > 0 ? categoryGamesLables.map((data, index) => (
                                        <div className="flex flex-row mb-1"
                                            onClick={(e) => setObjCatValue(data)}
                                            key={index}
                                        >
                                            <div className={`w-[20px] h-[20px] mr-2`}
                                                style={{
                                                    background: catgeoryColorCodes[data]
                                                }}
                                            ></div>
                                            <span className="text-[10px] font-semibold font-sans cursor-pointer">{data}</span>
                                        </div>
                                    )
                                    )

                                        : objCatValue && objCatValue !== "All" && allMainTitlesSubExperiencesColors && allMainTitleSubExperiencesLables && allMainTitleSubExperiencesLables.length > 0 ?
                                            allMainTitleSubExperiencesLables.map((data, index) => (
                                                <div className="flex flex-row mb-1" key={index}>
                                                    <div className={`w-[20px] h-[20px] mr-2`}
                                                        style={{
                                                            background: allMainTitlesSubExperiencesColors[data]
                                                        }}
                                                    ></div>
                                                    <span className="text-[10px] font-semibold font-sans cursor-pointer">{data}</span>
                                                </div>
                                            )
                                            )
                                            : null
                                }

                            </div>
                        </div>
                    </div>

                </div>

            </div>


            <div className="flex flex-col lg:flex-row w-full justify-between mt-5">

                <div className="flex flex-col items-start p-[20px] rounded-[8px] bg-white h-[360px] mb-5 lg:mb-0 w-[100%] lg:w-[49.3%]">
                    <div className="flex w-full" style={{ borderBottom: '1px solid #EDEDED' }}>
                        <h2 className="text-[20px] font-sans font-semibold mb-5">Average Time Play</h2>
                    </div>
                    <div className="relative flex w-full flex-col items-center  h-full lg:h-[-webkit-fill-available] ">
                        <Bar data={barData} options={barOptions} />
                    </div>
                </div>


                <div className="flex flex-col items-start p-[20px] rounded-[8px] bg-white h-[360px] lg:mb-0 w-[100%] lg:w-[49.3%]">
                    <div className="flex w-full" style={{ borderBottom: '1px solid #EDEDED' }}>
                        <h2 className="text-[20px] font-sans font-semibold mb-5">Games Played</h2>
                    </div>
                    <div className="flex w-full items-center flex-row " style={{ height: '-webkit-fill-available' }}>
                        <div className="relative flex w-full flex-col items-center h-full lg:h-[-webkit-fill-available] " >
                            <Line data={lineData} options={lineOptions} />
                        </div>

                    </div>


                </div>

            </div>


        </div>
    );
};

export default ReportsGrahpsGroupsTwo;
