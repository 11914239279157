import React, { useEffect, useState, useRef } from "react";
import PageLayout from '../../components/pagelayout/pagelayout';
import ScheduledGames from '../../components/scheduledGames/scheduledGames';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import MyFavourites from "../../components/myFavourites/myFavourites";
import { sidebarContentScheduledByOthers, sideBarContentMyFavourites, sidebarContentScheduledByMe } from "../../helpers/sidebarContentMyGames";
import { logOut } from "../../redux/actions/userAction";
import Roles from '../../helpers/userTypes';
import Reports from "../reports/reports";
import PollDetailsTable from "../../components/pollDetailsTable/pollDetailsTable";
import DefaultGames from '../../components/defaultgames/DefaultGames';
import Header from "../../components/header/header";
import { axiosApiInstance, BASE_URL, decryptData, encryptData, GetUserType, IsAdmin, REG_EX_URL_FORMAT, successAlert, failureAlert } from "../../helpers/helper";
const AllGamesNonLogin = (props) => {

  const dispatch = useDispatch();
  const isAdmin = IsAdmin();
  const signOut = async () => {
    await dispatch(logOut());
    props.history.push("/");
  };
  const { gameCategory } = useSelector((state) => state.gameAllCategory);
  const { gameObjectives } = useSelector(state => state.getAllObjectives);
  const [allgamesForNonLoginUser, setAllGames] = useState([]);
  useEffect(() => {
    if ((gameCategory?.data && gameCategory.data.length > 0) ||
      (gameObjectives?.data && gameObjectives.data.length > 0)) {
      const arr1 = gameCategory?.data || [];
      const arr2 = gameObjectives?.data || [];
      const allGamesForNonLoginUser = [
        ...arr1,
        ...arr2
      ];
      const result = { data: allGamesForNonLoginUser.reduce((acc, item) => acc.concat(item.games), []) };
       setAllGames(result);
   }
  }, [gameCategory, gameObjectives]);

  return (
    <div >
      <Header profile {...props} signOut={signOut} {...props} isAdmin={isAdmin} />
      <div className=" w-full h-auto md:p-8 p-0 mt-[100px]">
        <DefaultGames {...props} allGamesNonLogin={allgamesForNonLoginUser} />
      </div>
    </div>
  );

};
export default AllGamesNonLogin;
