import React, { useEffect, useState } from "react";
import Playcardlarge from "../../components/playCard/playcardlarge";
import "./scheduledGames.css";
//images
import search from "../../assets/images/search.svg";
import arrowleft from "../../assets/images/paginationarrow.svg";
import SideBarMob from "../sidebar/sideBarMob";
import LikeShareModal from "../../components/modal/likesharemodal";
import { gamesScheduledByOthers, gamesScheduledByMe } from "../../redux/actions/gameDetailAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import PlayCardMobileHoverCard from "../../components/playCard/playCardMobileHoverCard";

import useConstant from "use-constant"
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsync } from 'react-async-hook';
import LoadingComponent from "../loader/LoadingComponent";

const sideBarMobContents = [
  { title: "Scheduled By Others", redirectLink: "/my-games/scheduled-by-others" },
  { title: "Scheduled By Me", redirectLink: "/my-games/scheduled-by-me" }
];

const useDebouncedSearch = (searchFunction) => {
  const [searchText, setSearchText] = useState('');
  const [gameSessionType, setGameSessionType] = useState("");
  const debouncedSearchFunction = useConstant(() =>
    AwesomeDebouncePromise(searchFunction, 500)
  );
  const results = useAsync(
    async () => {
      return debouncedSearchFunction(searchText, gameSessionType);
    },
    [debouncedSearchFunction, searchText]
  );
  return {
    searchText,
    setSearchText,
    results,
    setGameSessionType
  };
};

const ScheduledGames = (props) => {
  const [sessionType, setSessionType] = useState("");
  const useSearch = () => useDebouncedSearch(searchGames);
  const searchGames = (text, gameSessionType) => {
    if (gameSessionType === "user") {
      dispatch(gamesScheduledByMe(text));
    } else if (gameSessionType === "others") {
      dispatch(gamesScheduledByOthers(text));
    }
  }
  const { searchText, setSearchText, results, setGameSessionType } = useSearch();

  const dispatch = useDispatch();
  const history = useHistory();
  const GameSessionsByMe = useSelector((state) => state.gamesScheduledByMe);
  const { gameSessionsByMe } = GameSessionsByMe;
  const GameSessionsByOthers = useSelector((state) => state.gamesScheduledByOthers);
  const [page, setPage] = useState(1);
  const { gameSessionsByOthers } = GameSessionsByOthers;
  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [openMobileHoverCard, setOpenMobileHoverCard] = useState(false);
  const [gameDetails, setGameDetails] = useState({});

  useEffect(() => {
    if(props.gameSessionType === "user"){
      setMyGameSessions([])
      // dispatch(gamesScheduledByOthers(searchText, page));
      dispatch(gamesScheduledByMe(searchText, page));
    }
    else{
      setMyGameSessions([])
      dispatch(gamesScheduledByOthers(searchText, page));
      // dispatch(gamesScheduledByMe(searchText, page));
    }
   
  }, [page, props.gameSessionType]);
  useEffect(() => {

    // setPage(1);
    if (props.gameSessionType) {
      console.log("true props");
      
      setSessionType(props.gameSessionType);
      setGameSessionType(props.gameSessionType);
    }

  }, [ props.gameSessionType]);
  useEffect(() => {
    if (
      (GameSessionsByMe && GameSessionsByMe.loading) ||
      (GameSessionsByOthers && GameSessionsByOthers.loading) ||
      (results && results.loading)
    ) {
      setLoaded(false);
    }
    else
      setLoaded(true);
  }, [GameSessionsByMe, GameSessionsByOthers, results]);

  const handleClick = () => {
    history.push("/");
  };
  const handleChange = (e) => {
    setSearchText(e.target.value);
    // if (e.target.value.length === 0) {
    //   if (sessionType === "user") {
    //     dispatch(gamesScheduledByMe(""));
    //   } else if (sessionType === "others") {
    //     dispatch(gamesScheduledByOthers(""));
    //   }
    // }
    setPage(1)
  };
  // useEffect(() => {
  //   if (history.location && history.location.search) {
  //     const page = new URLSearchParams(history.location.search).get('page');
  //     if (page > 1)
  //       setPage(parseInt(page));
  //   }
  // }, [history.location]);

  const changePage = (newPage) => {
    setLoaded(false);
    setPage(newPage)
    history.push('/my-games/' + (sessionType === "user" ? "scheduled-by-me" : "scheduled-by-thers") + "?page=" + newPage);
    setTimeout(() => {
      setLoaded(true)
    }, 100);
  }

  //Pagination
  var dataLength;
  if (sessionType === "user") {
    dataLength = gameSessionsByMe?.paginationData?.totalEntries;
  } else {
    dataLength = gameSessionsByOthers?.paginationData?.totalEntries;
  }
  const [myGamesSessions, setMyGameSessions] = useState([])
  useEffect(() => {
    if (sessionType === "user" && gameSessionsByMe && gameSessionsByMe?.data && gameSessionsByMe?.data.length > 0) {
      setMyGameSessions(gameSessionsByMe.data)

    }
    if (sessionType === "others" && gameSessionsByOthers && gameSessionsByOthers?.data && gameSessionsByOthers?.data.length > 0) {
      setMyGameSessions(gameSessionsByOthers.data)

    }
  }, [sessionType, gameSessionsByMe, gameSessionsByOthers])
  return (
    <div>

      <div className="flex justify-between items-center profile-section">
        <h5 className="hide991 profile-name">
          {sessionType === "user" ? "Scheduled By Me" : "Scheduled By Others"}
        </h5>
        <div className="flex items-center input-icon">
          <img src={search} alt="search" className="mr-2" />
          <input
            type="text"
            name="search"
            placeholder="Search a game"
            value={searchText}
            onChange={handleChange}
            className="p-2 border"
          />
        </div>
      </div>

      <SideBarMob active={props.gameSessionType === "user" ? "Scheduled By Me" : "Scheduled By Others"} contents={sideBarMobContents} />
      {openShareModal && (
        <LikeShareModal
          toggle={openShareModal}
          setOpenShareModal={setOpenShareModal}
          shareLink={shareLink}
        />
      )}
      <LoadingComponent loaded={loaded} />
          <div className="flex flex-wrap justify-center md:justify-start lg:justify-start" >

            {
              myGamesSessions && myGamesSessions.length > 0 ?
                myGamesSessions.map((game, index) => {
                  return (game.organizationGame?.game &&
                    <Playcardlarge
                      key={JSON.stringify(game.organizationGame.game)}
                      srcImage={game.organizationGame.game.coverMedia}
                      title={game.organizationGame.game.title}
                      gameDetail={game.organizationGame.game}
                      setOpenShareModal={setOpenShareModal}
                      setShareLink={setShareLink}
                      setGameDetails={setGameDetails}
                      setOpenMobileHoverCard={setOpenMobileHoverCard}
                    />
                    // <div>{game.organizationGame.game.title +" --> "+index}</div>
                  );
                })
                : loaded === false ? (
                  Array.from({ length: 8 }).map((_, indx) => (
                    <div className="z-[100] flex bg-slate-100 mr-[20px] mb-5 rounded-[10px] w-[200px] h-[300px] skeleton-animation">

                    </div>
                  ))
                )
                  : (
                    <div className="no-game-wrapper">
                      <div>
                        <h3 className="no-game">
                          {
                            searchText.length === 0 ?
                              "Currently, there are no live games scheduled by others. Please schedule one or enjoy the default games."
                              : "Currently, there are no live games scheduled by others with this name. Please schedule one or enjoy the default games."
                          }
                        </h3>
                        <span className="no-game-span" onClick={handleClick}>
                          Go to Homepage
                        </span>
                      </div>
                    </div>
                  )
            }
          </div>

      {
        dataLength > 0 && (
          <div className="active-games pagination-wrapper">
            <button
              className={page > 1 ? "pagination-left enable" : "pagination-left"}
              onClick={() => {
                if (page > 1) changePage(page - 1);
                window.scrollTo(0, 0);
              }}
            >
              <img src={arrowleft} alt="arrow left" />
            </button>
            <div className="pagination-number">
              <h5>{page}</h5>
              <span>of {dataLength ? Math.ceil(dataLength / 15) : 1} pages</span>
            </div>
            <button
              className={(page < Math.ceil(dataLength / 15)) ? "pagination-right enable" : "pagination-right"}
              onClick={() => {
                if (page < Math.ceil(dataLength / 15)) changePage(page + 1);
              }}
            >
              <img src={arrowleft} alt="arrow right" />
            </button>
          </div>
        )
      }
      {
        openMobileHoverCard &&
        <PlayCardMobileHoverCard
          setOpenShareModal={setOpenShareModal}
          gameDetail={gameDetails}
          setOpenMobileHoverCard={setOpenMobileHoverCard}
          setShareLink={setShareLink}
        />
      }
    </div>

  )
}

export default ScheduledGames;
