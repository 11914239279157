export const ADD_QUARTER_INFO_REQUEST = "ADD_QUARTER_INFO_REQUEST";
export const ADD_QUARTER_INFO_SUCCESS = "ADD_QUARTER_INFO_SUCCESS";
export const ADD_QUARTER_INFO_FAIL = "ADD_QUARTER_INFO_FAIL";

export const ADD_QUARTER_GAMES_OCCASIONS_REQUEST = "ADD_QUARTER_GAMES_OCCASIONS_REQUEST";
export const ADD_QUARTER_GAMES_OCCASIONS_SUCCESS = "ADD_QUARTER_GAMES_OCCASIONS_SUCCESS";
export const ADD_QUARTER_GAMES_OCCASIONS_FAIL = "ADD_QUARTER_GAMES_OCCASIONS_FAIL";

export const GET_QUARTER_INFO_REQUEST = "GET_QUARTER_INFO_REQUEST";
export const GET_QUARTER_INFO_SUCCESS = "GET_QUARTER_INFO_SUCCESS";
export const GET_QUARTER_INFO_FAIL = "GET_QUARTER_INFO_FAIL";

export const UPDATE_QUARTER_INFO_REQUEST="UPDATE_QUARTER_INFO_REQUEST";
export const UPDATE_QUARTER_INFO_SUCCESS="UPDATE_QUARTER_INFO_SUCCESS";
export const UPDATE_QUARTER_INFO_FAIL="UPDATE_QUARTER_INFO_FAIL";

export const GET_ALL_CALENDAR_DATA_REQUEST = "GET_ALL_CALENDAR_DATA_REQUEST";
export const GET_ALL_CALENDAR_DATA_SUCCESS = "GET_ALL_CALENDAR_DATA_SUCCESS";
export const GET_ALL_CALENDAR_DATA_FAIL = "GET_ALL_CALENDAR_DATA_FAIL";


export const UPDATE_OCCASION_GAMES_REQUEST="UPDATE_OCCASION_GAMES_REQUEST";
export const UPDATE_OCCASION_GAMES_SUCCESS="UPDATE_OCCASION_GAMES_SUCCESS";
export const UPDATE_OCCASION_GAMES_FAIL="UPDATE_OCCASION_GAMES_FAIL";

export const GET_OCCASION_GAMES_REQUEST = "GET_OCCASION_GAMES_REQUEST";
export const GET_OCCASION_GAMES_SUCCESS = "GET_OCCASION_GAMES_SUCCESS";
export const GET_OCCASION_GAMES_FAIL = "GET_OCCASION_GAMES_FAIL";