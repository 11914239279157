import React, { useState, useRef } from "react";
import "./index.css";

import { useDispatch } from "react-redux";
import PageLayout from "../../components/pagelayout/pagelayout";
import { failureAlert, IsAdmin, signOut, successAlert } from "../../helpers/helper";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import Select from "react-select";
import { useEffect } from "react";
import { addCategory, deleteCategory, gameAllCategory, updateCategory, deleteMultipleCategories } from "../../redux/actions/gameDetailAction";
import { getAllGames } from "../../redux/actions/homepageActions";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { createFilter } from 'react-select';
import DataTable from "react-data-table-component";
import { uploadFile, getAllWebinars, getAllWebinarCategories } from '../../redux/actions/commonActions';

import {
    S3_BASE_URL,
    BASE_URL,
    GOOGLE_RECAPTCHA_SITE_KEY,
    axiosApiInstance,
    encryptData,
    decryptData
} from "../../helpers/helper";

const AddEditCategory = (props) => {
 
      
    const [activeTab, setActiveTab] = useState("experience");
   
    const handleTabClick = async (tab) => {
        if (tab == 'home') {
            try {
                setLoaded(false);
                const result = await axiosApiInstance.get(
                    BASE_URL + "/api/category/getAllExperiences"
                );
               

                if(result?.data?.data.length>0){
                    const experienceData= result?.data?.data;
                    const selectedOptions = experienceData.map(item => ({
                        value: item.experienceId,
                        label: item.experienceName
                      }));

                      
                      setSelectedExperiences(selectedOptions);
                }
                setLoaded(true);
            } catch (error) {
                console.error('Error fetching games:', error);
                setLoaded(true);
                throw error;
            }
        }
        setActiveTab(tab);
    };
    const svgfileInputRef = useRef(null);
    const svgfileInputRefImage = useRef(null);
    const isAdmin = IsAdmin();
    const dispatch = useDispatch();
    const customStyles = {
        headCells: {
            style: {
                fontFamily: "firaSans-semibold",
                fontSize: "15px",
                borderBottom: "1px solid Black",
                borderTop: "1px solid Black",
            }
        },
        cells: {
            style: {
                fontFamily: "firaSans-regular",
                fontSize: "14px",
                textAlign: "center",
            }
        },
        header: {
            style: {
                fontFamily: "firaSans-semibold",
                color: "#737373",
            }
        }
    };
    const [loaded, setLoaded] = useState(true);
    const [svgPreview, setSvgPreview] = useState(null);
    const [thumbnailFilePreview, setThumbnailFilePreview] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [editInfo, setEditInfo] = useState([]);
    const [gameOptions, setGameOptions] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [experienceType, setExperienceType] = useState('header');
    const [experienceTypeOnedit, setExperienceTypeOnEdit] = useState('');
    const [selectedEditGames, setSelectedEditGames] = useState([]);
    const [EditGames, setEditGames] = useState([]);
    const [selectedAddGames, setSelectedAddGames] = useState([]);

    const [titleTouched, setTitleTouched] = useState(false);
    const [addDetails, setAddDetails] = useState({ title: "", description: "",mainTitleColorCode:"#000000",subTitleColorCode:"#000000" });
    const [editDescription, setEditDescription] = useState("");

    const AllGames = useSelector(state => state.allGames);
    const { allGames } = AllGames;
    const GameCategory = useSelector((state) => state.gameAllCategory);
    const { gameCategory } = GameCategory;
    const AddedCategory = useSelector(state => state.addedCategory);
    const UpdatedCategory = useSelector(state => state.updatedCategory);
    const DeletedCategory = useSelector(state => state.deletedCategory);

    const [mainTitleOptions, setMainTitles] = useState([]);
    const [homePageExperiences, setHomePageExperiences] = useState([]);
    const [categoriesToDisplay, setCategoriesToDisplay] = useState([]);
    const [svgFileError, setSvgFileError] = useState('');
    const [thumbnailError, setThumbnailError] = useState('');
    const [mainTitleError, setMainTitleError] = useState('');
    const AllWebinars = useSelector(state => state.allWebinars);
    const { allWebinars } = AllWebinars;
    useEffect(() => {
        dispatch(gameAllCategory());
        dispatch(getAllGames());
        dispatch(getAllWebinars(true));
    }, [])



    useEffect(() => {
        if (gameCategory && gameCategory.data) {
            const options = gameCategory.data.map(category => (
                { id: category.id, label: category.title, value: category.title, games: category.games, description: category.description }
            ))
            setCategoryOptions(options);
        }
    }, [gameCategory]);

    useEffect(() => {
        if (allGames?.data) {

            const options = [
                ...allGames.data.map(game => ({
                    id: game.id,
                    label: game.title,
                    value: game.title

                })),

            ];
            setGameOptions(options);
        }
    }, [allGames, allWebinars]);

    useEffect(() => {
        if (selectedCategory && selectedCategory.games) {
            const options = selectedCategory.games.map(game => (
                { id: game.id, label: game.title, value: game.title }
            ))
            setSelectedEditGames(options);
            setEditDescription(selectedCategory.description);
        }
    }, [selectedCategory])

    useEffect(() => {
        if (
            (AllGames && AllGames.loading) ||
            (GameCategory && GameCategory.loading) ||
            (AddedCategory && AddedCategory.loading) ||
            (UpdatedCategory && UpdatedCategory.loading) ||
            (DeletedCategory && DeletedCategory.loading)
        ) {
            setLoaded(false);
        }
        else
            setLoaded(true);
    }, [AllGames, GameCategory, AddedCategory, UpdatedCategory, DeletedCategory])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    function groupByMainTitle(data) {
        const groupedData = data.reduce((acc, current) => {
            const { mainTitle, title, games, svgPath, id, mainTitleIndex, thumbnail, experienceType,mainTitleColorCode,subTitleColorCode } = current;

            if (!acc[mainTitle]) {
                acc[mainTitle] = {
                    experienceType,
                    mainTitle: mainTitle,
                    mainTitleColorCode:mainTitleColorCode,
                    subTitleColorCode:[],
                    mainTitleIndex: mainTitleIndex,
                    games: [],
                    titles: [],
                    path: [],
                    thumbnailPath: [],
                    id: []
                };
            }

            acc[mainTitle].games = [...acc[mainTitle].games, ...games];

            if (!acc[mainTitle].titles.includes(title)) {
                acc[mainTitle].titles.push(title);
            }
            //   if (!acc[mainTitle].subTitleColorCode.includes(subTitleColorCode)) {
                acc[mainTitle].subTitleColorCode.push(subTitleColorCode);
           // }
            acc[mainTitle].path.push(svgPath);
            acc[mainTitle].thumbnailPath.push(thumbnail);
            if (!acc[mainTitle].id.includes(id)) {
                acc[mainTitle].id.push(id);
            }
            return acc;
        }, {});
        return Object.values(groupedData);
    }

    const uploadImage = async (file) => {
        const data = new FormData();
        data.append("banner-images", file);

        try {
            const response = await dispatch(uploadFile(data));
            return response.data.data.path;
        } catch (error) {
            console.error('Upload failed:', error);
            return null;
        }
    };
    useEffect(() => {

        if (gameCategory && gameCategory.data.length > 0) {
          
            setEditGames(gameCategory.data);
            const uniqueMaintitles = [
                ...new Map(
                    gameCategory.data
                        .map(item => item.mainTitle && { mainTitle: item.mainTitle, svgPath: item.svgPath, mainTitleIndex: item.mainTitleIndex, thumbnail: item.thumbnail, experienceType: item.experienceType })
                        .filter(Boolean)
                        .map(item => [item.mainTitle, item])
                ).values()
            ];
            const gameOptions = Array.from(
                new Set(gameCategory.data.map((game) => game.title)) 
            ).map((title) => {
              
                const game = gameCategory.data.find((game) => game.title === title);
                return {
                    value: game.id, 
                    label: title,   
                };
            });


          
            setMainTitles(uniqueMaintitles);
            setHomePageExperiences(gameOptions);
            const groupedArray = groupByMainTitle(gameCategory.data);
            groupedArray.sort((a, b) => a.mainTitleIndex - b.mainTitleIndex);
            
            setCategoriesToDisplay(groupedArray);
        }
        else {
            setCategoriesToDisplay([]);
            setMainTitles([]);
        }
    }, [gameCategory])

    const resetForm = () => {
        setAddDetails({ title: "", description: "",mainTitleColorCode:"#000000",subTitleColorCode:"#000000" });
        setSelectedAddGames([]);
        setInputValue('');
        setIsDropdownOpen(false);
        setSelectedFile(null);
        setThumbnailFile(null);
        setFilePreview('');
        setThumbnailPreview('');
        setShowFileInput(false);
        if (svgfileInputRef.current) {
            svgfileInputRef.current.value = '';
        }
        if (svgfileInputRefImage.current) {
            svgfileInputRefImage.current.value = '';
        }
    };

    const getMainTitleIndex = (array, mainTitle) => {
        if (array.length === 0) return 1;

        const foundItem = array.find(item => item.mainTitle === mainTitle);

        if (foundItem) {
            return foundItem.mainTitleIndex;
        } else {
            const maxIndex = Math.max(...array.map(item => item.mainTitleIndex || 0));
            return maxIndex + 1;
        }
    };
    const handleAddCategory = async (e) => {
        e.preventDefault();

        if (inputValue === '') {
            setMainTitleError('Please fill out this field');
            return;
        }
        if (!titleTouched)
            setTitleTouched(true)
        if (inputValue !== '') {

            if (showFileInput && !selectedFile) {
                setSvgFileError('Please upload a file');
                return;
            }
            if (showFileInput && !thumbnailFile) {
                setThumbnailError('Please upload a file');
                return;
            }
            if (selectedFile) {
                const r = await uploadImage(selectedFile);
                addDetails.svgPath = r;
            }
            else {
                addDetails.svgPath = svgValue;
            }
            if (thumbnailFile) {
                const r = await uploadImage(thumbnailFile);
                addDetails.thumbnail = r;
            }
            else {
                addDetails.thumbnail = thumbnailValue;
            }
            addDetails.mainTitle = inputValue;
            const index = getMainTitleIndex(mainTitleOptions, addDetails.mainTitle);
            addDetails.mainTitleIndex = index;
            addDetails.experienceType = experienceType;
            const response = await dispatch(addCategory({ category: { ...addDetails, selectedAddGames } }));

           if (response === 200) {
                successAlert("Category Added Successfully!");
                dispatch(gameAllCategory());
                resetForm();
            }
            else
                failureAlert("Something went wrong!");
        }
    }


    const validate = () => {
        const error = { title: "" };
        if (titleTouched && addDetails && addDetails.title && addDetails.title.length <= 0) {
            error.title = "Please enter valid title";
        }
        return error;
    }
    const errors = validate();

    const options = [
        'Ice Breakers',
        'Meeting Energizers',
        'Mystery Games',
        'Festivals',
        'Birthdays & Anniversaries',
        'International Days',
    ];
    const [inputValue, setInputValue] = useState('');
    const [svgValue, setSVGValue] = useState(null);
    const [thumbnailValue, setThumbnailValue] = useState(null);
    const [suggestions, setSuggestions] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const inputRef = useRef(null);
    const [showFileInput, setShowFileInput] = useState(false);
    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        if (value) {
            setSuggestions(value);
            if (categoriesToDisplay.length > 0) {
                const exists = categoriesToDisplay.some(item => item.mainTitle === value);

                if (exists) {
                    setFilePreview('');
                    setSelectedFile(null);
                    document.getElementById('fileInput').value = '';
                    setShowFileInput(false);
                }
                setShowFileInput(!exists);
            }
            else {
                setShowFileInput(true);
            }

        }
    };

    const handleSuggestionClick = (suggestion) => {
        setInputValue(suggestion.mainTitle);
        setSVGValue(suggestion.svgPath);
        setThumbnailValue(suggestion.thumbnail);
        setSuggestions(options);
        setIsDropdownOpen(false);
        setFilePreview('');
        setSelectedFile(null);
        setShowFileInput(false);
    };

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const [editCategory, setEditCategory] = useState(false);


    const handleEdit = (row) => {

        setExperienceTypeOnEdit(row.experienceType);
        setSvgPreview(row.path[0]);
        setThumbnailFilePreview(row.thumbnailPath[0]);
        const filterByTitle = (title) => {
            return EditGames.filter(game => game.mainTitle === title);
        };
        const filteredGames = filterByTitle(row.mainTitle);


        const updatedData = filteredGames.map(item => ({
            ...item,
            games: item.games.map(game => ({
                id: game.id,
                label: game.title,
                value: game.title
            }))
        }));


        setEditInfo(updatedData)
        setEditCategory(true);

    };

    const handleDelete = async (row) => {


        const deleteCategoryProcess = async () => {
            const response = await dispatch(deleteMultipleCategories(row.id));
            if (response === 200) {
                successAlert("Category deleted successfully");
                dispatch(gameAllCategory());
                setSelectedCategory(null);
                setEditDescription("");
                setSelectedEditGames([]);
                setLoaded(true);
            } else {
                failureAlert("Something went wrong!");
                setLoaded(true);
            }
        };


        try {
            if (row.path) {
                setLoaded(false);
                const logo = [row.path[0], row.thumbnailPath[0]];
                await axiosApiInstance.post(`${BASE_URL}/api/file/deleteBanners`, logo);
            }
            await deleteCategoryProcess();
        } catch (error) {
            failureAlert("Failed to delete");
        }
    };




    const [selectedFile, setSelectedFile] = useState(null);
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [filePreview, setFilePreview] = useState('');
    const [thumbnailPreview, setThumbnailPreview] = useState('');

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 20 * 1024 * 1024) {
                setSvgFileError('File size must be less than 20MB');
                return;
            }

            const fileType = file.type;
            const allowedTypes = ['image/jpeg', 'image/png'];

            if (!allowedTypes.includes(fileType)) {
                setSvgFileError('Please select a jpg or png file');
                return;
            }

            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                const fileContent = reader.result;
                const img = new Image();
                img.onload = () => {
                    if (img.width === 35 && img.height === 35) {
                        setFilePreview(fileContent);
                        setSvgFileError('');
                    } else {
                        setSvgFileError('File dimension must be 35 x 35 px');
                    }
                };
                img.onerror = () => {
                    setSvgFileError('Invalid image file');
                };
                img.src = fileContent;
            };
            reader.readAsDataURL(file);
        }
    };

    const handleFileChangeImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 20 * 1024 * 1024) {
                setThumbnailError('File size must be less than 20MB');
                return;
            }

            const fileType = file.type;
            const allowedTypes = ['image/jpeg', 'image/png'];

            if (!allowedTypes.includes(fileType)) {
                setThumbnailError('Please select a jpg or png file');
                return;
            }

            setThumbnailFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                const fileContent = reader.result;
                const img = new Image();
                img.onload = () => {
                    if (img.width === 150 && img.height === 150) {
                        setThumbnailPreview(fileContent);
                        setThumbnailError('');
                    } else {
                        setThumbnailError('File dimension must be 150 x 150 px');
                    }
                };
                img.onerror = () => {
                    setThumbnailError('Invalid image file');
                };
                img.src = fileContent;
            };
            reader.readAsDataURL(file);
        }
    };

    const validateTitles = (data) => {
        let hasErrors = false;

        data.forEach((item) => {
            const mainTitleErrors = [];
            if (!item.mainTitle) {
                mainTitleErrors.push(`Please fill out this field`);
            }
            if (mainTitleErrors.length > 0) {
                item.mainTitleError = mainTitleErrors;
                hasErrors = true;
            } else {
                item.mainTitleError = [];
            }
        });

        return hasErrors;
    };


    const handleMainTitleChange = (newTitle) => {
        const updatedData = [...editInfo];
        updatedData[0].mainTitle = newTitle;
        setEditInfo(updatedData);
    };

    const handleMainTitlecolorcodeChange = (newTitle) => {
        const updatedData = [...editInfo];
        updatedData[0].mainTitleColorCode = newTitle;
        setEditInfo(updatedData);
    };
    const handleDescriptionChange = (description, index) => {
        const updatedEditInfo = [...editInfo];
        updatedEditInfo[index].description = description;
        setEditInfo(updatedEditInfo);
    };

    const handleSubTitleColorCodeChange = (description, index) => {
        const updatedEditInfo = [...editInfo];
        updatedEditInfo[index].subTitleColorCode = description;
        setEditInfo(updatedEditInfo);
    };
    const handleEditFormSubmit = async (e) => {
        e.preventDefault();
        const Error = validateTitles(editInfo);
        const updatedData = [...editInfo];
        setEditInfo(updatedData);

        if (!Error) {

            if (deleteIds.length > 0) {
                await dispatch(deleteMultipleCategories(deleteIds));
            }
            if (editInfo.length > 0) {

                editInfo[0].experienceType = experienceTypeOnedit;

                await axiosApiInstance.put(BASE_URL + "/api/category/new", editInfo)
                    .then(async (response) => {
                        dispatch(gameAllCategory());
                        successAlert("updated successfully");
                        setEditCategory(false);
                    }).catch((error) => {
                        failureAlert("Category Update failed ");
                    });
            }

        }
    };

    const handleChange2 = (selectedOption, index) => {
        const updatedData = [...editInfo];
        updatedData[index].games = selectedOption;
        setEditInfo(updatedData);
    };
    const handleTitleChange = (newTitle, index) => {
        const updatedEditInfo = [...editInfo];
        updatedEditInfo[index].title = newTitle;
        setEditInfo(updatedEditInfo);
    };


    const handleFileChangeNew = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setLoaded(false);
            if (file.size > 20 * 1024 * 1024) {
                failureAlert('File size must be less than 20MB');
                setLoaded(true);
                return;
            }
            const allowedTypes = ['image/jpeg', 'image/png'];
            if (!allowedTypes.includes(file.type)) {
                failureAlert('Please select a jpg or png file');
                setLoaded(true);
                return;
            }
            const img = new Image();
            const reader = new FileReader();
            reader.onloadend = () => {
                img.onload = () => {
                    if (img.width !== 35 || img.height !== 35) {
                        failureAlert('File dimension must be 35 x 35 px');
                        setLoaded(true);
                        return;
                    }
                    if (svgPreview) {
                        const svgIcon = [svgPreview];
                        axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", svgIcon);
                    }
                    uploadImage(file).then((r) => {
                        const updatedEditInfo = [...editInfo];
                        setSvgPreview(r);
                        updatedEditInfo[0].svgPath = r;
                        setEditInfo(updatedEditInfo);
                        setLoaded(true);
                    }).catch((error) => {
                        failureAlert('Error uploading the image');
                        setLoaded(true);
                    });
                };
                img.onerror = () => {
                    failureAlert('Invalid image file');
                    setLoaded(true);
                };
                img.src = reader.result;
            };
            reader.readAsDataURL(file);
        }
    };
    const handleFileChangeNewThumbnail = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setLoaded(false);
            if (file.size > 20 * 1024 * 1024) {
                failureAlert('File size must be less than 20MB');
                setLoaded(true);
                return;
            }
            const allowedTypes = ['image/jpeg', 'image/png'];
            if (!allowedTypes.includes(file.type)) {
                failureAlert('Please select a jpg or png file');
                setLoaded(true);
                return;
            }
            const img = new Image();
            const reader = new FileReader();
            reader.onloadend = () => {
                img.onload = () => {
                    if (img.width !== 150 || img.height !== 150) {
                        failureAlert('File dimension must be 150 x 150 px');
                        setLoaded(true);
                        return;
                    }
                    if (thumbnailFilePreview) {
                        const Icon = [thumbnailFilePreview];
                        axiosApiInstance.post(BASE_URL + "/api/file/deleteBanners", Icon);
                    }
                    uploadImage(file).then((r) => {
                        const updatedEditInfo = [...editInfo];
                        setThumbnailFilePreview(r);
                        updatedEditInfo[0].thumbnail = r;
                        setEditInfo(updatedEditInfo);
                        setLoaded(true);
                    }).catch((error) => {
                        failureAlert('Error uploading the image');
                        setLoaded(true);
                    });
                };
                img.onerror = () => {
                    failureAlert('Invalid image file');
                    setLoaded(true);
                };
                img.src = reader.result;
            };
            reader.readAsDataURL(file);
        }
    };
    const Reset = () => {
        setEditCategory(false);
    };
    const [deleteIds, setDeleteIds] = useState([]);
    const deleteSubTitle = (id) => {
        setDeleteIds(prevIds => {
            if (!prevIds.includes(id)) {
                return [...prevIds, id];
            }
            return prevIds;
        });

        const updatedData = editInfo.filter(item => item.id !== id);
        setEditInfo(updatedData);


    };


    const [draggedItemIndex, setDraggedItemIndex] = useState(null);


    const handleDragStart = (index) => {
        setDraggedItemIndex(index);
    };


    const handleDragOver = (index) => {
        if (draggedItemIndex === index) return;

        const newItems = [...categoriesToDisplay];
        const [movedItem] = newItems.splice(draggedItemIndex, 1);
        newItems.splice(index, 0, movedItem);

        setDraggedItemIndex(index);
        setCategoriesToDisplay(newItems);
    };

    const handleDragEnd = async () => {
        setDraggedItemIndex(null);

        const transformData = (data) => {
            return data.map((item, index) => ({
                index: index + 1,
                mainTitle: item.mainTitle
            }));
        };

        const transformedData = transformData(categoriesToDisplay);

        if (transformedData.length > 0) {
            setLoaded(false);
            const response = await axiosApiInstance.post(BASE_URL + '/api/category/updateIndex', transformedData);

            if (response.status == 200) {
                successAlert("Experiences Rearranged successfully")
            }
            setLoaded(true);
        }

    };
    const [selectedExperiences, setSelectedExperiences] = useState([]);


    const submitHomepageExperiences = async (e) => {
        e.preventDefault();


      

        if (selectedExperiences.length > 0) {
            setLoaded(false);
            await axiosApiInstance.post(BASE_URL + "/api/category/add_homepage_experiences", selectedExperiences)
                .then(async (response) => {
                    // dispatch(gameAllCategory());
                    successAlert("updated successfully");
                    setLoaded(true);
                }).catch((error) => {
                    failureAlert("Experience Update failed ");
                    setLoaded(true);
                });

        }
    };
    return (<div className="admin-homepage">
        <PageLayout
            sidebartitle=""
            active={"Experiences"}
            category
            sideBarContents={sidebarContentAdmin}
            profile
            {...props}
            signOut={() => signOut(dispatch, props.history, isAdmin)}
            isAdmin={isAdmin}
        >
            <div className="addedit-category">
                <LoadingComponent loaded={loaded} />
                <ToastContainer position="bottom-center" />
                <div className="container !pt-0">
                    {/* Tabs */}
                    <div className="tabs mb-5">
                        <button
                            className={`btn btn-default ${activeTab === "experience" ? "active btn btn-primary" : ""}`}
                            onClick={() => handleTabClick("experience")}
                        >
                            Header
                        </button>
                        <button
                            className={`btn btn-defaul ${activeTab === "home" ? "active btn btn-primary" : ""}`}
                            onClick={() => handleTabClick("home")}
                        >
                            Home Page
                        </button>

                    </div>

                    {/* Tab Content */}
                    <div className="tab-content ">

                        {activeTab === "experience" && (
                            <div>
                                {editCategory ? (
                                    <div className="add-category">
                                        <h4 className="title">Edit Experience</h4>
                                        <form onSubmit={handleEditFormSubmit}>
                                            <div className="group">
                                                <div className="form-group">
                                                    <label htmlFor="inputWithSelect">Main Title</label>
                                                    <div className="relative mb-4">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter the title"
                                                            value={editInfo[0]?.mainTitle}
                                                            onChange={(e) => handleMainTitleChange(e.target.value)}
                                                            className="border rounded p-2 w-full cursor-pointer"
                                                        />
                                                    </div>

                                                    {editInfo[0]?.mainTitleError && editInfo[0]?.mainTitleError.length > 0 && <div className="error-message">{editInfo[0]?.mainTitleError}</div>}
                                                </div>


                                                <div className="form-group">
                                                    <label htmlFor="inputWithSelectu">Main Title Color Code</label>
                                                    <div className="relative mb-4">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter the color code"
                                                            value={editInfo[0]?.mainTitleColorCode}
                                                            onChange={(e) => handleMainTitlecolorcodeChange(e.target.value)}
                                                            className="border rounded p-2 w-full cursor-pointer"
                                                        />
                                                    </div>

                                                  
                                                </div>
                       
                                            </div>
                                            <div className="group">
                                                <div className="form-group">
                                                    <label htmlFor="">Upload New Icon</label>
                                                    <input type="file" onChange={(e) => handleFileChangeNew(e)} />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="">Existing Icon</label>

                                                    <img
                                                        src={S3_BASE_URL + svgPreview}
                                                        style={{ width: '100px', height: '100px' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="group">
                                                <div className="form-group">
                                                    <label htmlFor="">Upload New Thumbnail</label>
                                                    <input type="file" onChange={(e) => handleFileChangeNewThumbnail(e)} />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="">Existing Thumnail</label>

                                                    <img
                                                        src={S3_BASE_URL + thumbnailFilePreview}
                                                        style={{ width: '100px', height: '100px' }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="group">
                                                <div className="form-group">
                                                    <label htmlFor="">Sub Title</label>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="">Games</label>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="">Sub title color codes</label>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="">Description</label>
                                                </div>
                                            </div>

                                            {editInfo && editInfo.length > 0 && editInfo.map((item, index) => (
                                                <div className="group" key={index}>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter the title"
                                                            value={item.title}
                                                            onChange={(e) => handleTitleChange(e.target.value, index)}
                                                            className="border rounded p-2 w-full cursor-pointer"
                                                        />
                                                        {item.error && item.error.length > 0 && <div className="error-message">{item.error}</div>}
                                                    </div>

                                                    <div className="form-group">

                                                        <Select
                                                            classNamePrefix="react-select"
                                                            className="form-select"
                                                            options={gameOptions}
                                                            menuPlacement={"auto"}
                                                            isMulti
                                                            onChange={(selectedOption) => handleChange2(selectedOption, index)}
                                                            placeholder="Select Games"
                                                            value={item.games}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="flex items-center mb-4">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter the title"
                                                                value={item.subTitleColorCode}
                                                                onChange={(e) => handleSubTitleColorCodeChange(e.target.value, index)}
                                                                className="border rounded p-2 w-full mr-2"
                                                            />
                                                       
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="flex items-center mb-4">
                                                            <input
                                                                type="text"
                                                                placeholder="Enter the title"
                                                                value={item.description}
                                                                onChange={(e) => handleDescriptionChange(e.target.value, index)}
                                                                className="border rounded p-2 w-full mr-2"
                                                            />
                                                            <button onClick={(event) => {
                                                                event.preventDefault();
                                                                deleteSubTitle(item.id);
                                                            }} className="bg-red-500 text-white text-sm py-2 px-4 rounded hover:bg-blue-600">
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </div>



                                                </div>
                                            ))}

                                            <div className="">
                                                <div className="d-flex justify-content-center w-100">
                                                    <button className="btn btn-default shadow-md me-2" onClick={Reset}>
                                                        Back
                                                    </button>
                                                    <button className="btn btn-primary" type="submit" disabled={!loaded}>
                                                        UPDATE
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>


                                ) : (
                                    <div className="add-category">
                                        <h4 className="title">Add Experience</h4>
                                        <form onSubmit={(e) => e.preventDefault()}>

                                            <div className="group">
                                                <div className="form-group">
                                                    <label htmlFor="inputWithSelect">Main Title</label>
                                                    <div className="relative mb-4" ref={inputRef}>
                                                        <input
                                                            type="text"
                                                            value={inputValue}
                                                            onChange={handleInputChange}
                                                            placeholder="Enter the title"
                                                            onFocus={() => {
                                                                setSuggestions(options);
                                                                setIsDropdownOpen(true);
                                                            }}
                                                            className="border rounded p-2 w-full cursor-pointer"
                                                        />
                                                        {mainTitleOptions && mainTitleOptions.length > 0 && isDropdownOpen && (
                                                            <ul className="absolute z-10 bg-white border rounded mt-1 w-full max-h-60 overflow-auto shadow-lg">
                                                                {mainTitleOptions.map((suggestion, index) => (
                                                                    <li
                                                                        key={index}
                                                                        className="p-2 hover:bg-gray-200 cursor-pointer"
                                                                        onClick={() => handleSuggestionClick(suggestion)}
                                                                    >
                                                                        {suggestion.mainTitle}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}

                                                        {mainTitleError && <div className="error-message">{mainTitleError}</div>}
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="">Sub Title</label>
                                                    <input
                                                        type="text"
                                                        value={addDetails.title}
                                                        placeholder="Enter the title"
                                                        onBlur={() => setTitleTouched(true)}
                                                        name="title"
                                                        onChange={handleChange}
                                                    />
                                                    <div className="error-message">{errors.title}</div>
                                                </div>
                                            </div>
                                            <div className="group">
                                            <div className="form-group">
                                                    <label htmlFor="">Main Title color code</label>
                                                    <input
                                                        type="text"
                                                        value={addDetails.mainTitleColorCode}
                                                        placeholder="Enter the title"
                                                        // onBlur={() => setTitleTouched(true)}
                                                        name="mainTitleColorCode"
                                                        onChange={handleChange}
                                                    />
                                                    <div className="error-message">{errors.mainTitleColorCode}</div>
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="">Sub Title color code</label>
                                                    <input
                                                        type="text"
                                                        value={addDetails.subTitleColorCode}
                                                        placeholder="Enter the title"
                                                        // onBlur={() => setTitleTouched(true)}
                                                        name="subTitleColorCode"
                                                        onChange={handleChange}
                                                    />
                                                    <div className="error-message">{errors.subTitleColorCode}</div>
                                                </div>
                                            </div>
                                            <div className="group">


                                                <div className="form-group">
                                                    <label htmlFor="">Description</label>
                                                    <textarea
                                                        width="100%"
                                                        rows="5"
                                                        placeholder="Enter the description"
                                                        name="description"
                                                        value={addDetails.description}
                                                        onChange={handleChange}
                                                    ></textarea>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="">Games</label>

                                                    <Select
                                                        classNamePrefix="react-select"
                                                        className="form-select"
                                                        options={gameOptions}
                                                        menuPlacement={"auto"}
                                                        isMulti
                                                        onChange={(e) => setSelectedAddGames(e)}
                                                        placeholder="Select Games"
                                                        value={selectedAddGames}
                                                    />
                                                </div>
                                            </div>

                                            <div className="group">
                                                {showFileInput && (
                                                    <div className="form-group">
                                                        <label htmlFor="fileInput" className="block mb-2">
                                                            Upload Icon
                                                        </label>
                                                        <input
                                                            id="fileInput"
                                                            type="file"
                                                            ref={svgfileInputRef}
                                                            onChange={handleFileChange}
                                                            className="border p-2"
                                                            accept="image/png, image/jpeg, image/jpg"
                                                        />

                                                        {svgFileError && <div className="error-message">{svgFileError}</div>}
                                                    </div>
                                                )}

                                                {filePreview && (
                                                    <div className="form-group">
                                                        <img
                                                            src={filePreview}
                                                            alt="Preview"
                                                            className="mt-2"
                                                            style={{ width: '100px', height: '100px' }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="group">
                                                {showFileInput && (
                                                    <div className="form-group">
                                                        <label htmlFor="fileInput1" className="block mb-2">
                                                            Thumbnail
                                                        </label>
                                                        <input
                                                            id="fileInput1"
                                                            type="file"
                                                            ref={svgfileInputRefImage}
                                                            onChange={handleFileChangeImage}
                                                            className="border p-2"
                                                            accept="image/png, image/jpeg, image/jpg"
                                                        />

                                                        {thumbnailError && <div className="error-message">{thumbnailError}</div>}
                                                    </div>
                                                )}

                                                {thumbnailPreview && (
                                                    <div className="form-group">
                                                        <img
                                                            src={thumbnailPreview}
                                                            alt="Preview"
                                                            className="mt-2"
                                                            style={{ width: '100px', height: '100px' }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <button className="btn btn-primary" onClick={handleAddCategory}>
                                                ADD
                                            </button>
                                        </form>
                                    </div>

                                )}

                                <div>
                                    <div className="relative overflow-x-auto">
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                                            <thead className="text-xs text-gray-700 bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">Main Title</th>
                                                    <th scope="col" className="px-6 py-3">Sub Title</th>
                                                    <th scope="col" className="px-6 py-3">Games Added</th>
                                                    <th scope="col" className="px-6 py-3">Thumbnail</th>
                                                    <th scope="col" className="px-6 py-3">Svg Icon</th>
                                                    <th scope="col" className="px-6 py-3">Experience For</th>
                                                    <th scope="col" className="px-6 py-3">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {categoriesToDisplay.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="5" className="px-6 py-4  text-gray-500">
                                                            <h2> No Experiences Found</h2>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    categoriesToDisplay.map((item, index) => (
                                                        <tr
                                                            className="bg-white border-b"
                                                            key={item.id}
                                                            draggable
                                                            onDragStart={() => handleDragStart(index)}
                                                            onDragOver={(e) => {
                                                                e.preventDefault();
                                                                handleDragOver(index);
                                                            }}
                                                            onDragEnd={handleDragEnd}
                                                            style={{
                                                                backgroundColor: draggedItemIndex === index ? '#f0f0f0' : 'white',
                                                                cursor: 'move',
                                                            }}
                                                        >
                                                            <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                                                                <span className="mr-3">{index + 1}</span>
                                                                <span>{item.mainTitle}</span><br/>
                                                                <span style={{color:'blue'}}>({item.mainTitleColorCode})</span>
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                <select style={{ zIndex: 'unset' }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                                                    {item.titles.map((title, index) => (
                                                                        <option className="capitalize" key={index} value={title}>{title}  ({item.subTitleColorCode[index]})</option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                <select style={{ zIndex: 'unset' }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                                                    {item.games.map((game, index) => (
                                                                        <option key={index} >{game.title}</option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                <img src={`${S3_BASE_URL}${item.thumbnailPath[0]}`} style={{ width: '100px', height: '70px', padding: '10px' }} alt="Icon" />
                                                            </td>
                                                            <td className="px-6 py-4">
                                                                <img src={`${S3_BASE_URL}${item.path[0]}`} style={{ width: '100px', height: '70px', padding: '10px' }} alt="Icon" />
                                                            </td>
                                                            <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">

                                                                <span className="uppercase">{item.experienceType}</span>
                                                            </td>
                                                            <td className="flex px-6 py-4">
                                                                <button onClick={() => handleEdit(item)} className="mr-2 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                                                    Edit
                                                                </button>
                                                                <button onClick={() => handleDelete(item)} className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                                                    Delete
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>


                                </div>
                            </div>
                        )}
                        {activeTab === "home" && (
                            <div >

                                <h4 className="title pt-8">Add  Experience for Home Page</h4>
                                <form
                                    onSubmit={submitHomepageExperiences}
                                    className="flex flex-col items-center justify-center w-full max-w-md p-6 bg-white  mx-auto mt-6"
                                >
                                    <div className="w-full">
                                        <div className="mb-4">
                                            <label className="block mb-2 text-sm font-medium text-gray-900">
                                                Select Experiences
                                            </label>
                                            <Select
                                                classNamePrefix="react-select"
                                                className="form-select w-full"
                                                options={homePageExperiences}
                                                menuPlacement="auto"
                                                isMulti
                                                onChange={(selectedOptions) =>
                                                    setSelectedExperiences(selectedOptions || [])
                                                }
                                                placeholder="Select Experiences"
                                                value={selectedExperiences}
                                            />
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="submit"
                                            className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>

                            </div>
                        )}
                    </div>
                </div>


            </div>
        </PageLayout>
    </div>

    )
}

export default AddEditCategory;