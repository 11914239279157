import React, { useState, useEffect } from 'react';
import './testimonialCarousel.css';
import { S3_BASE_URL } from "../../helpers/helper";
import headingIcon from "../../assets/icons/headingIcon.png";
import quotes from "../../assets/icons/quotes.png";
import rightArrow from "../../assets/icons/rightsvg.svg";
import leftArrow from "../../assets/icons/leftsvg.svg";
// Custom hook to get window width
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowSize(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Initial size check
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const SkeletonTestimonialCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const windowSize = useWindowSize();
  const [images, setImages] = useState([1, 2, 3, 4, 5, 6, 7, 8])
  const isSmallScreen = windowSize <= 768; // Adjust breakpoint as needed

  // Handle previous slide
  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + images.length) % images.length
    );
  };

  // Handle next slide
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex + 1) % images.length
    );
  };

  // Get indices for the 5 images to be displayed
  const getDisplayedIndices = () => {
    const indices = [];
    for (let i = -2; i <= 2; i++) {
      indices.push((currentIndex + i + images.length) % images.length);
    }
    return indices;
  };

  const displayedIndices = getDisplayedIndices();

  // Set up auto-sliding every 8 seconds
  useEffect(() => {
    const intervalId = setInterval(handleNext, 80000);
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [currentIndex, images.length]);

  return (
    <div className="testimonial-carousel">



      <div className="carousel-container">
        <div className="carousel-track" style={{ width: '100%!important' }}>
          {displayedIndices.map((index, pos) => {
            const isCenter = pos === 2;
            if (isSmallScreen && !isCenter) return null;

            return (
              <div
                key={index}
                className={`carousel-slide ${isCenter ? 'center-image' : ''} ${pos === 0 || pos === 4 ? 'small-image' : ''}`}
              >
                {/* <img className="carouseImg" src={`${S3_BASE_URL}${images[index].path}`} alt={`Image ${index}`} /> */}
                <div className={`skeletoncarouseImg ${isCenter ? 'bg-slate-100 skeleton-animation' : ''}`}></div>
                {isCenter && (
                  <div className="carousel-text">

                    {/* <h3 className="mt-4">{images[index].Name}</h3> */}
                    <div className=" mt-4 flex w-full items-center justify-center flex-col ">
                      <div className=" w-[145px] h-[28px] bg-slate-100 skeleton-animation rounded-sm"></div>

                      {/* <h4>{images[index].Designation}</h4> */}
                      <div className=" w-[120px] h-[28px] bg-slate-100 skeleton-animation mt-2 rounded-sm"></div>
                    </div>



                    <span className="comments  h-[200px]" >
                      <img src={quotes} alt="Small Decorative Image" />
                      <p className='w-[700px] h-[130px] bg-slate-100 skeleton-animation rounded-sm '></p>

                    </span>
                    <div className="btn-container-carousel">
                     
                      <button className="control prev" onClick={handlePrev}>
                        <img src={leftArrow} alt="Previous" className='bg-[#93939333]'/>
                      </button>
                      <button className="control next" onClick={handleNext}>
                        <img src={rightArrow} alt="Next"  className='bg-[#000000]'/>
                      </button>
                    </div>

                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

    </div>
  );
};

export default SkeletonTestimonialCarousel;