
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './modal.css'; // If you still need custom styles
import Modal from './modal';
import { ToastContainer } from 'react-toastify';
import iconfilter from "../../assets/icons/iconfilter.png";
import LoadingComponent from "../loader/LoadingComponent";
import { Redirect, useParams } from "react-router";
import { updateCalendarFilterFormData } from "../../redux/actions/calendarFilterActions";
import { BASE_URL, successAlert, encryptData, S3_BASE_URL, GOOGLE_RECAPTCHA_SITE_KEY, failureAlert, IsAdmin, axiosApiInstance, videoUrl } from "../../helpers/helper";
const CalendarGamesFilterModal = ({ modalid, toggle, setOpenBookEventFilterModal, experiences, onCategoriesChange, onClearFilters }) => {
    const dispatch = useDispatch();

    const { id } = useParams();
    const storedFormData = useSelector((state) => state.calendarfilterFormData);
    const [loaded, setLoaded] = useState(true);
    const [checkedItems, setCheckedItems] = useState({});
    const [platformItems, setplatformcheckedItems] = useState({});

    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');

    const [errorMinPrice, setErrorMinPrice] = useState('');
    const [errorMaxPrice, setErrorMaxPrice] = useState('');
    const minutes = [10, 15, 30, 45, 60, 75, 90, 120, 180];
    const [minutesChecked, setMinutesChecked] = useState({});
    const handleCheckboxChange = (id) => {
        setCheckedItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const convertPlatformsArrayToObject = (platformsArray) => {
        return platformsArray.reduce((acc, platformId) => {
            acc[platformId] = true;
            return acc;
        }, {});
    };
    const [selectedOption, setSelectedOption] = useState('');
    const [error, setError] = useState('');


    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedOption(event.target.value);
        setStatus(value);

        switch (value) {
            case 'online':
                setMinPlaceholder('45000');
                setMaxPlaceholder('85000');
                break;
            case 'offline':
                setMinPlaceholder('1000');
                setMaxPlaceholder('1500');
                break;
            default:
                setMinPlaceholder('1000');
                setMaxPlaceholder('85000');
                break;
        }
    };
    useEffect(() => {
        if (storedFormData) {
            const data = storedFormData.formData;
            if (data) {
                setcalendarGameTitle(data?.title ? data.title : '');
                const categoryIds = convertPlatformsArrayToObject(data?.experienceIds);
                const minutes = convertPlatformsArrayToObject(data?.duration);
                setMinutesChecked(minutes)
                setCheckedItems(categoryIds);
                setSelectedOption(data?.gameType ? data.gameType : '')
            }
        }
    }, [storedFormData]);

    const handleMinutesChange = (minute) => {
        setMinutesChecked((prev) => ({
            ...prev,
            [minute]: !prev[minute],
        }));
    };
    const handleplatformChange = (id) => {
        setplatformcheckedItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const [calendarGameTitle, setcalendarGameTitle] = useState('');
    const [errorcalendarGameTitle, setErrorcalendarGameTitle] = useState('');


    const handleTextInputChange = (e, setter) => {
        setter(e.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        if (isValid) {

            const selectedIds = Object.keys(checkedItems).filter(id => checkedItems[id]);

            const selectedMinutes = Object.keys(minutesChecked).filter(min => minutesChecked[min]);

            setLoaded(false);
            let obj = { id: id, title: calendarGameTitle, experienceIds: selectedIds, duration: selectedMinutes.map(Number), gameType: selectedOption };

            const result = await axiosApiInstance.post(BASE_URL + "/api/Calendar/filter_calendar_games", obj)
                .then((response) => {

                    onCategoriesChange(response.data.data);
                    setOpenBookEventFilterModal(false);
                    setcalendarGameTitle('');
                    setLoaded(true);
                    dispatch(updateCalendarFilterFormData(obj));

                })
                .catch((error) => {
                    failureAlert("Failed to get games");
                    setLoaded(true);
                });
        }
    };
    const handleClearFilters = () => {
        setSelectedOption('');
        setcalendarGameTitle('');
        setMinutesChecked({});
        setCheckedItems({});
        onClearFilters(true);
        dispatch(updateCalendarFilterFormData(null));
    };
    const [minPlaceholder, setMinPlaceholder] = useState('1000');
    const [maxPlaceholder, setMaxPlaceholder] = useState('85000');
    const [status, setStatus] = useState('default');


    return (
        <>

            <ToastContainer position='bottom-center' />
            <LoadingComponent loaded={loaded} />
            <Modal modalid={modalid} toggle={toggle}>
                <div className="modal-body w-full max-w-[700px] bg-white overflow-hidden">
                    <ToastContainer position='bottom-center' />
                    <form className="mb-2 md:mb-0 flex flex-col " onSubmit={handleSubmit}>
                        <div className="w-full flex-grow">
                            <div className="flex justify-between items-center mb-0" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                <div className="flex items-center justify-start flex-grow ml-8 md:justify-center md:ml-0">
                                    <img
                                        src={iconfilter}
                                        alt="Filter Icon"
                                        className="mr-2"
                                    />
                                    <h2 className="text-xl font-bold">Filters</h2>
                                </div>
                                <button
                                    style={{ fontSize: '40px' }}
                                    className="text-black-500 hover:text-gray-800 mr-6"
                                    onClick={() => setOpenBookEventFilterModal(false)}
                                >
                                    &times;
                                </button>
                            </div>

                            <div className=''>
                                <div className="p-6 pb-0">


                                    <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-4">
                                        <div className="w-full md:w-1/2">
                                            <label htmlFor="calendarGameTitle" className="text-sm font-bold mb-4">Game Title</label>
                                            <input
                                                type="text"
                                                placeholder="Enter game title"
                                                value={calendarGameTitle}
                                                onChange={(e) => handleTextInputChange(e, setcalendarGameTitle)}
                                                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5"
                                            />
                                            {errorcalendarGameTitle && <div style={{ color: 'red', fontSize: '14px' }}>{errorcalendarGameTitle}</div>}
                                        </div>
                                        <div className="w-full md:w-1/2">
                                            <label htmlFor="third_name" className="text-sm font-bold mb-4">Game Type</label>

                                            <select
                                                className="bg-gray-50 border text-gray-900 text-sm rounded-lg  block w-full  bg-white"
                                                style={{ height: '40px' }}
                                                value={selectedOption}
                                                onChange={handleSelectChange}
                                            >
                                                <option value="" selected>
                                                    Select
                                                </option>
                                                <option value="ONLINE">Virtual</option>
                                                <option value="HYBRID">Hybrid</option>
                                                <option value="OFFLINE">Onsite</option>
                                            </select>
                                        </div>
                                    </div>


                                    <hr className="h-px my-4 bg-gray-200 border-0 " />
                                    <div className="mb-2 md:mb-4">
                                        <h6 className="text-sm font-bold mb-4">Experiences</h6>
                                        <div className="pb-0">
                                            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                                {experiences.map(option => (
                                                  <div key={option.id} className="inline-block">
                                                  <input
                                                      id={`category-${option.id}`}
                                                      type="checkbox"
                                                      checked={!!checkedItems[option.id]}
                                                      onChange={() => handleCheckboxChange(option.id)}
                                                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 align-middle"
                                                  />
                                                  <label 
                                                      htmlFor={`category-${option.id}`} 
                                                      className="text-sm font-medium text-gray-900 align-middle ml-2"
                                                  >
                                                      {option.title}
                                                  </label>
                                              </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="h-px my-4 bg-gray-200 border-0 " />

                                    <div className="mb-2 md:mb-0">
                                        <h6 className="text-sm font-bold mb-4">Game Duration</h6>
                                        <div className="pb-0">
                                            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                                {minutes.map(min => (
                                                    <div className="flex items-center" key={min}>
                                                        <input
                                                            id={`minute-${min}`}
                                                            type="checkbox"
                                                            checked={!!minutesChecked[min]}
                                                            onChange={() => handleMinutesChange(min)}
                                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                                        />
                                                        <label htmlFor={`minute-${min}`} className="ml-2 ms-2 text-sm font-medium text-gray-900">{min} minutes</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center m-4 w-[95%]">
                            <button
                                onClick={handleClearFilters}
                                className="mr-3 w-[40%] py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-md border border-gray-900 font-bold"
                            >
                                Clear All
                            </button>
                            <button
                                type="submit"
                                className="w-[60%] py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200  btn btn-primary"
                            >
                                Filter Results
                            </button>

                        </div>
                    </form>
                </div>

            </Modal>
        </>
    );
};

export default CalendarGamesFilterModal;