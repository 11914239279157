
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './modal.css'; // If you still need custom styles
import Modal from './modal';
import { ToastContainer } from 'react-toastify';
import iconfilter from "../../assets/icons/iconfilter.png";
import LoadingComponent from "../../components/loader/LoadingComponent";

import { updateFilterFormData } from "../../redux/actions/bookEventFilterActions";
import { BASE_URL, successAlert, encryptData, S3_BASE_URL, GOOGLE_RECAPTCHA_SITE_KEY, failureAlert, IsAdmin, axiosApiInstance, videoUrl } from "../../helpers/helper";
import { hand } from 'synonyms/dictionary';
const ViewAllGamesFilterModal = ({ modalid, toggle, setOpenBookEventFilterModal, bookEventCategories, setGamesDuration, setGamesFormat, setCatMainTitle, mainTitle, gamesDuration, gamesFormat }) => {
    const [loaded, setLoaded] = useState(true);
    const [duration, setDuration] = useState(0);
    const [format, setFormat] = useState('');
    // const [checkTitle, setCheckTitle] = useState("All");
    const [checkTitle, setCheckTitle] = useState([{ title: "All" }]);

    useEffect(() => {
        // if (mainTitle) {
        //     setCheckTitle(mainTitle);
        // }
        if (mainTitle === "All") {
            setCheckTitle([{ title: "All" }]);
        } else if (Array.isArray(mainTitle)) {
            setCheckTitle(mainTitle);
        }
        else {
            setCheckTitle([{ title: mainTitle }]);
        }
        if (gamesFormat) {
            setFormat(gamesFormat);
        }
        if (gamesDuration) {
            setDuration(gamesDuration);
        }
    }, [mainTitle, gamesDuration, gamesFormat])
    // const handleCheckboxChange = (mainTitle) => {
    //     // setCheckedItems((prev) => ({
    //     //     ...prev,
    //     //     [mainTitle]: !prev[mainTitle],
    //     // }));
    //     if (checkTitle.includes(mainTitle)) {
    //         setCheckTitle("All")
    //     }
    //     else {
    //         setCheckTitle(mainTitle)

    //     }
    // };
    const handleCheckboxChange = (mainTitle) => {
        setCheckTitle((prev) => {
            const checkTitleArray = Array.isArray(prev) ? prev : [];
            if (mainTitle === "All") {
                return checkTitleArray.some((item) => item.title === "All")
                    ? []
                    : [{ title: "All" }];
            }

            const withoutAll = checkTitleArray.filter((item) => item.title !== "All");

            const exists = withoutAll.some((item) => item.title === mainTitle);

            if (exists) {
                // Remove the object with the matching title
                return withoutAll.filter((item) => item.title !== mainTitle);
            } else {
                // Add the new object
                return [...withoutAll, { title: mainTitle }];
            }
        });
    };
    const handleDurationChange = (event) => {
        const value = event.target.value;
        setDuration(value)
    };
    const handleFormatChange = (event) => {
        const value = event.target.value;
        console.log("handleFormatChange value---->  " + value);

        setFormat(value)

    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("checkTitle: filter " + JSON.stringify(checkTitle));

        setGamesDuration(duration)
        setGamesFormat(format);
        if(checkTitle && checkTitle.length > 0){
            setCatMainTitle(checkTitle);

        }
        else{
            setCatMainTitle("All");
        }
        setOpenBookEventFilterModal(false);

    };
    const handleClearFilters = () => {
        setDuration(0);
        setFormat('');
        setCheckTitle('');

        setGamesDuration(0)
        setGamesFormat('');
        setCatMainTitle("All");

        setOpenBookEventFilterModal(false)
    };


    return (
        <>

            <ToastContainer position='bottom-center' />
            <LoadingComponent loaded={loaded} />
            <Modal modalid={modalid} toggle={toggle}>
                <div className="modal-body w-full max-w-[700px] bg-white overflow-hidden ">
                    <ToastContainer position='bottom-center' />
                    <form className="mb-2 md:mb-4 flex flex-col h-auto" onSubmit={handleSubmit}>
                        <div className="w-full flex-grow">
                            <div className="flex justify-between items-center mb-0" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                <div className="flex items-center justify-start flex-grow ml-8 md:justify-center md:ml-0">
                                    <img
                                        src={iconfilter}
                                        alt="Filter Icon"
                                        className="mr-2"
                                    />
                                    <h2 className="text-xl font-bold">Filters</h2>
                                </div>
                                <button
                                    style={{ fontSize: '40px' }}
                                    className="text-black-500 hover:text-gray-800 mr-6"
                                    onClick={() => setOpenBookEventFilterModal(false)}
                                >
                                    &times;
                                </button>
                            </div>

                            <div className='max-h-[60vh] overflow-y-auto'>
                                <div className="p-6 pb-0">
                                    {/* <h4 className="text-md font-bold mb-4">Group Size & Budget</h4> */}

                                    <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-4">
                                        <div className="w-full md:w-1/2">
                                            <label htmlFor="participants" className="text-sm font-bold mb-4"> Type Of Event</label>
                                            <select
                                                className="bg-gray-50 border text-gray-900 text-sm rounded-lg  block w-full  bg-white"
                                                style={{ height: '40px' }}
                                                value={format}
                                                onChange={handleFormatChange}
                                            >
                                                <option value="" selected>
                                                    Select
                                                </option>
                                                <option value="ONLINE">Virtual</option>
                                                <option value="OFFLINE">Onsite</option>
                                                <option value="HYBRID">Hybird</option>
                                            </select>
                                        </div>
                                        <div className="w-full md:w-1/2">
                                            <label htmlFor="third_name" className="text-sm font-bold mb-4">Duration</label>

                                            <select
                                                className="bg-gray-50 border text-gray-900 text-sm rounded-lg  block w-full  bg-white"
                                                style={{ height: '40px' }}
                                                value={duration}
                                                onChange={handleDurationChange}
                                            >
                                                <option value="" selected>
                                                    Select
                                                </option>
                                                {
                                                    [5, 10, 20, 30, 40, 50, 60].map((val, indx) => (
                                                        <option value={val}>{val} min</option>

                                                    ))
                                                }

                                            </select>
                                        </div>
                                    </div>



                                    <hr className="h-px my-4 bg-gray-200 border-0 " />
                                    <div className="mb-2 md:mb-4">
                                        <h6 className="text-sm font-bold mb-4">Experiences</h6>
                                        <div className="pb-0">
                                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                                {bookEventCategories.map(option => (
                                                    <div className="flex items-center" key={option.id}>
                                                        <input
                                                            id={`category-${option.id}`}
                                                            type="checkbox"
                                                            // checked={checkTitle === option.mainTitle ? true : false}
                                                            checked={
                                                                Array.isArray(checkTitle)
                                                                    ? checkTitle.some((item) =>
                                                                        item.title === "All"
                                                                            ? true
                                                                            : item.title === option.mainTitle
                                                                    )
                                                                    : false
                                                            } onChange={() => handleCheckboxChange(option.mainTitle)}
                                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                                        />
                                                        <label htmlFor={`category-${option.id}`} className="ml-2 ms-2 text-sm font-medium text-gray-900">{option.mainTitle === "DEIB - Diversity, Inclusion, Equity and Belonging" ? "DEIB" : option.mainTitle}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="h-px my-4 bg-gray-200 border-0 " />

                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center m-4 w-[95%]">
                            <button
                                onClick={handleClearFilters}
                                className="mr-3 w-[40%] py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-md border border-gray-900 font-bold"
                            >
                                Clear All
                            </button>
                            <button
                                type="submit"
                                className="w-[60%] py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200  btn btn-primary"
                            >
                                Filter Results
                            </button>

                        </div>




                    </form>
                </div>

            </Modal>
        </>
    );
};

export default ViewAllGamesFilterModal;