import  * as ActionTypes from "../constants/calendarConstants"



function getOcasionGamesOnId(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_OCCASION_GAMES_REQUEST:
      return { loading: true };
    case ActionTypes.GET_OCCASION_GAMES_SUCCESS:
      return { loading: false, occasionGames: action.payload };
    case ActionTypes.GET_OCCASION_GAMES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
function getAllQuarterInfoReducer(state = {}, action) {
    switch (action.type) {
      case ActionTypes.GET_QUARTER_INFO_REQUEST:
        return { loading: true };
      case ActionTypes.GET_QUARTER_INFO_SUCCESS:
        return { loading: false, allQuartersInfo: action.payload };
      case ActionTypes.GET_QUARTER_INFO_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }
  function getAllCalendarDataReducer(state = {}, action) {
    switch (action.type) {
      case ActionTypes.GET_ALL_CALENDAR_DATA_REQUEST:
        return { loading: true };
      case ActionTypes.GET_ALL_CALENDAR_DATA_SUCCESS:
        return { loading: false, allCalendarData: action.payload };
      case ActionTypes.GET_ALL_CALENDAR_DATA_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  }
export {
    // addEventQuestionReducer,
    // getEventQuestionReducer,
    getAllCalendarDataReducer,
    getAllQuarterInfoReducer,
    getOcasionGamesOnId
}